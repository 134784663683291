import {createSlice} from '@reduxjs/toolkit'
import {Customerconfig} from "src/types/generated";

const slice = createSlice({
    name: 'customerconfig',
    initialState: {
        data: [] as Customerconfig[]
    },
    reducers: {
        readedAll: (state, action) => {
            state.data = action.payload;
        },
        created: (state, action) => {
            state.data.push(action.payload);
        },
    },
})

export const actions = slice.actions;
export default slice.reducer;
