import { useSnackbar } from "notistack";
import { FC, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
    Box,
    Breadcrumbs, Button, Card,
    Container,
    Grid,
    IconButton,
    Link,
    Tooltip,
    Typography
} from "@mui/material";
import { Table } from "src/components/Table/Table";

import Add from "@mui/icons-material/Add";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import PermissionGuard from "../../components/PermissionGuard/PermissionGuard";
import useSettings from "../../hooks/useSettings";
import { useSelector } from "../../store";

import { deleteCompany, readAll } from "./commands.company";

const PageCompany: FC = (props) => {
  const data = useSelector((state) => state.company.data);
  const user = useSelector((state) => (state as any).auth);
  const { t } = useTranslation();

  const { settings } = useSettings();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { ...other } = props;

  useEffect(() => {
    readAll();
  }, []);

  const deleteEntry = async (id: string) => {
    try {
      let res = await deleteCompany(id);
      readAll();
      enqueueSnackbar("Eliminato con successo", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
        variant: "success",
      });
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar("Impossibile eliminare", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  // @ts-ignore
  return (
    <>
      <Helmet>
        <title>Dashboard: company </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Company
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  company
                </Typography>
              </Breadcrumbs>
            </Grid>
            <PermissionGuard action={"allow"} resource={"createCompany"}>
              <Grid item>
                <Box sx={{ m: -1 }}>
                  <RouterLink
                    to={{
                      pathname: "/companies/new",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      color="primary"
                      startIcon={<Add fontSize="small" />}
                      sx={{ m: 1 }}
                      variant="contained"
                    >
                      Aggiungi
                    </Button>
                  </RouterLink>
                </Box>
              </Grid>
            </PermissionGuard>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card {...other}>
              <Table
                idDefaultColumnSort="description"
                Data={data}
                Columns={[
                  { Header: "Descrizione", accessor: "description" },

                  {
                    Header: "Azioni",
                    accessor: "azioni",
                    Cell: (row) => (
                      <>
                        <PermissionGuard
                          action={"allow"}
                          resource={"updateCompany"}
                        >
                          <IconButton
                            component={RouterLink}
                            to={`/companies/update/${row.row.values.id}`}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </PermissionGuard>
                        <PermissionGuard
                          action={"allow"}
                          resource={"deleteCompany"}
                        >
                          <Tooltip title={"Elimina"}>
                            <IconButton
                              aria-label="Elimina"
                              color="secondary"
                              onClick={() => deleteEntry(row.row.values.id)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </PermissionGuard>

                        <IconButton>
                          <ArrowRightIcon fontSize="small" />
                        </IconButton>
                      </>
                    ),
                  },
                ]}
              />
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PageCompany;
