import { useSnackbar } from "notistack";
import type { ChangeEvent, FC } from "react";
import { Fragment, useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Link,
    Tab,
    Tabs,
    Typography
} from "@mui/material";

import { Add } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Helmet } from "react-helmet-async";
import { Table } from "src/components/Table/Table";
import GraphqlClient from "../../client/graphql.client";
import useSettings from "../../hooks/useSettings";
import Label from "../../material/Label";
import { useSelector } from "../../store";
import { Group, Policy } from "../../types/generated";
import { impersonificate } from "../login/commands.login";
import { readAll } from "./commands.role";

const tabs = [
  { label: "roles", value: "roles" },
  { label: "Policy", value: "policies" },
  { label: "Gruppi", value: "groups" },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function insertUrlParam(key, value) {
  if (window.history.pushState) {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    let newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParams.toString();
    window.history.pushState({ path: newurl }, "", newurl);
  }
}

const PageRole: FC = (props) => {
  const roles = useSelector((state) => state.roles.data);

  const [policies, setPolicies] = useState<Policy[]>([]);
  const [loadingPolicies, setLoadingPolicies] = useState(false);

  const [groups, setGroups] = useState<Group[]>([]);
  const [loadingGroups, setLoadingGroups] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { ...other } = props;
  const { settings } = useSettings();

  const query = useQuery();
  let tab = query.get("tab");

  const [currentTab, setCurrentTab] = useState<string>(tab ? tab : "roles");

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
    insertUrlParam("tab", value);
  };

  const readPolicies = async () => {
    setLoadingPolicies(true);
    try {
      const res = await GraphqlClient.getPolicies();
      setPolicies(res.getPolicies);
    } catch (e) {
      setPolicies([]);
      alert("Impossibile caricare le policy");
    } finally {
      setLoadingPolicies(false);
    }
  };

  const readGroups = async () => {
    setLoadingGroups(true);
    try {
      const res = await GraphqlClient.getGroups();
      setGroups(res.getGroups);
    } catch (e) {
      setGroups([]);
      alert("Impossibile caricare i gruppi");
    } finally {
      setLoadingGroups(false);
    }
  };

  useEffect(() => {
    readAll();
    readPolicies();
    readGroups();
  }, []);

  const impersonifica = async (utente) => {
    const key = enqueueSnackbar(`Impersonificazione di ${utente} in corso..`, {
      anchorOrigin: {
        horizontal: "right",
        vertical: "bottom",
      },
      variant: "info",
      persist: true,
      action: (key) => (
        <Fragment>
          <CircularProgress style={{ color: "white" }} size={24} />
        </Fragment>
      ),
    });

    try {
      await impersonificate(utente);
      closeSnackbar(key);
    } catch (e) {
      closeSnackbar(key);
      enqueueSnackbar(`Impossibile procedere con l'impersonificazione`, {
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
        variant: "error",
      });
    }
  };

  // Usually query is done on backend with indexing solutions
  // @ts-ignore
  return (
    <>
      <Helmet>
        <title>Dashboard: roles e permessi </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                roles e permessi
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  roles e permessi
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>

          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === "roles" && (
              <Box sx={{ mt: 3 }}>
                <Grid item>
                  <Box
                    sx={{ m: -1, p: 2 }}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      component={RouterLink}
                      to="/roles/new/ruolo"
                      color="primary"
                      startIcon={<Add fontSize="small" />}
                      sx={{ m: 1 }}
                      variant="contained"
                    >
                      Nuovo ruolo
                    </Button>
                  </Box>
                </Grid>
                <Card {...other}>
                  <Table
                    idDefaultColumnSort="name"
                    Data={roles}
                    Columns={[
                      {
                        Header: "Ruolo",
                        accessor: "name",
                        Cell: (row) => (
                          <Label
                            style={{
                              backgroundColor:
                                colorArray[
                                  hashCode(row.value) % colorArray.length
                                ],
                            }}
                          >
                            {row.value}
                          </Label>
                        ),
                      },
                      {
                        Header: "Descrizione",
                        accessor: "description",
                      },
                    ]}
                  />
                </Card>
              </Box>
            )}

            {currentTab === "policies" && (
              <Box sx={{ mt: 3 }}>
                <Grid item>
                  <Box
                    sx={{ m: -1, p: 2 }}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      component={RouterLink}
                      to="/roles/nuova/policy"
                      color="primary"
                      startIcon={<Add fontSize="small" />}
                      sx={{ m: 1 }}
                      variant="contained"
                    >
                      Nuova policy
                    </Button>
                  </Box>
                </Grid>
                <Card {...other}>
                  <Table
                    idDefaultColumnSort="subject"
                    Data={policies}
                    Columns={[
                      {
                        Header: "Soggetto",
                        accessor: "subject",
                        Cell: (row) => (
                          <Label
                            style={{
                              backgroundColor:
                                colorArray[
                                  hashCode(row.value) % colorArray.length
                                ],
                            }}
                          >
                            {row.value}
                          </Label>
                        ),
                      },
                      {
                        Header: "Risorsa",
                        accessor: "object",
                      },
                    ]}
                  />
                </Card>
              </Box>
            )}

            {currentTab === "groups" && (
              <Box sx={{ mt: 3 }}>
                <Grid item>
                  <Box
                    sx={{ m: -1, p: 2 }}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      component={RouterLink}
                      to="/roles/new/gruppo"
                      color="primary"
                      startIcon={<Add fontSize="small" />}
                      sx={{ m: 1 }}
                      variant="contained"
                    >
                      Nuovo gruppo
                    </Button>
                  </Box>
                </Grid>
                <Card {...other}>
                  <Table
                    idDefaultColumnSort="subject"
                    Data={groups}
                    Columns={[
                      {
                        Header: "Soggetto",
                        accessor: "subject",
                      },
                      {
                        Header: "Dominio",
                        accessor: "domain",
                      },
                      {
                        Header: "Ruolo",
                        accessor: "role",
                        Cell: (row) => (
                          <Label
                            style={{
                              backgroundColor:
                                colorArray[
                                  hashCode(row.value) % colorArray.length
                                ],
                            }}
                          >
                            {row.value}
                          </Label>
                        ),
                      },
                    ]}
                  />
                </Card>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PageRole;

var colorArray = [
  "#9c27b0",
  "#ea80fc",
  "#aa00ff",
  "#ff5252",
  "#ffcdd2",
  "#1976d2",
  "#009688",
  "#009688",
];

let hashCode = (str) => {
  var hash = 0;
  if (str.length == 0) {
    return hash;
  }
  for (var i = 0; i < str.length; i++) {
    var char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};
