import { styled } from "@mui/material/styles";
import type { FC, ReactNode } from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../NavBar";
import SideBar from "../SideBar";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = (sidebarOpened) =>
  styled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: "64px",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: sidebarOpened ? "280px" : 0,
    },
  }));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100vh",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

  const Wrapper = DashboardLayoutWrapper(isSidebarOpen);

  return (
    <DashboardLayoutRoot>
      <NavBar
        openSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
      />
      <SideBar
        openSidebar={isSidebarOpen}
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <Wrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </Wrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
