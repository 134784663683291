import { ErrorMessage, Formik } from "formik";
import { useSnackbar } from "notistack";
import type { FC } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Autocomplete } from "@mui/lab";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Link,
    TextField,
    Typography
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import GraphqlClient from "../../client/graphql.client";
import useSettings from "../../hooks/useSettings";
import { useSelector } from "../../store";
import { Role } from "../../types/generated";

const NewPolicy: FC = (props) => {
  const roles = useSelector((state) => state.roles.data);

  const navigate = useNavigate();
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Helmet>
        <title>Dashboard: Nuova policy </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Nuova policy
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/roles?tabs=policy"
                  variant="subtitle2"
                >
                  Policy
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Nuova
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<ArrowLeftIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                  to="/users"
                  variant="outlined"
                >
                  Torna alle policy
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Formik
              initialValues={{
                subject: "",
                resource: "",
                action: "allow",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                subject: Yup.string().max(20).required("Campo obbligatorio"),
                resource: Yup.string().max(40).required("Campo ogglibgatorio"),
                action: Yup.string().required("Campo ogglibgatorio"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ): Promise<void> => {
                setSubmitting(true);
                let { subject, resource, action } = values;

                try {
                  const res = await GraphqlClient.AddPolicy({
                    subject,
                    object: resource,
                    action,
                  });
                  setStatus({ success: true });
                  setSubmitting(false);
                  enqueueSnackbar("Policy creata", {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    variant: "success",
                  });
                  navigate("/roles?tab=policies");
                } catch (e) {
                  console.error(e);
                  setStatus({ success: false });
                  setSubmitting(false);
                  enqueueSnackbar("Errore durante creazione policy", {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    variant: "error",
                  });
                  return;
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }): JSX.Element => (
                <form onSubmit={handleSubmit} {...props}>
                  <Box sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <Card>
                          <CardContent>
                            <CardHeader title="Impostazioni policy" />
                            <Box sx={{ mt: 2 }}>
                              <Autocomplete
                                disableClearable
                                onChange={(_, newValue: Role) => {
                                  if (!newValue) {
                                    setFieldValue("subject", "");
                                    return;
                                  }
                                  setFieldValue("subject", newValue?.name);
                                }}
                                options={roles}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Ruolo"
                                    variant="outlined"
                                  />
                                )}
                              />
                              <ErrorMessage name="subject" component="div" />
                              <TextField
                                error={Boolean(
                                  touched.resource && errors.resource
                                )}
                                fullWidth
                                helperText={touched.resource && errors.resource}
                                label="Risorsa"
                                id="resource"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.resource}
                                variant="outlined"
                              />

                              <Autocomplete
                                onChange={(_, newValue: any) => {
                                  setFieldValue("action", newValue.value);
                                }}
                                disableClearable
                                defaultValue={{
                                  label: "Permetti",
                                  value: "allow",
                                }}
                                options={[
                                  {
                                    label: "Permetti",
                                    value: "allow",
                                  },
                                  { label: "Nega", value: "deny" },
                                ]}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Azione"
                                    variant="outlined"
                                  />
                                )}
                              />
                              <ErrorMessage name="action" component="div" />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 3,
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Salva
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NewPolicy;
