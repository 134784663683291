import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Link,
    TextField,
    Typography
} from "@mui/material";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Link as RouterLink,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Helmet } from "react-helmet-async";
import GraphqlClient from "src/client/graphql.client";
import { Company } from "src/types/generated";
import useSettings from "../../hooks/useSettings";
import { updateCompany } from "./commands.company";

const UpdateCompany: FC = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [company, setCompany] = useState({} as Company);

  const [loadingData, setLoadingData] = useState(false);

  const idReceived = params.id;

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoadingData(true);
        const res = await GraphqlClient.company({ id: idReceived });
        setCompany(res.company);
      } catch (e) {
        enqueueSnackbar("Errore", {
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          variant: "error",
        });
      } finally {
        setLoadingData(false);
      }
    };

    loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>t("companyupdate_title") </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {t("company_update")}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/companies"
                  variant="subtitle2"
                >
                  Company
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  {t("company_update_breadcrumb")}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<ArrowLeftIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                  to="/companies"
                  variant="outlined"
                >
                  {t("back")}
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Formik
              initialValues={{
                /*TODO: Collegare le props con l'oggetto già popolato: company */
                form_description: "",
              }}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ): Promise<void> => {
                setSubmitting(true);
                let { form_description } = values;
                const res = await updateCompany(idReceived, {
                  description: form_description,
                });
                if (res.error && res.error == true) {
                  console.error(res.message);
                  setStatus({ success: false });
                  setSubmitting(false);
                  enqueueSnackbar(res.message, {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    variant: "error",
                  });
                  return;
                }

                setStatus({ success: true });
                setSubmitting(false);
                navigate("/companies");
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }): JSX.Element => (
                <form onSubmit={handleSubmit} {...props}>
                  <Box sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <Card>
                          <CardContent>
                            <CardHeader title="Form" />

                            <Box sx={{ mt: 2 }}>
                              <TextField
                                required
                                error={Boolean(
                                  touched.form_description &&
                                    errors.form_description
                                )}
                                fullWidth
                                helperText={
                                  touched.form_description &&
                                  errors.form_description
                                }
                                label="form_description"
                                id="form_description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.form_description}
                                variant="outlined"
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        mt: 3,
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        {t("company_update_save_btn")}
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UpdateCompany;
