import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "moment/locale/it";
import { SnackbarProvider } from "notistack";
import type { FC } from "react";
import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import "./App.css";
import { gtmConfig } from "./config";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import "./i18n";
import gtm from "./lib/gtm";
import RTL from "./material/RTL";
import SplashScreen from "./material/SplashScreen";
import routes from "./routes";
import { createTheme } from "./theme";

const queryClient = new QueryClient();

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  return (
    <ThemeProvider
      theme={createTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        mode: settings.theme === "light" ? "light" : "dark",
      })}
    >
      <QueryClientProvider client={queryClient}>
        <RTL direction={settings.direction}>
            <SnackbarProvider dense={false} maxSnack={3}>
              {auth.isInitialized ? content : <SplashScreen />}
            </SnackbarProvider>
        </RTL>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
