import {
    Box, Button, Divider, FormHelperText, Link, TextField
} from '@mui/material';
import { Formik } from 'formik';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import useIsMountedRef from '../../hooks/useIsMountedRef';

import { useTranslation } from "react-i18next";
import { login } from "./commands.login";

const LoginPage: FC = (props) => {
    const isMountedRef = useIsMountedRef();
    const {t} = useTranslation();

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                submit: null
            }}
            validationSchema={
                Yup
                    .object()
                    .shape({
                        email: Yup
                            .string()
                            .email(t("notValidEmail"))
                            .max(255)
                            .required(t("requiredField")),
                        password: Yup
                            .string()
                            .max(255)
                            .required(t("requiredField"))
                    })
            }
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting
            }): Promise<void> => {
                const res = await login(values.email, values.password);
                if (res.logged === true) {
                    if (isMountedRef.current) {
                        setStatus({success: true});
                        setSubmitting(false);
                    }
                } else {
                    if (isMountedRef.current) {
                        setStatus({success: false});
                        setErrors({submit: res.message});
                        setSubmitting(false);
                    }
                }

            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
              }): JSX.Element => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...props}
                >
                    <TextField
                        autoFocus
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                    />
                    <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                    />
                    {errors.submit && (
                        <Box sx={{mt: 3}}>
                            <FormHelperText error>
                                {errors.submit}
                            </FormHelperText>
                        </Box>
                    )}
                    <Box sx={{mt: 2}}>
                        <Button
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            {t("loginBtn")}
                        </Button>
                    </Box>
                  

                    <Divider sx={{my: 3}}/>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Link
                            color="textSecondary"
                            component={RouterLink}
                            sx={{mt: 1}}
                            to="/authentication/password-recovery"
                            variant="body2"
                        >
                            {t("forgotPwd")}
                        </Link>
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default LoginPage;
