// @ts-nocheck
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Container,
  Grid,
  Input,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import { Table } from "src/components/Table/Table";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import GraphqlClient from "src/client/graphql.client";
import useSettings from "../../hooks/useSettings";
import { AcrissCode } from "../../types/generated";

interface AcrissInputProps {
  item: AcrissCode;
}

const AcrissInput = ({ item }: AcrissInputProps) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    GraphqlClient.updateAcrissCode
  );

  const save = (e) => {
    e.preventDefault();

    if (!e.target.value && !item?.code) {
      return;
    }

    mutate(
      { id: item?.id, form: { code: e.target.value, gruppo: item.gruppo } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["acriss"]);
        },
      }
    );

    return;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      save(event);
    }
  };

  return (
    <Input
      endAdornment={
        isLoading && (
          <InputAdornment position="end">
            <CircularProgress size={20} />
          </InputAdornment>
        )
      }
      error={!!error}
      placeholder="Codice ACRISS"
      onKeyDown={handleKeyDown}
      defaultValue={item?.code}
      onBlur={(e) => save(e)}
    />
  );
};

const GroupInput = ({ item }: AcrissInputProps) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    GraphqlClient.updateAcrissCode
  );

  const save = (e) => {
    e.preventDefault();

    if (!e.target.value && !item?.code) {
      return;
    }

    mutate(
      { id: item?.id, form: { code: item.code, gruppo: e.target.value } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["acriss"]);
        },
      }
    );

    return;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      save(event);
    }
  };

  return (
    <Input
      endAdornment={
        isLoading && (
          <InputAdornment position="end">
            <CircularProgress size={20} />
          </InputAdornment>
        )
      }
      error={!!error}
      placeholder="Gruppo"
      onKeyDown={handleKeyDown}
      defaultValue={item?.gruppo}
      onBlur={(e) => save(e)}
    />
  );
};

const PageAcriss: FC = (props) => {
  const [data, setData] = useState<AcrissCode[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { isLoading, isError } = useQuery(
    ["acriss"],
    GraphqlClient.acrissCodes,
    {
      onSuccess: (data) => {
        setData(data.acrissCodes);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          variant: "error",
        });
      },
    }
  );

  if (isLoading) {
    return <Layout>Caricamento in corso...</Layout>;
  }

  return (
    <Layout>
      <Table
        idDefaultColumnSort="provider"
        loading={isLoading}
        Data={data || []}
        Columns={[
          {
            Header: "Auto",
            accessor: "auto",
          },
          {
            Header: "Codice ACRISS",
            accessor: "code",
            Cell: ({ row }) => <AcrissInput item={row.original} />,
          },
          {
            Header: "Gruppo",
            accessor: "gruppo",
            Cell: ({ row }) => <GroupInput item={row.original} />,
          },
          {
            Header: "Classe",
            accessor: "classe",
          },
          {
            Header: "Posti",
            accessor: "posti",
          },
          {
            Header: "Porte",
            accessor: "porte",
          },
          {
            Header: "Cambio",
            accessor: "cambio",
          },
        ]}
      />
    </Layout>
  );
};

export default PageAcriss;

const Layout = ({ children }) => {
  const { settings } = useSettings();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Acriss</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Elenco ACRISS
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Acriss
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Card sx={{ p: 4 }}>{children}</Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};
