import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  DatePickerRangeFilter,
  SelectColumnFilter,
  Table
} from "src/components/Table/Table";
import { readAll as readLocations } from "src/pages/locations/commands.location";
import PermissionGuard from "../../components/PermissionGuard/PermissionGuard";
import useSettings from "../../hooks/useSettings";
import store, { useSelector } from "../../store";
import { FilterInput, GlobalSearchInput } from "../../types/generated";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import GetAppIcon from "@mui/icons-material/GetApp";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DatePicker } from "@mui/lab";
import moment, { ISO_8601 } from "moment";
import GraphqlClient from "src/client/graphql.client";
import useToggle from "src/hooks/useToggle";
import { Can } from "../../pages/login/authorizer";
import { deleteCrawlerResult } from "./commands.crawlerResult";
import { fetchAsyncResults } from "./redux.crawlerResult";

const PageCrawlerResult: FC = (props) => {
  const state = useSelector((state) => state.crawlerResult);
  const user = useSelector((state) => (state as any).auth);
  const { t } = useTranslation();
  const [hiddenColumns, setHiddenColumns] = useState(["companies"]);
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const fetchIdRef = useRef(0);
  const [report, setReport] = useState<string>("Risultati");
  const locationsList = useSelector((state) => state.location.data);
  const [luogo, setLuogo] = useState<any>();
  const [editIncremento, setEditIncremento] = useState<boolean>(false);

  const { ...other } = props;

  const [loading, setLoading] = useState(false);

  const [refresh, toggleRefresh] = useToggle(false);
  const fetchData = useCallback(
    (
      records: number,
      page: number,
      orderBy: string,
      filters: FilterInput[],
      selectOptions: string[],
      globalSearch: GlobalSearchInput
    ) => {
      const fetchId = ++fetchIdRef.current;
      const sumColumns: string[] = []; // nomi colonne su cui effettuare la somma o media
      const avgColumns: string[] = [];

      if (fetchId === fetchIdRef.current) {
        store.dispatch(
          fetchAsyncResults({
            records,
            page,
            orderBy,
            filters,
            selectOptions,
            sumColumns,
            avgColumns,
            globalSearch,
          })
        );
      }
    },
    []
  );

  useEffect(() => {
    const checkRole = async () => {
      let can = await Can("allow", "users");
      if (can) {
        setHiddenColumns([]);
      }
    };
    checkRole();
  }, [user]);

  useEffect(() => {
    if (locationsList.length === 0) {
      readLocations();
    }
  }, [locationsList]);

  const deleteEntry = async (id: string) => {
    try {
      let res = await deleteCrawlerResult(id);
      toggleRefresh();
      enqueueSnackbar("Eliminato con successo", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
        variant: "success",
      });
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar("Impossibile eliminare", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // console.log(dataFrom, dataTo)
    setOpen(false);
    setEditIncremento(false);
    if (
      report === "Risultati per incremento" ||
      report === "Risultati per codice ACRISS"
    ) {
      setDataTo(moment(dataFrom).add(1, "years").toISOString());
    }
  };

  const [dataFrom, setDataFrom] = useState(new Date().toISOString());
  const [dataTo, setDataTo] = useState(new Date().toISOString());

  const [loadingXLS, setLoadingXLS] = useState(false);

  const downloadRisultati = async () => {
    setOpen(false);
    setLoadingXLS(true);
    let from = moment(dataFrom, ISO_8601);
    let to = moment(dataTo, ISO_8601);

    try {
      const res = await GraphqlClient.downloadResults({ start: from, end: to });
      setLoadingXLS(false);

      //trasfomare b64 in xls e download
      const link = document.createElement("a");
      link.href = `data:application/vnd.ms-excel;base64,${res.downloadResults}`;
      link.download = `risultati.xlsx`;
      link.click();
    } catch (e) {
      console.error(e);
      setLoadingXLS(false);
      enqueueSnackbar("Impossibile scaricare il report", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  const downloadRisultatiPerIncremento = async () => {
    setOpen(false);
    setLoadingXLS(true);
    let from = moment(dataFrom, ISO_8601);
    let to = moment(dataTo, ISO_8601);

    if (!luogo) {
      enqueueSnackbar("Devi selezionare un luogo valido", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
      return;
    }

    try {
      const res = await GraphqlClient.downloadResultsForIncrement({
        start: from,
        end: to,
        luogo: luogo.comune,
      });
      setLoadingXLS(false);

      //trasfomare b64 in xls e download
      const link = document.createElement("a");
      link.href = `data:application/vnd.ms-excel;base64,${res.downloadResultsForIncrement}`;
      link.download = `risultatiPerIncremento.xlsx`;
      link.click();
    } catch (e) {
      console.error(e);
      setLoadingXLS(false);
      enqueueSnackbar("Impossibile scaricare il report", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  const downloadRisultatiPerCodiceAcriss = async () => {
    setOpen(false);
    setLoadingXLS(true);
    let from = moment(dataFrom, ISO_8601);
    let to = moment(dataTo, ISO_8601);

    if (!luogo) {
      enqueueSnackbar("Devi selezionare un luogo valido", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
      return;
    }

    try {
      const res = await GraphqlClient.downloadResultsForAcriss({
        start: from,
        end: to,
        luogo: luogo.comune,
      });
      setLoadingXLS(false);

      //trasfomare b64 in xls e download
      const link = document.createElement("a");
      link.href = `data:application/vnd.ms-excel;base64,${res.downloadResultsForAcriss}`;
      link.download = `risultatiPerAcriss.xlsx`;
      link.click();
    } catch (e) {
      console.error(e);
      setLoadingXLS(false);
      enqueueSnackbar("Impossibile scaricare il report", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Risultati Crawler </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Risultati Crawler
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Risultati Crawler
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid display="flex" flexDirection="row" item>
              <Box
                sx={{
                  m: -1,
                  mr: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loadingXLS ? (
                  <CircularProgress color="primary" size={24} disableShrink />
                ) : (
                  <Button
                    color="primary"
                    startIcon={<GetAppIcon />}
                    sx={{ m: 1 }}
                    disabled={loading}
                    variant="contained"
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    Download XLS
                  </Button>
                )}
                <Tooltip title={"Refresh"}>
                  <IconButton onClick={() => toggleRefresh()}>
                    <RefreshIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Box>
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="">Download XLS</DialogTitle>
                  <DialogContent>
                    <Typography>Seleziona il report da scaricare</Typography>
                    <Box
                      sx={{ m: 2 }}
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Autocomplete
                        onChange={(_, newValue) => {
                          setReport(newValue);
                          if (
                            newValue === "Risultati per incremento" ||
                            "Risultati per codice ACRISS"
                          ) {
                            setDataTo(
                              moment(dataFrom).add(1, "years").toISOString()
                            );
                          }
                        }}
                        fullWidth
                        value={report}
                        options={[
                          "Risultati",
                          "Risultati per incremento",
                          "Risultati per codice ACRISS",
                        ]}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Report"
                            variant="outlined"
                          />
                        )}
                      />
                    </Box>
                    {(report === "Risultati per incremento" ||
                      report === "Risultati per codice ACRISS") && (
                      <>
                        <Typography>Seleziona la località</Typography>
                        <Box
                          sx={{ m: 2 }}
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Autocomplete
                            fullWidth
                            value={luogo}
                            onChange={(_, newValue: any) => {
                              setLuogo(newValue);
                            }}
                            options={locationsList.filter(
                              (v, i, a) =>
                                a.findIndex((v2) => v2.comune === v.comune) ===
                                i
                            )}
                            getOptionLabel={(option) => option.comune}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Localita"
                                variant="outlined"
                              />
                            )}
                          />
                        </Box>
                      </>
                    )}
                    <Typography>
                      Inserisci periodo di tempo da analizzare nel report XLS
                    </Typography>
                    <Box
                      sx={{ m: 2 }}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <DatePicker
                        label="Data da"
                        value={dataFrom}
                        onChange={(newValue: string) => {
                          setDataFrom(newValue);
                          if (report === "Risultati per incremento") {
                            setDataTo(
                              moment(newValue).add(1, "year").toISOString()
                            );
                          }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      {report !== "Risultati per incremento" ? (
                        <DatePicker
                          label="Data a"
                          value={dataTo}
                          onChange={(newValue: string) => setDataTo(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      ) : editIncremento ? (
                        <DatePicker
                          label="Data a"
                          value={dataTo}
                          onChange={(newValue: string) => setDataTo(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      ) : (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography>
                            Durata:{" "}
                            {moment(dataTo).diff(moment(dataFrom), "days") ??
                              "0"}{" "}
                            giorni
                          </Typography>
                          <Button onClick={() => setEditIncremento(true)}>
                            Modifica
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Box sx={{ m: 1 }}>
                      <Button
                        onClick={() => {
                          switch (report) {
                            case "Risultati":
                              downloadRisultati();
                              break;
                            case "Risultati per incremento":
                              downloadRisultatiPerIncremento();
                              break;
                            case "Risultati per codice ACRISS":
                              downloadRisultatiPerCodiceAcriss();
                              break;
                          }
                        }}
                        color="primary"
                        variant="contained"
                      >
                        Download
                      </Button>
                      <Button onClick={handleClose} color="primary">
                        Annulla
                      </Button>
                    </Box>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card {...other}>
              <Box sx={{ minWidth: 1200 }}>
                <Table
                  fetchData={fetchData}
                  pageCount={state.pages}
                  loading={state.loading}
                  rowCount={state.count}
                  refresh={refresh}
                  defaultColumnsToHide={hiddenColumns}
                  gSearchTimeColumn={"last_update"}
                  gSearchTimeDefault={{
                    start: moment(new Date()).format("YYYY/MM/DD"),
                    end: moment(new Date()).format("YYYY/MM/DD"),
                  }}
                  selectFilterColumns={["provider", "luogo", "classe"]} // nome della colonna su Postgres su cui applicare il filtro select
                  selectFilterOptions={state.options}
                  idDefaultColumnSort="last_update"
                  Data={state.data || []}
                  Columns={[
                    {
                      Header: "ID ricerca",
                      accessor: "jobId",
                      id: "jobID",

                      width: 350,
                    },
                    {
                      Header: "Provider",
                      accessor: "provider",
                      id: "provider",
                      Filter: SelectColumnFilter,
                    },
                    {
                      Header: "Localita",
                      accessor: "luogo",
                      width: 250,
                      Filter: SelectColumnFilter,
                      Cell: (row) => {
                        return (
                          <Chip
                            label={row.row.original.luogo}
                            style={{ margin: "1px" }}
                          />
                        );
                      },
                    },
                    {
                      Header: "Venditore",
                      accessor: "venditore",
                      Cell: (row) => {
                        return (
                          <Chip
                            label={row.row.original.venditore?.toUpperCase()}
                            style={{ margin: "1px" }}
                          />
                        );
                      },
                      width: 250,
                    },

                    {
                      Header: "Ultimo aggiornamento",
                      accessor: "last_update",
                      width: 250,
                      Cell: (row) => {
                        return moment(row.value).format(
                          "DD MMM YYYY | HH:MM:SS"
                        );
                      },
                      Filter: DatePickerRangeFilter,
                    },
                    {
                      Header: "Classe",
                      accessor: "classe",
                      id: "classe",
                      Filter: SelectColumnFilter,
                      Cell: (row) => {
                        return (
                          <Chip
                            label={row.value.toUpperCase()}
                            style={{ margin: "1px" }}
                          />
                        );
                      },
                    },
                    {
                      Header: "Dal",
                      accessor: "durata_dal",
                      Cell: (row) => {
                        return moment(
                          row.row.original.durata_dal,
                          "DD/MM/YYYY"
                        ).format("DD MMM YYYY");
                      },
                    },
                    {
                      Header: "Al",
                      accessor: "durata_al",
                      Cell: (row) => {
                        return moment(
                          row.row.original.durata_al,
                          "DD/MM/YYYY"
                        ).format("DD MMM YYYY");
                      },
                    },
                    {
                      Header: "Giorni",
                      accessor: "durata_gg",
                    },
                    {
                      Header: "Importo",
                      accessor: "prezzo_totale",
                      Cell: (row) => {
                        return (
                          <Chip
                            label={row.row.original.prezzo_totale.toFixed(2)}
                            style={{ margin: "1px" }}
                            color="primary"
                          />
                        );
                      },
                    },
                    {
                      Header: "Prezzo giornaliero",
                      accessor: "prezzo_giornaliero",
                      minWidth: 250,
                      Cell: (row) => {
                        return (
                          <Chip
                            label={row.row.original.prezzo_giornaliero.toFixed(
                              2
                            )}
                            style={{ margin: "1px" }}
                          />
                        );
                      },
                    },
                    // {
                    //     "Header": "Clienti",
                    //     "accessor": "companies",
                    //     id: 'companies',
                    //     // Filter: SelectColumnFilter,
                    //     // filter: SelectColumnFilterMethod,
                    //     Cell: (row) => {
                    //         return (
                    //             <div>
                    //                 {row.row.original.companies?.map(
                    //                     elem => {
                    //                         return (
                    //                             <Chip
                    //                                 label={elem}
                    //                                 style={{ margin: '1px' }}
                    //                             />
                    //                         )
                    //                     }
                    //                 )}
                    //             </div>
                    //         )
                    //     },
                    //     width: 350,
                    // },
                    {
                      Header: "Azioni",
                      accessor: "azioni",
                      Cell: (row) => (
                        <>
                          <PermissionGuard
                            action={"allow"}
                            resource={"updateCrawlerResult"}
                          >
                            <IconButton
                              component={RouterLink}
                              to={`/crawlerResults/update/${row.row.values.id}`}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </PermissionGuard>
                          <PermissionGuard
                            action={"allow"}
                            resource={"deleteCrawlerResult"}
                          >
                            <Tooltip title={"Elimina"}>
                              <IconButton
                                aria-label="Elimina"
                                color="secondary"
                                onClick={() => deleteEntry(row.row.values.id)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </PermissionGuard>

                          {/* 
                                                            <IconButton>
                                                                <ArrowRightIcon fontSize="small" />
                                                            </IconButton> */}
                        </>
                      ),
                    },
                  ]}
                />
              </Box>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PageCrawlerResult;
