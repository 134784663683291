import {createSlice} from '@reduxjs/toolkit'
import { Location } from 'src/types/generated';

const slice = createSlice({
    name: 'locations',
    initialState: {
        data: [] as Location[] 
    },
    reducers: {
        readedAll: (state, action) => {
            state.data = action.payload;
        },
        created: (state, action) => {
            state.data?.push(action.payload);
        },
    },
})

export const actions = slice.actions;
export default slice.reducer;
