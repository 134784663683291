import { lazy, Suspense } from 'react';
import type { PartialRouteObject } from 'react-router';
import PermissionGuard from "./components/PermissionGuard/PermissionGuard";
import AuthGuard from './material/AuthGuard';
import DashboardLayout from './material/dashboard/DashboardLayout';
import GuestGuard from './material/GuestGuard';
import LoadingScreen from './material/LoadingScreen';
import PasswordRecovery from "./pages/authentication/password-recovery/PasswordRecovery";
import PasswordReset from "./pages/authentication/password-reset/PasswordReset";
import NewGroup from "./pages/roles/new.group";
import NewPolicy from "./pages/roles/new.policy";
import NewRole from "./pages/roles/new.role";
import PageRole from "./pages/roles/page.role";
import NewUser from "./pages/users/new.user";
import PageUsers from "./pages/users/page.user";
import UpdateUser from "./pages/users/update.user";
// YEOMAN IMPORT ANCHOR */

import NewCompany from "src/pages/company/new.company";
import PageCompany from "src/pages/company/page.company";
import UpdateCompany from "src/pages/company/update.company";
import PageCrawlerResult from "src/pages/crawlerResult/page.crawlerResult";
import NewCustomerconfig from "src/pages/customerconfig/new.customerconfig";
import PageCustomerconfig from "src/pages/customerconfig/page.customerconfig";
import UpdateCustomerconfig from "src/pages/customerconfig/update.customerconfig";
import PageLocation from "src/pages/locations/page.location";
import NewProviderConfig from "src/pages/providerConfig/new.providerConfig";
import PageProviderConfig from "src/pages/providerConfig/page.providerConfig";
import UpdateProviderConfig from "src/pages/providerConfig/update.providerConfig";
import PageAcriss from './pages/acriss/page.acriss';
import PageAnalytics from './pages/analytics/page.analytics';
import PageJobList from './pages/jobList/page.joblist';
import NewLocation from './pages/locations/new.location';
import UpdateLocation from './pages/locations/update.location';

const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/account/pagina.account')));
const Finance = Loadable(lazy(() => import('./pages/dashboard/pagina.dashboard')));


const routes: PartialRouteObject[] = [
    {
        path: 'authentication',
        children: [
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                )
            },
            {
                path: 'register',
                element: (
                    <GuestGuard>
                        <Register />
                    </GuestGuard>
                )
            },
            {
                path: 'register-unguarded',
                element: <Register />
            },
            {
                path: 'password-recovery',
                element: <PasswordRecovery />
            },
            {
                path: 'password-reset',
                element: <PasswordReset />
            },
        ]
    },
    {
        path: '*',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '/',
                element: <Finance />
            },
            {
                path: 'analytics',
                element: <PageAnalytics />
            },
            {
                path: 'account',
                element: <Account />
            },
            {
                path: 'users',
                children: [
                    {
                        path: '/',
                        element:
                            <PermissionGuard action={"allow"} resource={"users"}>
                                <PageUsers />
                            </PermissionGuard>
                    },
                    {
                        path: 'new',
                        element: <PermissionGuard action={"allow"} resource={"createUser"}>
                            <NewUser />
                        </PermissionGuard>
                    },
                    {
                        path: 'modifica/:id',
                        element: <PermissionGuard action={"allow"} resource={"updateUser"}>
                            <UpdateUser />
                        </PermissionGuard>
                    }
                ]
            },
            {
                path: 'roles',
                children: [
                    {
                        path: '/',
                        element: <PageRole />
                    },
                    {
                        path: 'new/ruolo',
                        element: <NewRole />
                    },
                    {
                        path: 'new/gruppo',
                        element: <NewGroup />
                    },
                    {
                        path: 'nuova/policy',
                        element: <NewPolicy />
                    },
                ]
            },
            // YEOMAN ANCHOR */
            {
                path: 'acriss',
                element: <PageAcriss />,
            },
            {
                path: 'customerconfigs',
                children: [
                    {
                        path: '/',
                        element:
                            <PermissionGuard action={"allow"} resource={"customerconfigs"}>
                                <PageCustomerconfig />
                            </PermissionGuard>
                    },
                    {
                        path: 'new',
                        element:
                            <PermissionGuard action={"allow"} resource={"createCustomerconfig"}>
                                <NewCustomerconfig />
                            </PermissionGuard>
                    },
                    {
                        path: 'update/:id',
                        element:
                            <PermissionGuard action={"allow"} resource={"updateCustomerconfig"}>
                                <UpdateCustomerconfig />
                            </PermissionGuard>
                    },
                ]
            },

            {
                path: 'companies',
                children: [
                    {
                        path: '/',
                        element:
                            <PermissionGuard action={"allow"} resource={"companies"}>
                                <PageCompany />
                            </PermissionGuard>
                    },
                    {
                        path: 'new',
                        element:
                            <PermissionGuard action={"allow"} resource={"createCompany"}>
                                <NewCompany />
                            </PermissionGuard>
                    },
                    {
                        path: 'update/:id',
                        element:
                            <PermissionGuard action={"allow"} resource={"updateCompany"}>
                                <UpdateCompany />
                            </PermissionGuard>
                    },
                ]
            },

            {
                path: 'crawlerResults',
                children: [
                    {
                        path: '/',
                        element:
                            <PermissionGuard action={"allow"} resource={"crawlerResults"}>
                                <PageCrawlerResult />
                            </PermissionGuard>
                    },


                ]
            },
            {
                path: 'jobList',
                element:
                    <PermissionGuard action={"allow"} resource={"viewJobList"}>
                        <PageJobList />
                    </PermissionGuard>
            },

            {
                path: 'providerConfigs',
                children: [
                    {
                        path: '/',
                        element:
                            <PermissionGuard action={"allow"} resource={"providerConfigs"}>
                                <PageProviderConfig />
                            </PermissionGuard>
                    },
                    {
                        path: 'new',
                        element:
                            <PermissionGuard action={"allow"} resource={"createProviderConfig"}>
                                <NewProviderConfig />
                            </PermissionGuard>
                    },
                    {
                        path: 'update/:id',
                        element:
                            <PermissionGuard action={"allow"} resource={"updateProviderConfig"}>
                                <UpdateProviderConfig />
                            </PermissionGuard>
                    },
                ]
            },

            {
                path: 'location',
                children: [
                    {
                        path: '/',
                        element:
                            //<PermissionGuard action={"allow"} resource={"providerConfigs"}>
                            <PageLocation />
                        // </PermissionGuard>
                    },
                    {
                        path: 'new',
                        element:
                            //<PermissionGuard action={"allow"} resource={"createProviderConfig"}>
                            <NewLocation />
                        //</PermissionGuard>
                    },
                    {
                        path: 'update/:id',
                        element:
                            //<PermissionGuard action={"allow"} resource={"updateProviderConfig"}>
                            <UpdateLocation />
                        //</PermissionGuard>
                    },
                ]
            },

        ]
    },
];

export default routes;
