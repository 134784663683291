import { useSnackbar } from "notistack";
import { FC, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Chip,
    Container,
    Grid,
    IconButton,
    Link,
    Tooltip,
    Typography
} from "@mui/material";
import {
    SelectColumnFilter,
    SelectColumnFilterMethod,
    Table
} from "src/components/Table/Table";

import Add from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import useSettings from "../../hooks/useSettings";
import { useSelector } from "../../store";
import { deleteLocation, readAll } from "./commands.location";

const PageLocation: FC = (props) => {
  const data = useSelector((state) => state.location.data);
  const user = useSelector((state) => (state as any).auth);
  const { t } = useTranslation();

  const { settings } = useSettings();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { ...other } = props;

  useEffect(() => {
    readAll();
  }, []);

  return (
    <>
      <Helmet>
        <title>Localita</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Elenco Localita
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Localita
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <RouterLink
                  to={{
                    pathname: "/location/new",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    color="primary"
                    startIcon={<Add fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                  >
                    Crea nuova localita
                  </Button>
                </RouterLink>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Card {...other}>
              <Table
                idDefaultColumnSort="provider"
                Data={data || []}
                Columns={[
                  {
                    Header: "Provider",
                    accessor: "provider",
                    id: "provider",
                    Filter: SelectColumnFilter,
                    filter: SelectColumnFilterMethod,
                  },
                  {
                    Header: "Localita",
                    accessor: "comune",
                    id: "comune",
                    Filter: SelectColumnFilter,
                    filter: SelectColumnFilterMethod,
                    Cell: (row) => (
                      <Chip
                        label={row.row.original.comune}
                        style={{ margin: "1px" }}
                      />
                    ),
                  },
                  {
                    Header: "Transcodifica",
                    accessor: "transCodifica",
                    Cell: (row) => (
                      <div style={{ overflow: "hidden" }}>
                        <Chip
                          label={row.row.original.transCodifica}
                          style={{ margin: "1px" }}
                        />
                      </div>
                    ),
                    disableFilters: true,
                  },

                  {
                    Header: "Azioni",
                    Cell: (row) => (
                      <>
                        <IconButton
                          component={RouterLink}
                          to={`/location/update/${row.row.original.id}`}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                        <Tooltip title={"Elimina"}>
                          <IconButton
                            aria-label="Elimina"
                            color="secondary"
                            onClick={() => {
                              deleteLocation(row.row.original.id);
                              readAll();
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    ),
                  },
                ]}
              />
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PageLocation;
