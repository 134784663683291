import { DateFilter } from "src/types/generated";
import GraphqlClient from "../../client/graphql.client";
import store from "../../store";
import { actions } from "./redux.jobList";

export const readAll = async (filter: DateFilter) => {
	try {
		const res = await GraphqlClient.crawlerJobs({ filter });
		let data = res.crawlerJobs;

		store.dispatch(actions.readedAll(data));
		return { data: data };
	} catch (error) {
		store.dispatch(actions.readedAll([]));
		return {
			error: true,
			message: JSON.parse(JSON.stringify(error)).response.error,
		};
	}
};
