
import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
    Box, Breadcrumbs, Button, Card, Container,
    Grid, IconButton, Link,
    Tooltip,
    Typography
} from '@mui/material';

import { Table } from 'src/components/Table/Table';

import Add from "@mui/icons-material/Add";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { Helmet } from "react-helmet-async";
import PermissionGuard from "../../components/PermissionGuard/PermissionGuard";
import useSettings from "../../hooks/useSettings";
import { useSelector } from "../../store";

import { deleteCustomerconfig, readAll } from "./commands.customerconfig";

const PageCustomerconfig: FC = (props) => {
    const data = useSelector(state => state.customerconfig.data);

    const { settings } = useSettings();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { ...other } = props;

    useEffect(() => {
        readAll();
    }, [])

    const deleteEntry = async (id: string) => {
        try {
            let res = await deleteCustomerconfig(id);
            readAll();
            enqueueSnackbar("Eliminato con successo", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom'
                },
                variant: 'success'
            });
        } catch (e) {
            console.log('Error', e);
            enqueueSnackbar("Impossibile eliminare", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    }

    // @ts-ignore
    return (
        <>
            <Helmet>
                <title>Dashboard: customerconfig </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Configurazione clienti
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    customerconfig
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <PermissionGuard action={'allow'} resource={'createCustomerconfig'}>
                            <Grid item>
                                <Box sx={{ m: -1 }}>
                                    <RouterLink to={{
                                        pathname: "/customerconfigs/new",
                                    }} style={{ textDecoration: 'none' }}>
                                        <Button
                                            color="primary"
                                            startIcon={<Add fontSize="small" />}
                                            sx={{ m: 1 }}
                                            variant="contained"
                                        >
                                            Aggiungi
                                        </Button>
                                    </RouterLink>
                                </Box>
                            </Grid>
                        </PermissionGuard>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Card {...other}>
                            <Table
                                idDefaultColumnSort="customer"
                                Data={data ?? []}
                                Columns={
                                    [
                                        { Header: "customer", accessor: "customer" },

                                        { Header: "provider", accessor: "provider" },

                                        { Header: "name", accessor: "name" },

                                        {
                                            Header: 'Azioni',
                                            accessor: 'azioni',
                                            Cell: row => {
                                                return (
                                                    <>
                                                        <PermissionGuard action={'allow'}
                                                            resource={"updateCustomerconfig"}>
                                                            <IconButton component={RouterLink}
                                                                to={`/customerconfigs/update/${row.row.original.id}`}>
                                                                <Edit fontSize="small" />
                                                            </IconButton>
                                                        </PermissionGuard>
                                                        <PermissionGuard action={'allow'}
                                                            resource={"deleteCustomerconfig"}>
                                                            <Tooltip title={"Elimina"}>
                                                                <IconButton
                                                                    aria-label='Elimina'
                                                                    color="secondary"
                                                                    onClick={() => deleteEntry(row.row.original.id)}
                                                                >
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </PermissionGuard>

                                                        <IconButton>
                                                            <ArrowRightIcon fontSize="small" />
                                                        </IconButton>
                                                    </>
                                                )
                                            }
                                        },
                                    ]}
                            />
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default PageCustomerconfig;
