import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    it: {
        translation: {
            "user": "Utente",
            'loginHeader': 'Benvenuto',
            'loginDescription': 'Accedi alla piattaforma',
            'loginBtn': 'Accedi',
            'logout': 'Disconnetti',
            "recoveryHeader": "Recupero Password",
            "recoveryDescription": "A quale email vuoi ricevere le istruzioni per il recupero?",
            "proceed": "Procedi",
            "companyDomain": "Dominio Aziendale",
            "emailAddress": "Indirizzo Email",
            'demoLabel': 'Utilizzare',
            'forgotPwd': 'Password dimenticata',
            "requiredField": "Campo obbligatorio",
            "notValidEmail": "Email non valida",
            "profile": "Account",
            "accountGeneral": "Generale",
            "accountSecurity": "Sicurezza",
            "setAvatar": "Imposta avatar",
            "removeAvatar": "Rimuovi avatar",
            "profileT": "Profilo",

            "firstName": "Nome",
            "lastName": "Cognome",
            "save": "Salva",

            "changePwd": "Cambia password",
            "oldPwd": "Vecchia password",
            "password": "Password",
            "confirmPassword": "Conferma password",
            "pwdMismatch": "Le password devono coincidere",
            "pwdUpdated": "Password aggiornata con successo",
            "minLen7": "Lunghezza minima: 7 caratteri",

            "newUser": "Nuovo utente",
            "user_breadcrumb": "Nuovo utente",
            "usersT": "Utenti",
            "newUserTitle": "Informazioni login",
            "newUserTitle2": "Anagrafica",

            /*SETTINGS */
            "theme": "Tema",
            "dark": "Scuro",
            "light": "Chiaro",
            "settings": "Impostazioni",
            "saveSettings": "Salva Impostazioni",
            "rtl": "Cambia direzione testo",
            "responsiveFont": "Grandezza font dinamica",
            "responsiveFontDescription": "Dimensione font responsive",
            "compact": "Compatto",
            "compactDescription": "Larghezza fissa in alcune risoluzioni",
            "roundBorders": "Bordi rotondi",
            "roundBordersDescription": "Aumenta il raddio dei bordi",

            /* GENERAL */
            "back": "Indietro",
            "confirm": "Conferma",
            "actions": "Azioni",
            "action": "Azione",
            "by": "Da",
            "at": "Data",
            "open": "Aperto",
            "closed": "Chiuso",
            "acknowledge": "Accetta",
            "idle": "Stallo",
            "details": "Dettagli",
            "closeIt": "Chiudi",
            "openIt": "Apri",
            "delete": "Elimina",
            "cancel": "Annulla",
            "warning": "Avviso",
            "new": "Nuova",
            "add": "Aggiungi",
            "logs": "Logs",
            "waiting": "In Attesa",
            "notAssigned": "Non Assegnato",
            "errorComments": "Errore durante il caricamento dei commenti",

            /* DIALOG */
            "definitiveAction": "Questa è un'azione irreversibile, vuoi continuare?",

            /* NOTIFICATIONS */
            "error": "Errore",
            "success": "Confermato",

            /* SIDEBAR */
            "users": "Utenti",
            "list": "Lista",
            "rolesAndPerms": "Ruoli e Permessi",
            "tables": "Tabelle",
            "general": "Generale",

            /*ROLES & PERMS*/
            "role": "Ruolo",
            "roles": "Ruoli",
            "policy": "Policy",
            "resource": "Risorsa",
            "subject": "Soggetto",
            "domain": "Dominio",
            "groups": "Gruppi",
            "newRole": "Nuovo Ruolo",
            "roleInfo": "Informazioni Ruolo",
            "newPolicy": "Nuova Policy",
            "newGroup": "Nuovo Gruppo",
            "policySettings": "Impostazioni Policy",
            "policySettings1": "Impostazioni Policy",
            "roleCreated": "Ruolo Creato",
            "errorLoadingPolicy": "Errore durante il caricamento delle policy",
            "errorLoadingGroups": "Errore durante il caricamento dei gruppi",
            "policyCreated": "Policy creata",
            "policyInfo": "Informazioni Policy",
            "groupCreated": "Gruppo creato",
            "groupInfo": "Informazioni Gruppo",
            "allow": "Permetti",
            "deny": "Nega",

            /* DATE FILTER */
            "startDate": "Data Inizio",
            "endDate": "Data Fine",
            "today": "Oggi",
            "yesterday": "Ieri",
            "tomorrow": "Domani",
            "week": "Settimana",
            "month": "Mese",
            "lastMonth": "Mese Scorso",
            "nextMonth": "Mese Prossimo",

            /* SPLASH PAGE */
            "splashHeader": "Benvenuti",
            "splashDescription": "Seleziona una delle opzioni per continuare",
            "newReport": "Nuova Segnalazione",
            "findReport": "Trova la tua segnalazione",
            "reportID": "Report ID",
            "agentLogin": "Login Agente",

            /* NEW REPORT */
            "newDescription": "Invia una segnalazione anonima",
            "selectCategory": "Seleziona una categoria",
            "chooseAttachment": "Scegli gli allegati",
            "confirmReportQuestion": "Sei sicuro?",
            "confirmReport": "La segnalazione sarà completamente anonima",
            "errorReport": "Errore durante l'invio della segnalazione",
            "successReport": "Invio confermato",

            /* GUEST REPORT */
            "originalReport": "Segnalazione Originale",
            "currentStatus": "Status Corrente",
            "lastFollowup": "Ultimo Follow-Up",

            /* TABLE */
            "of": "di",
            "tableNoData": "Nessun dato trovato",
            "select": "Seleziona",

            /* ADMIN */
            "controlPanel": "Pannello di Controllo",
            "companies": "Aziende",
            "company": "Azienda",
            "referral": "Referente",
            "referrals": "Referenti",
            "type": "Tipologia",
            "createCompany": "Crea Azienda",
            "newCompany": "Nuova Azienda",

            /* YEOMAN IT ANCHOR */
"customerconfig_update_save_btn": "Salva customerconfig",
"customerconfig_update_breadcrumb": "Aggiorna customerconfig",
"customerconfig_update_title": "customerconfig: Aggiorna",
"customerconfig_update": "Aggiorna customerconfig",
"customerconfig_create_save_btn": "Salva customerconfig",
"customerconfig_breadcrumb": "Nuovo customerconfig",
"customerconfig_title": "customerconfig: Nuovo",
"customerconfig_new": "Nuovo customerconfig",
"customerconfig_delete_button": "Elimina customerconfig",
"customerconfig_update_button": "Aggiorna customerconfig",
"customerconfig_new_button": "Nuovo customerconfig",
"customerconfig_page_header": "customerconfig",
"company_update_save_btn": "Salva company",
"company_update_breadcrumb": "Aggiorna company",
"company_update_title": "company: Aggiorna",
"company_update": "Aggiorna company",
"company_create_save_btn": "Salva company",
"company_breadcrumb": "Nuovo company",
"company_title": "company: Nuovo",
"company_new": "Nuovo company",
"company_delete_button": "Elimina company",
"company_update_button": "Aggiorna company",
"company_new_button": "Nuovo company",
"company_page_header": "company",
            "reports": "Segnalazioni",
            "report": "Segnalazione",
            "state": "Stato",
            "assigned": "Assegnato a",
            "createdAt": "Creato Il",
            "createdBy": "Creato Da",
            "updatedAt": "Modificato Il",
            "updatedBy": "Modificato Da",
            "receivedAt": "Ricevuto il",
            "requiresFeedbackAt": "Feedback da inviare entro",
            "requiresFollowupAt": "Follow-up da inviare entro",
            "attachments": "Allegati",
            "newComment": "Nuovo Commento",
            "send": "Invia",
            "sendFeedback": "Invia Feedback",
            "customization": "Personalizzazione",
            "forms": "Moduli",
            "description": "Descrizione",
            "categories": "Categorie",
            "category": "Categoria",
            "name": "Nome",
            "search": "Cerca",
            "guests": "Ospiti",
            "guest": "Ospite",
            "assign": "Assegna",
            "assignDialogTitle": "Assegna",
            "assignDialogDescription": "Scegli uno o più utenti a cui assegnare la segnalazione",

            "providerConfig_new": "Nuova configurazione provider",
            "providerConfig_create_save_btn": "Salva nuova configurazione provider",
            "providerNewConfig_breadcrumb_": "Nuova configurazione provider",
            "providerConfig_update_breadcrumb": "Aggiorna configurazione provider",
            "providerConfig_title": "ProviderConfig",
            "providerConfig_update_title": "Modifica ProviderConfig",
            "providerConfig_update_save_btn": "Salva ProviderConfig aggiornata",
            "providerConfig_update": "Aggiorna configurazione Provider",
        }
    },
    en: {
        translation: {
            "user": "User",
            'loginHeader': 'Welcome',
            'loginDescription': 'Log in on the internal platform',
            'loginBtn': 'Log In',
            "recoveryHeader": "Password Recovery",
            "recoveryDescription": "Provide the email address where you want to receive the recovery instructions",
            "proceed": "Proceed",
            'demoLabel': 'Use',
            "companyDomain": "Company Domain",
            "emailAddress": "Email Address",
            'forgotPwd': 'Recover password',
            "requiredField": "Required field",
            "notValidEmail": "Not valid email",
            'logout': 'Logout',
            "profile": "Account",
            "accountGeneral": "Overview",
            "accountSecurity": "Security",
            "setAvatar": "Set avatar",
            "removeAvatar": "Remove avatar",
            "profileT": "Profile",
            "firstName": "First name",
            "lastName": "Last name",
            "save": "Save",

            "changePwd": "Change password",
            "oldPwd": "Old password",
            "password": "Password",
            "confirmPassword": "Confirm password",
            "pwdMismatch": "Password must be identical",
            "pwdUpdated": "Password updated",
            "minLen7": "Min len: 7 characters",

            "newUser": "New user",
            "user_breadcrumb": "New user",
            "usersT": "Users",
            "newUserTitle": "Login credentials",
            "newUserTitle2": "Overview",

            /*ROLES & PERMS*/
            "role": "Role",
            "roles": "Roles",
            "policy": "Policy",
            "groups": "Groups",
            "resource": "Resource",
            "subject": "Subject",
            "domain": "Domain",
            "newRole": "New Role",
            "roleInfo": "Role Info",
            "newPolicy": "New Policy",
            "newGroup": "New Group",
            "policySettings": "Policy Settings",
            "policySettings1": "Policy Settings",
            "roleCreated": "Role Created",
            "errorLoadingPolicy": "Error while loading policies",
            "errorLoadingGroups": "Error while loading groups",
            "policyCreated": "Policy created",
            "policyInfo": "Informazioni Policy",
            "groupInfo": "Group Info",
            "groupCreated": "Group created",
            "allow": "Allow",
            "deny": "Deny",

            /*SETTINGS */
            "theme": "Theme",
            "DARK": "Dark",
            "LIGHT": "Light",
            "NATURE": "Nature",
            "settings": "Settings",
            "saveSettings": "Save settings",
            "rtl": "Change text direction",
            "responsiveFont": "Responsive font sizes",
            "responsiveFontDescription": "Adjust font for small devices",
            "compact": "Compact",
            "compactDescription": "Fixed width on some screens",
            "roundBorders": "Rounded Corners",
            "roundBordersDescription": "Increase border radius",

            /* GENERAL */
            "back": "Back",
            "confirm": "Confirm",
            "actions": "Actions",
            "action": "Action",
            "by": "By",
            "at": "At",
            "open": "Open",
            "acknowledge": "Acknowledge",
            "closed": "Closed",
            "idle": "Idle",
            "details": "Details",
            "closeIt": "Close",
            "openIt": "Open",
            "delete": "Delete",
            "cancel": "Cancel",
            "warning": "Warning",
            "new": "New",
            "add": "Add",
            "logs": "Logs",
            "waiting": "Waiting",
            "notAssigned": "Not Assigned",
            "errorComments": "Error while loading comments",

            /* DIALOG */
            "definitiveAction": "This is a definitive action, are you sure?",

            /* NOTIFICATIONS */
            "error": "Error",
            "success": "Success!",

            /* SIDEBAR */
            "users": "Users",
            "list": "List",
            "rolesAndPerms": "Roles and Permissions",
            "tables": "Tables",
            "general": "General",

            /* DATE FILTER */
            "startDate": "Start Date",
            "endDate": "End Date",
            "today": "Today",
            "yesterday": "Yesterday",
            "tomorrow": "Tomorrow",
            "week": "Week",
            "month": "Month",
            "lastMonth": "Last Month",
            "nextMonth": "Next Month",

            /* SPLASH PAGE */
            "splashHeader": "Welcome",
            "splashDescription": "Choose one of the options to continue",
            "newReport": "New Report",
            "findReport": "Find Your Report",
            "reportID": "Report ID",
            "agentLogin": "Agent's Login",

            /* NEW REPORT */
            "newDescription": "Send an anonimous report",
            "selectCategory": "Select one category",
            "chooseAttachment": "Choose the attachments",
            "confirmReportQuestion": "Are you sure?",
            "confirmReport": "The report will be completely anonymous",
            "errorReport": "Error",
            "successReport": "Success",

            /* GUEST REPORT */
            "originalReport": "Original Report",
            "currentStatus": "Current Status",
            "lastFollowup": "Last Follow-Up",

            /* TABLE */
            "of": "of",
            "tableNoData": "No data was found",
            "select": "Select",

            /* ADMIN */
            "controlPanel": "Control Panel",
            "companies": "Companies",
            "company": "Company",
            "referral": "Referral",
            "referrals": "Referrals",
            "type": "Type",
            "createCompany": "Create Company",
            "newCompany": "New Company",

            /* YEOMAN EN ANCHOR */
"customerconfig_update_save_btn": "Save customerconfig",
"customerconfig_update_breadcrumb": "Update customerconfig",
"customerconfig_update_title": "customerconfig: Update",
"customerconfig_update": "Update customerconfig",
"customerconfig_create_save_btn": "Save customerconfig",
"customerconfig_breadcrumb": "New customerconfig",
"customerconfig_title": "customerconfig: New",
"customerconfig_new": "New customerconfig",
"customerconfig_delete_button": "Delete customerconfig",
"customerconfig_update_button": "Update customerconfig",
"customerconfig_new_button": "New customerconfig",
"customerconfig_page_header": "customerconfig",
"company_update_save_btn": "Save company",
"company_update_breadcrumb": "Update company",
"company_update_title": "company: Update",
"company_update": "Update company",
"company_create_save_btn": "Save company",
"company_breadcrumb": "New company",
"company_title": "company: New",
"company_new": "New company",
"company_delete_button": "Delete company",
"company_update_button": "Update company",
"company_new_button": "New company",
"company_page_header": "company",
            "reports": "Reports",
            "report": "Report",
            "state": "State",
            "assigned": "Assigned to",
            "createdAt": "Created At",
            "createdBy": "Created By",
            "updatedAt": "Updated At",
            "updatedBy": "Updated By",
            "receivedAt": "Received At",
            "requiresFeedbackAt": "Requires feedback before",
            "requiresFollowupAt": "Requires follow-up before",
            "attachments": "Attachments",
            "newComment": "New Comment",
            "send": "Send",
            "sendFeedback": "Send Feedback",
            "customization": "Customization",
            "forms": "Forms",
            "description": "Description",
            "categories": "Categories",
            "category": "Category",
            "name": "Name",
            "search": "Search",
            "guests": "Guests",
            "guest": "Guest",
            "assign": "Assign",
            "assignDialogTitle": "Assign",
            "assignDialogDescription": "Pick one or more users to whom assign the report",

            "providerConfig_new": "New provider configuration",
            "providerConfig_create_save_btn": "Save new provider configuration",
            "providerNewConfig_breadcrumb_": "New configuration provider",
            "providerConfig_title": "ProviderConfig",
            "providerConfig_update_title": "Update ProviderConfig",
            "providerConfig_update_save_btn": "Save Update ProviderConfig"
        }
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: window.localStorage.getItem('lang'),
        fallbackLng: 'it',
        interpolation: {
            escapeValue: false
        }
    });
