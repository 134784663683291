import store from "../../store";

import {actions} from './redux.company';
import GraphqlClient from "../../client/graphql.client";

import {CompanyInputForm} from "src/types/generated";
import {CompanyUpdateForm} from "src/types/generated";

export const readAll = async () => {
    try {
        const res = await GraphqlClient.companys({});
        let data = res.companys || [];
        store.dispatch(actions.readedAll(data));
        return {data: data}
    } catch (error) {
        store.dispatch(actions.readedAll([]));
        return {error: true, message: JSON.parse(JSON.stringify(error)).response.error}
    }
};

export const createCompany = async (form?: CompanyInputForm) => {
    try {
        const res = await GraphqlClient.createCompany({form});
        store.dispatch(actions.created(res.createCompany));
        return {data: res.createCompany}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};


export const updateCompany = async (id: string,form?: CompanyUpdateForm) => {
    try {
        const res = await GraphqlClient.updateCompany({id,form});
        store.dispatch(actions.created(res.updateCompany));
        return {data: res.updateCompany}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};


export const deleteCompany = async (id: string) => {
    try {
        const res = await GraphqlClient.deleteCompany({id});
        return {data: res.deleteCompany}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};
