import BarChart from "@mui/icons-material/BarChart";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import Group from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SettingsIcon from "@mui/icons-material/Settings";
import TableChartIcon from "@mui/icons-material/TableChart";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Can } from "../pages/login/authorizer";
import { User } from "../types/generated";
import Logo from "./Logo";
import NavSection from "./NavSection";
import Scrollbar from "./Scrollbar";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  openSidebar: boolean;
}

const Sidebar: FC<DashboardSidebarProps> = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const sections = [
    {
      title: "General",
      items: [
        {
          title: "Dashboard",
          path: "/",
          icon: <BarChart fontSize="small" />,
        },
        {
          title: "Analytics",
          path: "/analytics",
          icon: <QueryStatsIcon fontSize="small" />,
        },
        {
          title: "Account",
          path: "/account",
          icon: <PersonIcon fontSize="small" />,
        },
      ],
    },
    {
      title: "Tabelle",
      items: [
        // YEOMAN ROUTE ANCHOR

        {
          title: "Risultati Crawler",
          path: "/crawlerResults",
          icon: <TableChartIcon fontSize="small" />,
          authorization: { resource: "crawlerResults", action: "allow" },
        },
        {
          title: "Configurazioni Provider",
          path: "/providerConfigs",
          icon: <SettingsIcon fontSize="small" />,
          authorization: { resource: "providerConfigs", action: "allow" },
        },
        {
          title: "Ricerche programmate",
          path: "/jobList",
          icon: <FindInPageIcon fontSize="small" />,
          authorization: { resource: "viewJobList", action: "allow" },
        },
        {
          title: "Localita",
          path: "/location",
          icon: <PlaceIcon fontSize="small" />,
          authorization: { resource: "viewJobList", action: "allow" },
        },
        {
          title: "Acriss",
          path: "/acriss",
          icon: <DirectionsCarFilledIcon fontSize="small" />,
        },
      ],
    },
    {
      title: t("Amministrazione"),
      items: [
        {
          title: t("Clienti"),
          path: "/companies",
          icon: <Group fontSize="small" />,
          authorization: { resource: "companies", action: "allow" },
        },
        {
          title: "Configurazione clienti",
          path: "/customerconfigs",
          icon: <Group fontSize="small" />,
          authorization: { resource: "customerconfigs", action: "allow" },
        },
        {
          title: t("users"),
          path: "/users",
          icon: <Group fontSize="small" />,
          children: [
            {
              title: t("list"),
              path: "/users",
              authorization: { resource: "users", action: "allow" },
            },
            {
              title: t("roles"),
              path: "/roles",
              authorization: { resource: "roles", action: "allow" },
            },
          ],
        },
      ],
    },
  ];

  const { onMobileClose, openMobile, openSidebar } = props;
  const location = useLocation();
  // @ts-ignore
  const user: User = useSelector((state) => state.auth.user);

  const [authedSections, setAuthedSections] = useState([]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    const setAuthedSec = async () => {
      let authed = [];

      const checkChildren = async (children) => {
        let authed = [];
        if (!children) return null;
        for (const item of children) {
          if (item.authorization) {
            let { resource, action } = item.authorization;
            let can = await Can(action, resource);
            if (can) authed.push(item);
          } else {
            item.children = await checkChildren(item.children);
            authed.push(item);
          }
        }
        return authed;
      };

      const checkItems = async (items) => {
        let authed = [];
        for (const item of items) {
          if (item.authorization) {
            let { resource, action } = item.authorization;
            let can = await Can(action.toLowerCase(), resource.toLowerCase());
            if (can) authed.push(item);
          } else {
            if (!item.children) {
              authed.push(item);
              continue;
            }
            item.children = await checkChildren(item.children);
            if (item.children.length > 0) authed.push(item);
          }
        }
        return authed;
      };

      for (const section of sections) {
        // @ts-ignore
        if (section.authorization) {
          // @ts-ignore
          let { resource, action } = section.authorization;
          let can = await Can(action.toLowerCase(), resource.toLowerCase());

          if (can) authed.push(section);
          return;
        }

        section.items = await checkItems(section.items);
        if (section.items.length > 0) {
          authed.push(section);
        }
      }

      setAuthedSections(authed);
    };

    setAuthedSec();
  }, [user, i18n.language]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <RouterLink to="/account">
              <Avatar
                src={user?.avatar}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user?.firstName} {user?.lastName}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {user?.email}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {authedSections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
      <Box>
        <Divider />
        <Box
          sx={{
            p: 1,
            textAlign: "right",
          }}
        >
          <Typography variant="overline">v. 3.0.0</Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open={openSidebar}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

Sidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default Sidebar;
