import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Link,
    TextField,
    Typography
} from "@mui/material";
import { ErrorMessage, Formik } from "formik";
import { useSnackbar } from "notistack";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Link as RouterLink,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Helmet } from "react-helmet-async";
import GraphqlClient from "src/client/graphql.client";
import { Customerconfig } from "src/types/generated";
import useSettings from "../../hooks/useSettings";
import { updateCustomerconfig } from "./commands.customerconfig";

const UpdateCustomerconfig: FC = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [customerconfig, setCustomerconfig] = useState({} as Customerconfig);

  const [loadingData, setLoadingData] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    readAll();
  }, []);

  const readAll = async () => {
    const res = await GraphqlClient.companys({});
    let data = res.companys || [];
    setCustomers(data);
    setLoaded(true);
  };

  const idReceived = params.id;

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoadingData(true);
        const res = await GraphqlClient.customerconfig({ id: idReceived });
        console.log("RESSSSSSSSS", res);
        setCustomerconfig(res.customerconfig);
      } catch (e) {
        enqueueSnackbar("Errore", {
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          variant: "error",
        });
      } finally {
        setLoadingData(false);
      }
    };

    loadData();
  }, []);

  if (!loaded) {
    return <div>..</div>;
  }

  return (
    <>
      <Helmet>
        <title>t("customerconfigupdate_title") </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {t("customerconfig_update")}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/customerconfigs"
                  variant="subtitle2"
                >
                  Customerconfig
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  {t("customerconfig_update_breadcrumb")}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<ArrowLeftIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                  to="/customerconfigs"
                  variant="outlined"
                >
                  Indietro
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Formik
              initialValues={{
                form_customer: customers.filter(
                  (el) => el.description === customerconfig.customer
                )[0],
                form_provider: customerconfig.provider,
                form_name: customerconfig.name,
              }}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ): Promise<void> => {
                setSubmitting(true);
                let { form_customer, form_provider, form_name } = values;
                const res = await updateCustomerconfig(idReceived, {
                  customer: form_customer.description,
                  provider: form_provider,
                  name: form_name,
                });
                if (res.error && res.error == true) {
                  console.error(res.message);
                  setStatus({ success: false });
                  setSubmitting(false);
                  enqueueSnackbar(res.message, {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    variant: "error",
                  });
                  return;
                }

                setStatus({ success: true });
                setSubmitting(false);
                navigate("/customerconfigs");
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }): JSX.Element => (
                <form onSubmit={handleSubmit} {...props}>
                  <Box sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <Card>
                          <CardContent>
                            <CardHeader title="Form" />

                            <Box sx={{ mt: 2 }}>
                              <Autocomplete
                                onChange={(_, newValue) => {
                                  if (!newValue) {
                                    setFieldValue("form_customer", "");
                                    return;
                                  }
                                  setFieldValue("form_customer", newValue);
                                }}
                                value={values.form_customer}
                                options={customers}
                                getOptionLabel={(option) => option.description}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Cliente"
                                    variant="outlined"
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="form_customer"
                                component="div"
                              />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <Autocomplete
                                onChange={(_, newValue) => {
                                  if (!newValue) {
                                    setFieldValue("form_provider", "");
                                    return;
                                  }
                                  setFieldValue("form_provider", newValue);
                                }}
                                value={values.form_provider}
                                options={["DoYouSpain.com", "DiscoverCars.com"]}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Provider"
                                    variant="outlined"
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="form_provider"
                                component="div"
                              />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <TextField
                                required
                                error={Boolean(
                                  touched.form_name && errors.form_name
                                )}
                                fullWidth
                                helperText={
                                  touched.form_name && errors.form_name
                                }
                                label="Nome venditore cercato nella classifica"
                                id="form_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.form_name}
                                variant="outlined"
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        mt: 3,
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Aggiorna
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UpdateCustomerconfig;
