import store from "../../store";

import {actions} from './redux.providerConfig';
import GraphqlClient from "../../client/graphql.client";

import {ProviderConfigInputForm} from "src/types/generated";
import {ProviderConfigUpdateForm} from "src/types/generated";

export const readAll = async () => {
    try {
        const res = await GraphqlClient.providerConfigs({});
        let data = res.providerConfigs || [];
        store.dispatch(actions.readedAll(data));
        return {data: data}
    } catch (error) {
        store.dispatch(actions.readedAll([]));
        return {error: true, message: JSON.parse(JSON.stringify(error)).response.error}
    }
};

export const createProviderConfig = async (form?: ProviderConfigInputForm) => {
    try {
        const res = await GraphqlClient.createProviderConfig({form});
        store.dispatch(actions.created(res.createProviderConfig));
        return {data: res.createProviderConfig}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};


export const updateProviderConfig = async (id: string,form?: ProviderConfigUpdateForm) => {
    try {
        const res = await GraphqlClient.updateProviderConfig({id,form});
        store.dispatch(actions.created(res.updateProviderConfig));
        return {data: res.updateProviderConfig}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};


export const deleteProviderConfig = async (id: string) => {
    try {
        const res = await GraphqlClient.deleteProviderConfig({id});
        return {data: res.deleteProviderConfig}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};

export const duplicateProviderConfig = async (id: string) => {
    try {
        const res = await GraphqlClient.duplicateConfig({id});
        return {data: res.duplicateConfig}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
}