import { createSlice } from "@reduxjs/toolkit";
import { CrawlerJob } from "src/types/generated";

const slice = createSlice({
	name: "jobList",
	initialState: {
		data: [] as CrawlerJob[],
	},
	reducers: {
		readedAll: (state, action) => {
			state.data = action.payload;
		},
		created: (state, action) => {
			state.data.push(action.payload);
		},
	},
});

export const actions = slice.actions;
export default slice.reducer;
