import { combineReducers } from '@reduxjs/toolkit';
import authentication from '../pages/login/redux.login';
import storage from 'redux-persist/lib/storage'
import persistReducer from "redux-persist/es/persistReducer";
import users from '../pages/users/redux.user'
import roles from '../pages/roles/redux.role'
// YEOMAN ANCHOR IMPORT */
import customerconfig from "../pages/customerconfig/redux.customerconfig"
import company from "../pages/company/redux.company"
import crawlerResult from "../pages/crawlerResult/redux.crawlerResult"
import providerConfig from "../pages/providerConfig/redux.providerConfig"
import jobList from "../pages/jobList/redux.jobList"
import location from "../pages/locations/redux.location"
import tablePref from 'src/components/Table/redux.table';

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    blacklist: ['tokenRefreshed', 'refreshingToken']
}

// Aggiungere reducers qui
const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authentication),
    users,
    roles,
    // YEOMAN ANCHOR REDUCER */
    customerconfig,
    company,
    tablePref,
    crawlerResult,
    providerConfig,
    jobList,
    location,

});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['auth'],
    debug: true
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default persistedReducer;
