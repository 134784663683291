import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
    Autocomplete,
    Box, Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader, Container, Grid, Link,
    TextField,
    Typography
} from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import GraphqlClient from 'src/client/graphql.client';
import * as Yup from 'yup';
import useSettings from "../../hooks/useSettings";
import { createUser } from "./commands.user";


const NewUser: FC = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const {settings} = useSettings();
    const {enqueueSnackbar} = useSnackbar();

    const [company, setCompany] = useState(null);
    const [companies, setCompanies] = useState([]);

    const {t} = useTranslation();

    console.log('location', location)
    console.log('params', params);
    
    const readCompanies = async () => {
        const res = await GraphqlClient.companys({});
        let data = res.companys || [];
        setCompanies(data);
    }

    useEffect(() => {
        readCompanies();
    }, [])

    return (
        <>
            <Helmet>
                <title>Dashboard: {t("newUser")} </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {t("newUser")}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small"/>}
                                sx={{mt: 1}}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/users"
                                    variant="subtitle2"
                                >
                                    {t("usersT")}
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    {t("user_breadcrumb")}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{m: -1}}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<ArrowLeftIcon fontSize="small"/>}
                                    sx={{mt: 1}}
                                    to="/users"
                                    variant="outlined"
                                >
                                    {t("back")}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{mt: 3}}>
                        <Formik
                            initialValues={{
                                nome: '',
                                cognome: '',
                                email: '',
                                password: '',
                                confermaPassword: '',
                                submit: null
                            }}
                            validationSchema={
                                Yup
                                    .object()
                                    .shape({
                                        nome: Yup.string().max(30).required('Campo obbligatorio'),
                                        cognome: Yup.string().max(30).required('Campo obbligatorio'),
                                        email: Yup.string().max(30).required('Campo obbligatorio'),
                                        password: Yup.string().min(7, 'Lunghezza minima: 7 caratteri').max(20).required('Campo obbligatorio'),
                                        confermaPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Le password devono coincidere').max(30).required('Campo obbligatorio'),

                                    })
                            }
                            onSubmit={async (values, {
                                setErrors,
                                setStatus,
                                setSubmitting
                            }): Promise<void> => {
                                setSubmitting(true);
                                let {cognome, nome, email, password, confermaPassword} = values;
                                const res = await createUser({
                                    lastName: cognome,
                                    firstName: nome,
                                    email,
                                    password,
                                    confirmPassword: confermaPassword,
                                    company: company!.description,
                                });
                                if (res.error && res.error == true) {
                                    console.error(res.message);
                                    setStatus({success: false});
                                    setSubmitting(false);
                                    enqueueSnackbar(res.message, {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'error'
                                    });
                                    return;
                                }

                                setStatus({success: true});
                                setSubmitting(false);
                                enqueueSnackbar('Utente creato', {
                                    anchorOrigin: {
                                        horizontal: 'right',
                                        vertical: 'top'
                                    },
                                    variant: 'success'
                                });
                                navigate('/users');
                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                  touched,
                                  values
                              }): JSX.Element => (
                                <form
                                    onSubmit={handleSubmit}
                                    {...props}
                                >
                                    <Box sx={{p: 3}}>

                                        <Grid
                                            container
                                            spacing={3}
                                        >
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <Card>
                                                    <CardContent>
                                                        <CardHeader title={t("newUserTitle")}/>
                                                        <Box sx={{mt: 2}}>

                                                            <TextField
                                                                error={Boolean(touched.email && errors.email)}
                                                                fullWidth
                                                                helperText={touched.email && errors.email}
                                                                label="Email"
                                                                id="email"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.email}
                                                                variant="outlined"

                                                            />
                                                        </Box>
                                                        <Box sx={{mt: 2}}>
                                                            <TextField
                                                                type='password'
                                                                error={Boolean(touched.password && errors.password)}
                                                                fullWidth
                                                                helperText={touched.password && errors.password}
                                                                label="Password"
                                                                id="password"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.password}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                        <Box sx={{mt: 2}}>
                                                            <TextField
                                                                type='password'
                                                                error={Boolean(touched.confermaPassword && errors.confermaPassword)}
                                                                fullWidth
                                                                helperText={touched.confermaPassword && errors.confermaPassword}
                                                                label={t("confirmPassword")}
                                                                id="confermaPassword"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.confermaPassword}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <Card>
                                                    <CardContent>
                                                        <Box sx={{mt: 2}}>
                                                            <CardHeader title={"Anagrafica"}/>
                                                            <TextField
                                                                error={Boolean(touched.nome && errors.nome)}
                                                                fullWidth
                                                                helperText={touched.nome && errors.nome}
                                                                label={t("firstName")}
                                                                id="nome"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.nome}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                        <Box sx={{mt: 2}}>
                                                            <TextField
                                                                error={Boolean(touched.cognome && errors.cognome)}
                                                                fullWidth
                                                                helperText={touched.cognome && errors.cognome}
                                                                label={t("lastName")}
                                                                id="cognome"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.cognome}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                        <Box sx={{ mt: 2 }}>
                                                                    <Autocomplete
                                                                        value={company}
                                                                        onChange={(_, newValue) => {
                                                                            setCompany(newValue);
                                                                        }}
                                                                        isOptionEqualToValue={(option, current) => option === current}
                                                                        options={companies}
                                                                        getOptionLabel={(option) => option.description}
                                                                        renderInput={(params) => <TextField {...params}
                                                                            label="Provider"
                                                                            variant="outlined" />}
                                                                    />
                                                                </Box>

                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                mt: 3
                                            }}
                                        >
                                            <Button
                                                color="primary"
                                                disabled={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                            >
                                                {t("save")}
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default NewUser;
