import {
    Box,
    Card,
    CardContent,
    Container,
    Divider,
    Link,
    Typography
} from '@mui/material';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import { PasswordResetAmplify } from "../../../components/authentication/password-reset";

const platformIcons = {
    Amplify: '/static/icons/amplify.svg',
    Auth0: '/static/icons/auth0.svg',
    Firebase: '/static/icons/firebase.svg',
    JWT: '/static/icons/jwt.svg'
};

const PasswordReset: FC = () => {
    const {platform} = useAuth() as any;


    return (
        <>
            <Helmet>
                <title>Imposta password</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh'
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{py: 10}}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <RouterLink to="/">
                            <Logo
                                sx={{
                                    height: 40,
                                    width: 40
                                }}
                            />
                        </RouterLink>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 8
                        }}
                    />
                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 4
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mb: 3
                                }}
                            >
                                <div>
                                    <Typography
                                        color="textPrimary"
                                        gutterBottom
                                        variant="h4"
                                    >
                                        Password Reset
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                       Cambia la password del tuo account usando il codice di 6 cifre che ti abbiamo inviato tramite email
                                    </Typography>
                                </div>
                                <Box
                                    sx={{
                                        height: 32,
                                        '& > img': {
                                            maxHeight: '100%',
                                            width: 'auto'
                                        }
                                    }}
                                >
                                    <img
                                        alt="Auth platform"
                                        src={platformIcons[platform]}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    mt: 3
                                }}
                            >
                                <PasswordResetAmplify/>
                            </Box>
                            <Divider sx={{my: 3}}/>

                            <Link
                                color="textSecondary"
                                component={RouterLink}
                                to="/authentication/password-recovery"
                                variant="body2"
                            >
                               Non hai ricevuto il codice?
                            </Link>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default PasswordReset;
