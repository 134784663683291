import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
    Autocomplete, Box, Breadcrumbs, Button, capitalize, Card,
    CardContent,
    CardHeader, Container, Grid, Link, TextField, Typography
} from '@mui/material';
import { ErrorMessage, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useSettings from "../../hooks/useSettings";
import { createLocation } from "./commands.location";


const NewLocation: FC = (props) => {
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { enqueueSnackbar } = useSnackbar();

    let note = [
        {
            Provider: "DoYouSpain.com",
            Spiegazione: " Per scegliere la codifica della localita inserita come prima cosa collegarsi al sito web del provider scelto tramite browser Chrome, successivamente premere il tasto F12, cliccare su NetWork e selezionare la richiesta 'formulario.asp...' dall'elenco. Una volta aperta, cliccare su Headers e cercare il volore del dato identificato come 'fomulario_destino', una volta trovato copiarlo e inserirlo nel campo 'Transcodifica' nella pagina 'Nuova Localita'"
        },
        {
            Provider: "Tinoleggio.it",
            Spiegazione: " Per scegliere la codifica della localita inserita come prima cosa collegarsi al sito web del provider scelto tramite browser Chrome, successivamente effettuare una ricerca di prova nella localita desiderata. A questo troverete nell'url in alto una cosa di questo genere '..../Sicilia/Catania%20Aueroporto%20Fontanarossa%20(CTA)/...'. Da qui troverete la codifica da inserire che sarà 'Sicilia/Catania Aeroporto Fontanarossa (CTA)'"
        },
        {
            Provider: "Rent.it",
            Spiegazione: " Per scegliere la codifica della localita inserita come prima cosa collegarsi al sito web del provider scelto tramite browser Chrome, successivamente inserire nel form di ricerca la localita desiderata e usare come codifica una porzione del suggerimento generato dal sito, ad esempio se si vuole cercare 'Milano Stazione Centrale' la codifica sarà 'Milano - Sta' "
        },
        {
            Provider: "DiscoverCars.com",
            Spiegazione: " Per scegliere la codifica della localita inserita come prima cosa collegarsi al sito web del provider scelto tramite browser Chrome, successivamente inserire nel form di ricerca la localita desiderata e usare come codifica il suggerimento generato dal sito"
        },
        {
            Provider: "Rentalcars.com",
            Spiegazione: "Per scegliere la codifica della localita inserita come prima cosa collegarsi al sito web del provider scelto tramite browser Chrome, successivamente premere il tasto F12 ed eseguire una ricerca di prova nella localita desiderata, cliccare su NetWork e selezionare la richiesta 'search-results?searchCriteria=%...' dall'elenco. Una volta aperta, cliccare su Headers e all'interno del 'Request URL' troverete ad esempio una porzione del tipo '..pickUpLocation%22%3A%2223456%22...pickUpLocationType%22%3A%22AIRPORT%22..searchMetadata%22%3A%22Aeroporto%20di%20Catania%22%' per cui la codifica sarà del tipo '23456_AIRPORT_23456_AIRPORT_Aeroporto_di_Catania'"
        },
        {
            Provider: "Vipcars.com",
            Spiegazione: " Per scegliere la codifica della localita inserita come prima cosa collegarsi al sito web del provider scelto tramite browser Chrome, successivamente effettuare una ricerca di prova nella localita desiderata. A questo troverete nell'url in alto una cosa di questo genere '...pickup_country=5522&pickup_city=228775&pickup_location=425...'. La codifica da salvare sara formata dai codici numerici trovati separati da uno _ . Seguendo l'esempio la codifica sara la seguente '5522_228775_425'"
        }
    ]

    return (
        <>
            <Helmet>
                <title>Nuova Localita </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Nuova Localita
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/location"
                                    variant="subtitle2"
                                >
                                    Elenco Localita
                                </Link>

                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Nuova Localita
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<ArrowLeftIcon fontSize="small" />}
                                    sx={{ mt: 1 }}
                                    to="/location"
                                    variant="outlined"
                                >
                                    Torna all'elenco
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 3 }}>
                        <Formik
                            initialValues={{
                                form_provider: '',
                                form_comune: '',
                                form_transCodifica: '',
                            }}

                            onSubmit={async (values, {
                                setErrors,
                                setStatus,
                                setSubmitting
                            }): Promise<void> => {
                                setSubmitting(true);
                                let {
                                    form_provider,
                                    form_comune,
                                    form_transCodifica,
                                } = values;
                                if (values.form_provider === '' || values.form_comune === '' || values.form_transCodifica === '') {
                                    enqueueSnackbar("I campi non possono essere vuoti", {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'error'
                                    });
                                    return;
                                }
                                const res = await createLocation(
                                    {
                                        provider: form_provider,
                                        comune: form_comune,
                                        transCodifica: form_transCodifica,
                                    }
                                );
                                if (res.error) {
                                    console.error(res);
                                    setStatus({ success: false });
                                    setSubmitting(false);
                                    enqueueSnackbar(res.message, {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'error'
                                    });
                                    return;
                                }

                                setStatus({ success: true });
                                setSubmitting(false);
                                navigate('/location');
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values
                            }): JSX.Element => (
                                <form onKeyDown={onKeyDown}                                    onSubmit={handleSubmit}
                                    {...props}
                                >
                                    <Box sx={{ p: 3 }}>

                                        <Grid
                                            container
                                            spacing={3}
                                        >
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                <Card>
                                                    <CardContent>
                                                        <CardHeader title="Form" />
                                                        <Box sx={{ mt: 2 }}>
                                                            <Autocomplete
                                                                onChange={(_, newValue) => {
                                                                    if (!newValue) {
                                                                        setFieldValue('form_provider', "")
                                                                        return
                                                                    }
                                                                    setFieldValue('form_provider', newValue)

                                                                }}
                                                                options={["DoYouSpain.com",  "DiscoverCars.com"]}
                                                                getOptionLabel={(option) => option}
                                                                renderInput={(params) => <TextField {...params}
                                                                    label="Provider"
                                                                    variant="outlined" />}
                                                            />
                                                            <ErrorMessage name="form_provider" component="div" />
                                                        </Box>


                                                        <Box sx={{ mt: 2 }}>
                                                            <TextField
                                                                label="Localita"
                                                                variant="outlined"
                                                                value={values.form_comune}
                                                                onChange={(e) => setFieldValue("form_comune", capitalize(e.target.value))}
                                                                fullWidth

                                                            />

                                                            <ErrorMessage name="form_comune" component="div" />
                                                        </Box>
                                                        <Card
                                                            sx={{ m: 2 }}
                                                            variant='outlined'
                                                        >
                                                            <CardHeader
                                                                subheader="Nota"
                                                                style={{
                                                                    paddingBottom: '0px'
                                                                }}
                                                            />
                                                            <Box
                                                                sx={{ m: 2 }}
                                                            >
                                                                <Typography
                                                                    variant='body2'
                                                                >
                                                                    {values.form_provider === note[0].Provider ? note[0].Spiegazione :
                                                                        values.form_provider === note[1].Provider ? note[1].Spiegazione :
                                                                            values.form_provider === note[2].Provider ? note[2].Spiegazione :
                                                                                values.form_provider === note[3].Provider ? note[3].Spiegazione :
                                                                                    values.form_provider === note[4].Provider ? note[4].Spiegazione :
                                                                                        values.form_provider === note[5].Provider ? note[5].Spiegazione :
                                                                                            " Devi scegliere prima un provider"
                                                                    }

                                                                </Typography>
                                                            </Box>



                                                        </Card>

                                                        <Box sx={{ mt: 2 }}>
                                                            <TextField
                                                                label="Transcodifica"
                                                                variant="outlined"
                                                                value={values.form_transCodifica}
                                                                onChange={(e) => setFieldValue("form_transCodifica", e.target.value)}
                                                                fullWidth
                                                            />
                                                            <ErrorMessage name="form_transCodifica" component="div" />
                                                        </Box>

                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                mt: 3
                                            }}
                                        >
                                            <Button
                                                color="primary"
                                                disabled={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                            >
                                                Salva
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
    }
}

export default NewLocation;
