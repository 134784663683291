import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
    Autocomplete, Box, Breadcrumbs, Button, capitalize, Card,
    CardContent,
    CardHeader, Container, Grid, LinearProgress, Link,
    TextField, Typography
} from '@mui/material';
import { ErrorMessage, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import GraphqlClient from 'src/client/graphql.client';
import { Location } from 'src/types/generated';
import useSettings from "../../hooks/useSettings";
import { updateLocation } from './commands.location';


const UpdateLocation: FC = (props) => {
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const params = useParams();
    const idReceived = params.id;

    const [loadingData, setLoadingData] = useState(false);
    const [location, setLocation] = useState({} as Location);

    const loadData = async () => {
        try {
            setLoadingData(true)
            const res = await GraphqlClient.getLocation({ id: idReceived });
            setLocation(res.getLocation);
        } catch (e) {
            enqueueSnackbar("Errore caricamento location", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } finally {
            setLoadingData(false);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <Helmet>
                <title>Aggiorna Localita </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Aggiorna Localita
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/location"
                                    variant="subtitle2"
                                >
                                    Localita
                                </Link>

                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Aggiorna Localita
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<ArrowLeftIcon fontSize="small" />}
                                    sx={{ mt: 1 }}
                                    to="/location"
                                    variant="outlined"
                                >
                                    Torna a Localita
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 3 }}>
                        {
                            loadingData ? <LinearProgress /> :
                                <Formik
                                    initialValues={{
                                        form_provider: location.provider, //ok
                                        form_comune: location.comune, //ok
                                        form_transCodifica: location.transCodifica,

                                    }}

                                    onSubmit={async (values, {
                                        setErrors,
                                        setStatus,
                                        setSubmitting
                                    }): Promise<void> => {
                                        setSubmitting(true);
                                        let {
                                            form_provider,
                                            form_comune,
                                            form_transCodifica,

                                        } = values;
                                        const res = await updateLocation(
                                            idReceived,
                                            {
                                                provider: form_provider,
                                                comune: form_comune,
                                                transCodifica: form_transCodifica,

                                            }
                                        );
                                        if (res.error) {
                                            console.error(res);
                                            setStatus({ success: false });
                                            setSubmitting(false);
                                            enqueueSnackbar(res.message, {
                                                anchorOrigin: {
                                                    horizontal: 'right',
                                                    vertical: 'top'
                                                },
                                                variant: 'error'
                                            });
                                            return;
                                        }

                                        setStatus({ success: true });
                                        setSubmitting(false);
                                        navigate('/location');
                                    }}
                                >
                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                        touched,
                                        values
                                    }): JSX.Element => (
                                        <form
                                            onSubmit={handleSubmit}
                                            {...props}
                                        >
                                            <Box sx={{ p: 3 }}>

                                                <Grid
                                                    container
                                                    spacing={3}
                                                >
                                                    <Grid
                                                        item
                                                        md={12}
                                                        xs={12}
                                                    >
                                                        <Card>
                                                            <CardContent>
                                                                <CardHeader title="Form" />
                                                                <Box sx={{ mt: 2 }}>
                                                                    <Autocomplete
                                                                        value={values.form_provider}
                                                                        onChange={(_, newValue) => {
                                                                            if (!newValue) {
                                                                                setFieldValue('form_provider', "")
                                                                                return
                                                                            }
                                                                            setFieldValue('form_provider', newValue)
                                                                        }}
                                                                        isOptionEqualToValue={(option, current) => option === current}
                                                                        options={["DoYouSpain.com",  "DiscoverCars.com"]}
                                                                        getOptionLabel={(option) => option}
                                                                        renderInput={(params) => <TextField {...params}
                                                                            label="Provider"
                                                                            variant="outlined" />}
                                                                    />
                                                                    <ErrorMessage name="form_provider" component="div" />
                                                                </Box>


                                                                <Box sx={{ mt: 2 }}>
                                                                    <TextField
                                                                        label="Comune"
                                                                        variant="outlined"
                                                                        value={values.form_comune}
                                                                        onChange={(e) => setFieldValue("form_comune", capitalize(e.target.value))}
                                                                        fullWidth
                                                                    />

                                                                    <ErrorMessage name="form_comune" component="div" />
                                                                </Box>
                                                                <Box sx={{ m: 2 }}>
                                                                    <Typography >
                                                                        Nota:
                                                                        {values.form_provider === 'DoYouSpain.com' ? " Per scegliere la codifica esatta della localita inserita come prima cosa collegarsi al sito web tramite browser Chrome, successivamente premere il tasto F12, cliccare su NetWork e selezionare la richiesta 'formulario.asp...'. Una volta aperta, cliccare su Headers e cercare il volore del dato identificato come 'fomulario_destino'" :
                                                                            values.form_provider === 'Tinoleggio.it' ? " hai selezionato Tinoleggio.it" :
                                                                                values.form_provider === 'Rent.it' ? " hai selezionato Rent.it" :
                                                                                    values.form_provider === 'DiscoverCars.com' ? " hai selezionato DiscoverCars.com" :
                                                                                        " devi scegliere prima un provider"
                                                                        }

                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ mt: 2 }}>
                                                                    <TextField
                                                                        label="Transcodifica"
                                                                        variant="outlined"
                                                                        value={values.form_transCodifica}
                                                                        onChange={(e) => setFieldValue("form_transCodifica", e.target.value)}
                                                                        fullWidth
                                                                    />
                                                                    <ErrorMessage name="form_transCodifica" component="div" />
                                                                </Box>


                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        mt: 3
                                                    }}
                                                >
                                                    <Button
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        Aggiorna
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                        }

                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default UpdateLocation;
