import store from "../../store";
import {actions} from './redux.location'
import GraphqlClient from "../../client/graphql.client";
import { NewLocation, UpdateLocation } from "src/types/generated";

export const readAll = async () => {
    try {
        const res = await GraphqlClient.getLocations({});
        let data = res.getLocations ;
        store.dispatch(actions.readedAll(data));
        return {data: data}
    } catch (error) {
        store.dispatch(actions.readedAll([]));
        return {error: true, message: JSON.parse(JSON.stringify(error)).response.error}
    }
};

export const createLocation = async (form?: NewLocation) => {
    try {
        const res = await GraphqlClient.createLocation({form});
        store.dispatch(actions.created(res.createLocation));
        return {data: res.createLocation}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};

export const updateLocation = async (id: string,form?: UpdateLocation) => {
    try {
        const res = await GraphqlClient.updateLocation({id,form});
        store.dispatch(actions.created(res.updateLocation));
        return {data: res.updateLocation}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};


export const deleteLocation = async (id: string) => {
    try {
        const res = await GraphqlClient.deleteLocation({id});
        return {data: res.deleteLocation}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};