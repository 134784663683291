import store from "../../store";

import {actions} from './redux.crawlerResult';
import GraphqlClient from "../../client/graphql.client";

import {CrawlerResultUpdateForm} from "src/types/generated";

export const deleteCrawlerResult = async (id: string) => {
    try {
        const res = await GraphqlClient.deleteCrawlerResult({id});
        return {data: res.deleteCrawlerResult}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};
