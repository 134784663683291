import { useSnackbar } from "notistack";
import React, {
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Link as RouterLink } from "react-router-dom";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CommentIcon from "@mui/icons-material/Comment";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
	Box,
	Breadcrumbs,
	Button,
	Card,
	Chip,
	Container,
	Divider,
	Grid,
	IconButton,
	Link,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import GraphqlClient from "src/client/graphql.client";
import { KliTable } from "src/components/KliTable";
import {
	SelectColumnFilter,
	SelectColumnFilterMethod,
} from "src/components/Table/Table";
import Label from "src/material/Label";
import useSettings from "../../hooks/useSettings";
import Scrollbar from "../../material/Scrollbar";
import { Can } from "../../pages/login/authorizer";
import { useSelector } from "../../store";
import { DateFilter } from "../../types/generated";
import { readAll } from "./commands.JobList";

const PageJobList: FC = (props) => {
	const data = useSelector((state) => state.jobList.data);
	const user = useSelector((state) => (state as any).auth);
	const { t } = useTranslation();

	const [filteredRows, setFilteredRows] = useState([]);

	const { settings } = useSettings();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [hiddenColumns, setHiddenColumns] = useState(["companies"]);

	const [infoRow, setInfoRow] = useState(null);

	const { ...other } = props;

	const [openAlert, setOpenAlert] = React.useState(false);
	let reactTable = useRef(null);

	const handleClickOpenAlert = () => {
		setOpenAlert(true);
	};

	const handleCloseAlert = () => {
		setOpenAlert(false);
	};

	let today = new Date();
	const [dateFilter, setDateFilter] = useState(
		moment(today).format("YYYY-MM-DD")
	);
	let filterResults: DateFilter = { data: dateFilter, status: true };

	useEffect(() => {
		readAll(filterResults);
	}, [dateFilter]);

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const runRulesAgain = async () => {
		let idsToRunAgain = filteredRows.map((item) => item.original.id);
		try {
			await GraphqlClient.runJobs({ ids: idsToRunAgain });
		} catch (e) {
			alert("C'è stato un errore");
			console.error(e);
		}
	};

	const runJob = async (id) => {
		try {
			const res = await GraphqlClient.runJob({ id: id });
			readAll(filterResults);
		} catch (e) {
			alert("Impossibile eseguire ricerca");
			console.log(e);
		}
	};

	const changeRows = useCallback((rows) => {
		setFilteredRows(rows);
	}, []);

	useEffect(() => {
		const checkRole = async () => {
			let can = await Can("allow", "users");
			if (can) {
				setHiddenColumns([]);
			}
		};

		checkRole();
	}, [user]);

	const memoChild = useMemo(
		() => (
			<KliTable
				SetFilteredRows={(rows) => changeRows(rows)}
				Data={data || []}
				InitialState={{
					hiddenColumns: hiddenColumns,
				}}
				Columns={[
					{
						Header: "Id",
						accessor: "id",
						id: "id",
						width: 350,
					},
					{
						Header: "Config ID",
						accessor: "providerConfigID",
						id: "providerConfigID",
						Filter: SelectColumnFilter,
						filter: SelectColumnFilterMethod,
						width: 350,
					},
					{
						Header: "Provider",
						accessor: "provider",
						id: "provider",
						Filter: SelectColumnFilter,
						filter: SelectColumnFilterMethod,
					},
					{
						Header: "Stato esecuzione",
						accessor: "status",
						Cell: (row) => {
							switch (row.row.original.status) {
								case "PIANIFICATO": {
									return (
										<Label style={{ margin: "1px" }} color="warning">
											{row.row.original.status}
										</Label>
									);
								}
								case "DA ESEGUIRE": {
									return (
										<Label style={{ margin: "1px" }} color="warning">
											{row.row.original.status}
										</Label>
									);
								}
								case "ESEGUITO": {
									return (
										<Label style={{ margin: "1px" }} color="success">
											{row.row.original.status}
										</Label>
									);
								}
								case "IN ESECUZIONE": {
									return (
										<Label style={{ margin: "1px" }} color="primary">
											{row.row.original.status}
										</Label>
									);
								}
								case "ERRORE": {
									return (
										<Label style={{ margin: "1px" }} color="error">
											{row.row.original.status}
										</Label>
									);
								}
							}
						},
						id: "status",
						Filter: SelectColumnFilter,
						filter: SelectColumnFilterMethod,
					},

					{
						Header: "localita",
						accessor: "localita",
						id: "localita",
						Filter: SelectColumnFilter,
						filter: SelectColumnFilterMethod,
					},
					{
						Header: "codificaLocalita",
						accessor: "codificaLocalita",
						id: "codificaLocalita",
						Filter: SelectColumnFilter,
						filter: SelectColumnFilterMethod,
					},
					{
						Header: "Ricerca dal",
						accessor: "from",
						Cell: (row) => {
							return moment(row.row.original.from, "DD/MM/YYYY").format(
								"DD MMM YYYY"
							);
						},
					},
					{
						Header: "Ricerca al",
						accessor: "to",
						Cell: (row) => {
							return moment(row.row.original.to, "DD/MM/YYYY").format(
								"DD MMM YYYY"
							);
						},
					},

					{
						Header: "Programmato alle",
						accessor: "scheduledAt",
						Cell: (row) => {
							if (!row.value) return "-";
							return moment(row.row.original?.scheduledAt).format(
								"DD MMM YYYY HH:mm:ss"
							);
						},
					},
					{
						Header: "Iniziato alle",
						accessor: "startedAt",
						Cell: (row) => {
							if (!row.value) return "-";
							return moment(row.row.original?.startedAt).format(
								"DD MMM YYYY HH:mm:ss"
							);
						},
					},
					{
						Header: "Finito alle",
						accessor: "finishedAt",
						Cell: (row) => {
							if (!row.value) return "-";
							return moment(row.row.original?.finishedAt).format(
								"DD MMM YYYY HH:mm:ss"
							);
						},
					},
					{
						Header: "Risultati",
						accessor: "resultRows",
					},
					{
						Header: "Usa Tor",
						accessor: "useTor",
					},
					{
						Header: "Max ( tentativi )",
						accessor: "maxAttempts",
					},
					{
						Header: "Tentativi effettuati",
						accessor: "attempts",
					},
					{
						Header: "Clienti",
						accessor: "companies",
						id: "companies",
						Cell: (row) => {
							return (
								<div>
									{row.row.original.companies?.map((elem) => {
										return <Chip label={elem} style={{ margin: "1px" }} />;
									})}
								</div>
							);
						},
						/*  Filter: SelectColumnFilter,
                                      filter: SelectColumnFilterMethod, */
						width: 350,
					},
					{
						Header: "Azioni",
						Cell: (row) => (
							<>
								<Tooltip title={"Maggiori Info"}>
									<IconButton
										aria-label="Maggiori Info"
										onClick={() => {
											handleClickOpen();
											setInfoRow(row.row.original);
										}}
									>
										<CommentIcon fontSize="small" />
									</IconButton>
								</Tooltip>
								<Tooltip title={"Esegui ricerca ora"}>
									<IconButton
										aria-label="Esegui ricerca ora"
										onClick={() => {
											runJob(row.row.original.id);
										}}
									>
										<PlayCircleOutlineIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</>
						),
					},
				]}
			/>
		),
		[data]
	);

	// @ts-ignore
	return (
		<>
			<Helmet>
				<title>Ricerche programmate </title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: "background.default",
					py: 8,
				}}
			>
				<Container maxWidth={settings.compact ? "xl" : false}>
					<Grid container justifyContent="space-between" spacing={3}>
						<Grid item>
							<Typography color="textPrimary" variant="h5">
								Elenco Ricerche
							</Typography>
							<Breadcrumbs
								aria-label="breadcrumb"
								separator={<ChevronRightIcon fontSize="small" />}
								sx={{ mt: 1 }}
							>
								<Link
									color="textPrimary"
									component={RouterLink}
									to="/"
									variant="subtitle2"
								>
									Dashboard
								</Link>
								<Typography color="textSecondary" variant="subtitle2">
									Elenco Ricerche
								</Typography>
							</Breadcrumbs>
						</Grid>
						<Grid display="flex" flexDirection="row" item>
							<Box
								sx={{
									m: -1,
									mr: 2,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<TextField
									type="date"
									label="Ricerche per il :"
									defaultValue={dateFilter}
									value={dateFilter}
									onChange={(e) => {
										setDateFilter(e.target.value);
										readAll(filterResults);
									}}
								/>
								<Tooltip title={"Esegui ricerche"}>
									<Button
										variant="contained"
										color="primary"
										onClick={handleClickOpenAlert}
									>
										Esegui ricerche
									</Button>
								</Tooltip>
								<Tooltip title={"Refresh"}>
									<IconButton onClick={() => readAll(filterResults)}>
										<RefreshIcon fontSize="large" />
									</IconButton>
								</Tooltip>
							</Box>
						</Grid>
					</Grid>

					<div>
						{" "}
						<Dialog
							open={openAlert}
							onClose={handleCloseAlert}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">
								{"Richiesta conferma"}
							</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									Stai per eseguire nuovamente {filteredRows.length} ricerche,
									l'azione è irreversibile.
									<br />
									Sei sicuro di voler procedere?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleCloseAlert}>Annulla</Button>
								<Button
									onClick={async () => {
										await runRulesAgain();
										handleCloseAlert();
									}}
									autoFocus
								>
									Procedi
								</Button>
							</DialogActions>
						</Dialog>
					</div>

					<div>
						<Dialog
							open={open}
							onClose={handleClose}
							aria-labelledby="form-dialog-title"
							fullWidth={true}
							maxWidth={"md"}
						>
							<DialogTitle id="">Job {infoRow?.id}</DialogTitle>
							<DialogContent>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Id
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									variant="body2"
									component="p"
								>
									{infoRow?.id}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									ProviderConfigID
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									variant="body2"
									component="p"
								>
									{infoRow?.providerConfigID}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Provider
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									variant="body2"
									component="p"
								>
									{infoRow?.provider}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Localita
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									variant="body2"
									component="p"
								>
									{infoRow?.localita}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Inizio periodo
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									variant="body2"
									component="p"
								>
									{moment(infoRow?.from, "DD-MM-YYYY").format("DD MMM YYYY")}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Fine periodo
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									variant="body2"
									component="p"
								>
									{moment(infoRow?.to, "DD-MM-YYYY").format("DD MMM YYYY")}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Categorie esaminate
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									variant="body2"
									component="p"
								>
									{infoRow?.categoria.map((elem) => {
										return (
											<Chip
												size="small"
												label={elem.toUpperCase()}
												style={{ margin: "2px" }}
											/>
										);
									})}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Stato job
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									variant="body2"
									component="p"
								>
									{infoRow?.status === "DA ESEGUIRE" ? (
										<Chip
											size="small"
											label={infoRow?.status}
											style={{ margin: "1px" }}
											color="warning"
										/>
									) : infoRow?.status === "IN CORSO" ? (
										<Chip
											size="small"
											label={infoRow?.status}
											style={{ margin: "1px" }}
											color="primary"
										/>
									) : (
										<Chip
											size="small"
											label={infoRow?.status}
											style={{ margin: "1px" }}
											color="success"
										/>
									)}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Inizio ricerca
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									variant="body2"
									component="p"
								>
									{moment(new Date(infoRow?.startedAt)).format(
										"DD MMM YYYY | HH:mm:ss"
									)}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Fine ricerca
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									variant="body2"
									component="p"
								>
									{moment(new Date(infoRow?.finishedAt)).format(
										"DD MMM YYYY | HH:mm:ss"
									)}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									PubSub
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									variant="body2"
									component="p"
								>
									{infoRow?.pubsubbed ? (
										<Chip
											size="small"
											label="ESEGUITO"
											style={{ margin: "1px", marginRight: "5px" }}
											color="success"
										/>
									) : (
										<Chip
											size="small"
											label="NON ESEGUITO"
											style={{ margin: "1px", marginRight: "5px" }}
											color="warning"
										/>
									)}
									il{" "}
									{moment(new Date(infoRow?.pubsubbedAt)).format(
										"DD MMM YYYY | HH:mm:ss"
									)}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Durata ricerca
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="body2"
									component="p"
								>
									{infoRow?.jobDurationSeconds} s
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Risultati ottenuti
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="body2"
									component="p"
								>
									{infoRow?.resultRows}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Step eseguiti
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="body2"
									component="p"
								>
									{infoRow?.step}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Compagnie
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="body2"
									component="p"
								>
									{infoRow?.companies.map((elem) => {
										if (elem === "") {
											return "Nessuna";
										} else {
											return (
												<Chip
													size="small"
													label={elem.toUpperCase()}
													style={{ margin: "2px" }}
												/>
											);
										}
									})}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Tentativi eseguiti
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="body2"
									component="p"
								>
									{infoRow?.attempts}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Tentativi massimi
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="body2"
									component="p"
								>
									{infoRow?.maxAttempts}
								</Typography>
								<Divider />
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="subtitle1"
									component="h2"
								>
									Utilizzo Tor
								</Typography>
								<Typography
									style={{ display: "inline-block", width: "50%" }}
									gutterBottom
									variant="body2"
									component="p"
								>
									{infoRow?.useTor ? (
										<Chip
											size="small"
											label="SI"
											style={{ margin: "1px", marginRight: "5px" }}
											color="success"
										/>
									) : (
										<Chip
											size="small"
											label="NO"
											style={{ margin: "1px", marginRight: "5px" }}
											color="warning"
										/>
									)}
								</Typography>
								<Divider />
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClose} color="primary">
									Chiudi
								</Button>
							</DialogActions>
						</Dialog>
					</div>

					<Box sx={{ mt: 3 }}>
						<Card {...other}>
							<Scrollbar>
								<Box sx={{ minWidth: 1200 }}>{memoChild}</Box>
							</Scrollbar>
						</Card>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default PageJobList;
