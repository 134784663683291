import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { useSnackbar } from "notistack";
import type { ChangeEvent, FC } from "react";
import { Fragment, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import Add from "@mui/icons-material/Add";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Edit from "@mui/icons-material/Edit";
import {
    Avatar,
    Box,
    Breadcrumbs, Button, Card,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    Link,
    Tooltip, Typography
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Table } from "src/components/Table/Table";
import PermissionGuard from "../../components/PermissionGuard/PermissionGuard";
import useSettings from "../../hooks/useSettings";
import { useSelector } from "../../store";
import { impersonificate } from "../login/commands.login";
import { readAll } from "./commands.user";
const wait = (time: number): Promise<void> =>
  new Promise((res) => setTimeout(res, time));

const PageUser: FC = (props) => {
  const users = useSelector((state) => state.users.data);
  const user = useSelector((state) => (state as any).auth);
  const { t } = useTranslation();
  const { settings } = useSettings();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { ...other } = props;
  const [query, setQuery] = useState<string>("");

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    readAll();
  }, []);

  const impersonifica = async (utente) => {
    const key = enqueueSnackbar(`Impersonificazione di ${utente} in corso..`, {
      anchorOrigin: {
        horizontal: "right",
        vertical: "bottom",
      },
      variant: "info",
      persist: true,
      action: (key) => (
        <Fragment>
          <CircularProgress style={{ color: "white" }} size={24} />
        </Fragment>
      ),
    });

    try {
      await impersonificate(utente);
      closeSnackbar(key);
    } catch (e) {
      closeSnackbar(key);
      enqueueSnackbar(`Impossibile procedere con l'impersonificazione`, {
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
        variant: "error",
      });
    }
  };

  // Usually query is done on backend with indexing solutions
  // @ts-ignore
  return (
    <>
      <Helmet>
        <title>Dashboard: t("usersT") </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {t("usersT")}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  users
                </Typography>
              </Breadcrumbs>
            </Grid>
            <PermissionGuard action={"allow"} resource={"users"}>
              <Grid item>
                <Box sx={{ m: -1 }}>
                  <RouterLink
                    to={{
                      pathname: "/users/new",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      color="primary"
                      startIcon={<Add fontSize="small" />}
                      sx={{ m: 1 }}
                      variant="contained"
                    >
                      Crea nuovo utente
                    </Button>
                  </RouterLink>
                </Box>
              </Grid>
            </PermissionGuard>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card {...other}>
              <Table
                idDefaultColumnSort="email"
                Data={users}
                Columns={[
                  {
                    Header: "",
                    width: 20,
                    accessor: "avatar",
                    disableFilters: true,
                    resizable: false,
                    Cell: (row) => (
                      <Avatar
                        src={row.value}
                        sx={{
                          height: 45,
                          width: 45,
                        }}
                      />
                    ),
                  },
                  {
                    Header: "Email",
                    accessor: "email",
                  },
                  {
                    Header: "Nome",
                    accessor: "firstName",
                  },
                  {
                    Header: "Cognome",
                    accessor: "lastName",
                  },
                  {
                    Header: "Azienda",
                    accessor: "company",
                  },
                  {
                    Header: t("actions"),
                    accessor: "azioni",
                    Cell: (row) => (
                      <>
                        <PermissionGuard
                          action={"allow"}
                          resource={"updateUser"}
                        >
                          <IconButton
                            component={RouterLink}
                            to={`/users/modifica/${row.row.values.email}`}
                          >
                            <Edit fontSize="small" />
                          </IconButton>
                          {!user?.impersonificated &&
                            row.row.values.email !== user?.user.email && (
                              <PermissionGuard
                                action={"allow"}
                                resource={"impersonates"}
                              >
                                <Tooltip title={"Impersonifica utente"}>
                                  <IconButton
                                    onClick={() =>
                                      impersonifica(row.row.values.email)
                                    }
                                  >
                                    <SupervisedUserCircleIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </PermissionGuard>
                            )}
                        </PermissionGuard>
                        <IconButton>
                          <ArrowRightIcon fontSize="small" />
                        </IconButton>
                      </>
                    ),
                  },
                ]}
              />
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PageUser;
