import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Breadcrumbs, Button, Card, CardContent,
  CardHeader,
  Checkbox,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from "@mui/material";
import { ErrorMessage, Formik } from "formik";
import { useSnackbar } from "notistack";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Helmet } from "react-helmet-async";
import GraphqlClient from "src/client/graphql.client";
import { ProviderConfig } from "src/types/generated";
import useSettings from "../../hooks/useSettings";
import { updateProviderConfig } from "./commands.providerConfig";

import { LinearProgress } from "@mui/material";
import { useSelector } from "src/store";
import { readAll } from "../locations/commands.location";

const UpdateProviderConfig: FC = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => (state as any).auth.user);
  const [providerConfig, setProviderConfig] = useState({} as ProviderConfig);

  const [loadingData, setLoadingData] = useState(false);
  const idReceived = params.id;
  const locationsList = useSelector((state) => state.location.data);
  const [previewed, setPreviewed] = useState(false);
  const [requestCount, setRequestCount] = useState(0);

  const [locationsStruct, setLocationsStruct] = useState({
    doyouspain: [],
    tinoleggio: [],
    rent: [],
    discovercars: [],
  });
  const calculateRequests = async (values) => {
    let {
      form_provider,
      form_localita,
      form_categories,
      form_steps,
      form_weekDaysStart,
      form_monthDaysStart,
      form_monthsDuration,
      form_scheduletAt,
      form_monthToCheck,
    } = values;

    try {
      const res = await GraphqlClient.calculateJobNumber({
        form: {
          provider: form_provider,
          localita: form_localita,
          categories: form_categories,
          steps: form_steps,
          weekDaysStart: form_weekDaysStart,
          monthDaysStart: form_monthDaysStart,
          monthsDuration: form_monthsDuration,
          scheduledAt: form_scheduletAt,
          monthToCheck: form_monthToCheck,
          company: user.company,
          useTor: true,
          maxAttempts: 10,
        },
      });

      setRequestCount(res.calculateJobNumber);
      setPreviewed(true);
    } catch (e) {
      alert("Impossibile calcolare il numero delle richieste");
    }
  };

  const setLocationsForProviders = () => {
    let loc = {
      doyouspain: [],
      tinoleggio: [],
      rent: [],
      discovercars: [],
    };
    locationsList?.map((elem) => {
      switch (elem.provider) {
        case "DoYouSpain.com": {
          loc.doyouspain.push(elem.comune);
          break;
        }

        case "DiscoverCars.com": {
          loc.discovercars.push(elem.comune);
          break;
        }
        default:
          return;
      }
    });
    setLocationsStruct({
      doyouspain: loc.doyouspain,
      rent: loc.rent,
      discovercars: loc.discovercars,
      tinoleggio: loc.tinoleggio,
    });
  };

  const loadData = async () => {
    try {
      setLoadingData(true);
      const res = await GraphqlClient.providerConfig({ id: idReceived });
      setProviderConfig(res.providerConfig);
    } catch (e) {
      enqueueSnackbar("Errore caricamento provider", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    } finally {
      setLoadingData(false);
      setLocationsForProviders();
    }
  };

  useEffect(() => {
    readAll();
    loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("providerConfig_update_title")} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {t("providerConfig_update")}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/providerConfigs"
                  variant="subtitle2"
                >
                  Configurazione Provider
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  {t("providerConfig_update_breadcrumb")}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<ArrowLeftIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                  to="/providerConfigs"
                  variant="outlined"
                >
                  Indietro
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            {loadingData ? (
              <LinearProgress />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  form_provider: providerConfig.provider,
                  form_localita: providerConfig.localita,
                  form_steps: providerConfig.steps,
                  form_categories: providerConfig.categories,
                  form_weekDaysStart: providerConfig.weekDaysStart,
                  form_monthDaysStart: providerConfig.monthDaysStart,
                  form_monthsDuration: providerConfig.monthsDuration,
                  form_scheduletAt: providerConfig.scheduledAt,
                  form_useTor: providerConfig.useTor,
                  form_maxAttempts: providerConfig.maxAttempts,
                  form_monthToCheck: providerConfig.monthToCheck,
                }}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ): Promise<void> => {
                  setSubmitting(true);
                  let {
                    form_provider,
                    form_localita,
                    form_categories,
                    form_steps,
                    form_weekDaysStart,
                    form_monthDaysStart,
                    form_monthsDuration,
                    form_scheduletAt,
                    form_useTor,
                    form_maxAttempts,
                    form_monthToCheck,
                  } = values;

                  const res = await updateProviderConfig(idReceived, {
                    provider: form_provider,
                    localita: form_localita,
                    categories: form_categories,
                    steps: form_steps,
                    weekDaysStart: form_weekDaysStart,
                    monthDaysStart: form_monthDaysStart,
                    monthsDuration: form_monthsDuration,
                    scheduledAt: form_scheduletAt,
                    company: user.company,
                    useTor: form_useTor,
                    maxAttempts: form_maxAttempts,
                    monthToCheck: form_monthToCheck,
                  });
                  if (res.error && res.error == true) {
                    console.error(res.message);
                    setStatus({ success: false });
                    setSubmitting(false);
                    enqueueSnackbar(res.message, {
                      anchorOrigin: {
                        horizontal: "right",
                        vertical: "top",
                      },
                      variant: "error",
                    });
                    return;
                  }
                  console.log("test");
                  setStatus({ success: true });
                  setSubmitting(false);
                  navigate("/providerConfigs");
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values,
                }): JSX.Element => (
                  <form onSubmit={handleSubmit} {...props}>
                    <Box sx={{ p: 3 }}>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <Card>
                            <CardContent>
                              <CardHeader title="Form" />
                              <Box sx={{ mt: 2 }}>
                                <Autocomplete
                                  value={values.form_provider}
                                  onChange={(_, newValue) => {
                                    setPreviewed(false);
                                    if (!newValue) {
                                      setFieldValue("form_provider", "");
                                      return;
                                    }
                                    setFieldValue("form_provider", newValue);
                                  }}
                                  isOptionEqualToValue={(option, current) =>
                                    option === current
                                  }
                                  options={[
                                    "DoYouSpain.com",
                                    "DiscoverCars.com",
                                  ]}
                                  getOptionLabel={(option) => option}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Provider"
                                      variant="outlined"
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="form_provider"
                                  component="div"
                                />
                              </Box>
                              <Box sx={{ mt: 2 }}>
                                <Autocomplete
                                  multiple
                                  value={values.form_categories}
                                  onChange={(_, newValue) => {
                                    setPreviewed(false);
                                    if (!newValue) {
                                      setFieldValue("form_categories", "");
                                      return;
                                    }
                                    setFieldValue("form_categories", newValue);
                                  }}
                                  options={[
                                    "automatica",
                                    "mini",
                                    "piccola",
                                    "media",
                                    "standard",
                                    "intermedia",
                                    "wagon",
                                    "van 9 posti",
                                    "van 7 posti",
                                    "monovolume",
                                    "berlina",
                                    "berlina lusso",
                                    "premium",
                                    "suv",
                                    "cabrio",
                                  ]}
                                  getOptionLabel={(option: string) => option}
                                  isOptionEqualToValue={(option, current) =>
                                    option === current
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Categorie Automobili"
                                      variant="outlined"
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="form_categories"
                                  component="div"
                                />
                              </Box>
                              <Box sx={{ mt: 2 }}>
                                <Autocomplete
                                  multiple
                                  value={values.form_localita}
                                  onChange={(_, newValue) => {
                                    setPreviewed(false);
                                    if (!newValue) {
                                      setFieldValue("form_localita", "");
                                      return;
                                    }
                                    setFieldValue("form_localita", newValue);
                                  }}
                                  options={
                                    values.form_provider === "DoYouSpain.com"
                                      ? locationsStruct.doyouspain
                                      : values.form_provider === "Rent.it"
                                      ? locationsStruct.rent
                                      : values.form_provider === "Tinoleggio.it"
                                      ? locationsStruct.tinoleggio
                                      : values.form_provider ===
                                        "DiscoverCars.com"
                                      ? locationsStruct.discovercars
                                      : []
                                  }
                                  // options={["Catania", "Cagliari", "Milano", "Lecce"]}
                                  getOptionLabel={(option) => option}
                                  isOptionEqualToValue={(option, current) =>
                                    option === current
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Localita"
                                      variant="outlined"
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="form_localita"
                                  component="div"
                                />
                              </Box>
                              <Box sx={{ mt: 3, ml: 1, mr: -1 }}>
                                <Autocomplete
                                  multiple
                                  freeSolo
                                  value={values.form_steps?.map((step) => {
                                    return step.toString();
                                  })}
                                  onChange={(_, newValue) => {
                                    setPreviewed(false);
                                    if (!newValue) {
                                      setFieldValue("form_steps", "");
                                      return;
                                    }
                                    setFieldValue("form_steps", newValue.map((step) => parseInt(step)));
                                  }}
                                  options={["1", "3", "5", "7", "10", "14"]}
                                  getOptionLabel={(option) => option}
                                  isOptionEqualToValue={(option, current) =>
                                    option === current
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Giorni da analizzare"
                                      variant="outlined"
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="form_steps"
                                  component="div"
                                />
                              </Box>
                              <Box sx={{ mt: 4, ml: 1, mr: -1 }}>
                                <Autocomplete
                                  multiple
                                  freeSolo
                                  value={values.form_weekDaysStart?.map((step) => {
                                    return step.toString();
                                  })}
                                  onChange={(_, newValue) => {
                                    setPreviewed(false);
                                    if (!newValue) {
                                      setFieldValue("form_weekDaysStart", "");
                                      return;
                                    }
                                    setFieldValue("form_weekDaysStart", newValue.map((step) => parseInt(step)));
                                  }}
                                  options={["1", "2", "3", "4", "5", "6", "7"]}
                                  getOptionLabel={(option) => option}
                                  isOptionEqualToValue={(option, current) =>
                                    option === current
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Inizio settimana"
                                      variant="outlined"
                                    />
                                  )}
                                />

                                <ErrorMessage
                                  name="form_weekDaysStart"
                                  component="div"
                                />
                              </Box>
                              <Box sx={{ mt: 4, ml: 1, mr: -1 }}>
                                <Autocomplete
                                  multiple
                                  freeSolo
                                  value={values.form_monthDaysStart?.map((step) => {
                                    return step.toString();
                                  })}
                                  onChange={(_, newValue) => {
                                    setPreviewed(false);
                                    if (!newValue) {
                                      setFieldValue("form_monthDaysStart", "");
                                      return;
                                    }
                                    setFieldValue("form_monthDaysStart", newValue.map((step) => parseInt(step)));
                                  }}
                                  options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]}
                                  getOptionLabel={(option) => option}
                                  isOptionEqualToValue={(option, current) =>
                                    option === current
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Inizio mese"
                                      variant="outlined"
                                    />
                                  )}
                                />

                                <ErrorMessage
                                  name="form_monthDaysStart"
                                  component="div"
                                />
                              </Box>
                              <Box sx={{ mt: 3 }}>
                                <Autocomplete
                                  onChange={(_, newValue) => {
                                    setPreviewed(false);
                                    if (!newValue) {
                                      setFieldValue("form_monthToCheck", "");
                                      return;
                                    }
                                    setFieldValue(
                                      "form_monthToCheck",
                                      newValue
                                    );
                                  }}
                                  multiple
                                  value={values.form_monthToCheck}
                                  options={[
                                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                                  ]}
                                  getOptionLabel={(option) => option as any}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Mesi da analizzare (1 = Gennaio, 2 = Febbraio .. )"
                                      variant="outlined"
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="form_monthToCheck"
                                  component="div"
                                />
                              </Box>
                              <Box sx={{ mt: 3 }}>
                                <Autocomplete
                                  value={values.form_monthsDuration}
                                  onChange={(_, newValue) => {
                                    setPreviewed(false);
                                    if (!newValue) {
                                      setFieldValue("form_monthsDuration", "");
                                      return;
                                    }
                                    setFieldValue(
                                      "form_monthsDuration",
                                      newValue
                                    );
                                  }}
                                  options={[
                                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                                  ]}
                                  isOptionEqualToValue={(option, current) =>
                                    option === current
                                  }
                                  getOptionLabel={(option) => String(option)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Estensione ricerca ( in mesi )"
                                      variant="outlined"
                                    />
                                  )}
                                />

                                <ErrorMessage
                                  name="form_monthsDuration"
                                  component="div"
                                />
                              </Box>
                              <Box sx={{ mt: 3 }}>
                                <Autocomplete
                                  multiple
                                  value={values.form_scheduletAt}
                                  onChange={(_, newValue) => {
                                    setPreviewed(false);
                                    if (!newValue) {
                                      setFieldValue("form_scheduletAt", []);
                                      return;
                                    }
                                    setFieldValue("form_scheduletAt", newValue);
                                  }}
                                  options={[
                                    "00:00",
                                    "00:15",
                                    "00:30",
                                    "00:45",
                                    "01:00",
                                    "01:15",
                                    "01:30",
                                    "01:45",
                                    "02:00",
                                    "02:15",
                                    "02:30",
                                    "02:45",
                                    "03:00",
                                    "03:15",
                                    "03:30",
                                    "03:45",
                                    "04:00",
                                    "04:15",
                                    "04:30",
                                    "04:45",
                                    "05:00",
                                    "05:15",
                                    "05:30",
                                    "05:45",
                                    "06:00",
                                    "06:15",
                                    "06:30",
                                    "06:45",
                                    "07:00",
                                    "07:15",
                                    "07:30",
                                    "07:45",
                                    "08:00",
                                    "08:15",
                                    "08:30",
                                    "08:45",
                                    "09:00",
                                    "09:15",
                                    "09:30",
                                    "09:45",
                                    "10:00",
                                    "10:15",
                                    "10:30",
                                    "10:45",
                                    "11:00",
                                    "11:15",
                                    "11:30",
                                    "11:45",
                                    "12:00",
                                    "12:15",
                                    "12:30",
                                    "12:45",
                                    "13:00",
                                    "13:15",
                                    "13:30",
                                    "13:45",
                                    "14:00",
                                    "14:15",
                                    "14:30",
                                    "14:45",
                                    "15:00",
                                    "15:15",
                                    "15:30",
                                    "15:45",
                                    "16:00",
                                    "16:15",
                                    "16:30",
                                    "16:45",
                                    "17:00",
                                    "17:15",
                                    "17:30",
                                    "17:45",
                                    "18:00",
                                    "18:15",
                                    "18:30",
                                    "18:45",
                                    "19:00",
                                    "19:15",
                                    "19:30",
                                    "19:45",
                                    "20:00",
                                    "20:15",
                                    "20:30",
                                    "20:45",
                                    "21:00",
                                    "21:15",
                                    "21:30",
                                    "21:45",
                                    "22:00",
                                    "22:15",
                                    "22:30",
                                    "22:45",
                                    "23:00",
                                    "23:15",
                                    "23:30",
                                    "23:45",
                                  ]}
                                  getOptionLabel={(option) => option as any}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Orario esecuzione ricerca"
                                      variant="outlined"
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="form_scheduletAt"
                                  component="div"
                                />
                              </Box>
                              <Box sx={{ mt: 3 }}>
                                <TextField
                                  error={Boolean(
                                    touched.form_maxAttempts &&
                                      errors.form_maxAttempts
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.form_maxAttempts &&
                                    errors.form_maxAttempts
                                  }
                                  label="Max tentativi"
                                  margin="normal"
                                  name="form_maxAttempts"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="number"
                                  variant="outlined"
                                  value={values.form_maxAttempts}
                                />
                              </Box>

                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  mt: 1,
                                }}
                              >
                                <Checkbox
                                  checked={values.form_useTor}
                                  color="primary"
                                  name="form_useTor"
                                  onChange={handleChange}
                                />
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  Usa TOR
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 3,
                        }}
                      >
                        {previewed && (
                          <Alert severity="info">
                            <AlertTitle>Conteggio richieste</AlertTitle>
                            Verranno effettuate{" "}
                            <div className="conteggio">{requestCount}</div>{" "}
                            ricerche.
                          </Alert>
                        )}
                        {previewed && (
                          <Button
                            color="primary"
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                          >
                            Aggiorna
                          </Button>
                        )}
                        {!previewed && (
                          <Button
                            color="primary"
                            disabled={isSubmitting}
                            onClick={() => calculateRequests(values)}
                            variant="contained"
                          >
                            Calcola
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UpdateProviderConfig;
