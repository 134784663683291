import type { GraphQLClient } from 'graphql-request';
import type * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Json: any;
  Time: any;
  UnixDate: any;
  Upload: any;
};





export type AcrissCode = {
  __typename?: 'AcrissCode';
  id: Scalars['String'];
  code: Scalars['String'];
  gruppo: Scalars['String'];
  auto: Scalars['String'];
  classe: Scalars['String'];
  posti: Scalars['Int'];
  porte: Scalars['Int'];
  cambio: Scalars['String'];
};

export type AcrissCodeInputForm = {
  code: Scalars['String'];
  gruppo: Scalars['String'];
  auto: Scalars['String'];
};

export type AcrissCodeUpdateForm = {
  code: Scalars['String'];
  gruppo: Scalars['String'];
};

export type Analytic = {
  __typename?: 'Analytic';
  id: Scalars['String'];
  date: Scalars['String'];
  source: Scalars['String'];
  acriss: Scalars['String'];
  group: Scalars['String'];
  location: Scalars['String'];
  duration: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  multipliedPrice?: Maybe<Scalars['Float']>;
  isSelected: Scalars['Boolean'];
  isFelirent: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type AnalyticInput = {
  id: Scalars['String'];
  date: Scalars['String'];
  source: Scalars['String'];
  acriss: Scalars['String'];
  location: Scalars['String'];
  duration: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  multipliedPrice?: Maybe<Scalars['Float']>;
  isSelected: Scalars['Boolean'];
  isFelirent: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['String'];
  description: Scalars['String'];
};

export type CompanyInputForm = {
  description: Scalars['String'];
};

export type CompanyUpdateForm = {
  description: Scalars['String'];
};

export type Configuration = {
  __typename?: 'Configuration';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type ConfigurationInputForm = {
  id: Scalars['String'];
  value: Scalars['String'];
};

export type ConfigurationUpdateForm = {
  value: Scalars['String'];
};

export type CrawlerJob = {
  __typename?: 'CrawlerJob';
  id: Scalars['String'];
  providerConfigID: Scalars['String'];
  provider: Scalars['String'];
  localita: Scalars['String'];
  codificaLocalita: Scalars['String'];
  categoria: Array<Scalars['String']>;
  status: Scalars['String'];
  customer: Scalars['String'];
  scheduledAt: Scalars['Time'];
  from: Scalars['String'];
  to: Scalars['String'];
  pubsubbed: Scalars['Boolean'];
  pubsubbedAt?: Maybe<Scalars['Time']>;
  startedAt?: Maybe<Scalars['Time']>;
  finishedAt?: Maybe<Scalars['Time']>;
  jobDurationSeconds: Scalars['Int'];
  resultRows: Scalars['Int'];
  step: Scalars['Int'];
  companies: Array<Scalars['String']>;
  attempts: Scalars['Int'];
  maxAttempts: Scalars['Int'];
  useTor: Scalars['Boolean'];
};

export type CrawlerJobInputForm = {
  providerConfigID: Scalars['String'];
  provider: Scalars['String'];
  localita: Scalars['String'];
  codificaLocalita: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
  categoria: Array<Scalars['String']>;
  status: Scalars['String'];
  customer: Scalars['String'];
  scheduledAt: Scalars['Time'];
  doneAt: Scalars['Time'];
  step: Scalars['Int'];
  companies: Array<Scalars['String']>;
  maxAttempts: Scalars['Int'];
  useTor: Scalars['Boolean'];
};

export type CrawlerJobUpdateForm = {
  providerConfigID: Scalars['String'];
  provider: Scalars['String'];
  localita: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
  categoria: Array<Scalars['String']>;
  status: Scalars['String'];
  lastRun: Scalars['Time'];
  nextRun: Scalars['Time'];
  companies: Array<Scalars['String']>;
  maxAttempts: Scalars['Int'];
  useTor: Scalars['Boolean'];
};

export type CrawlerResult = {
  __typename?: 'CrawlerResult';
  id: Scalars['String'];
  provider: Scalars['String'];
  classe: Scalars['String'];
  Posti: Scalars['String'];
  Porte: Scalars['String'];
  Cambio: Scalars['String'];
  Auto: Scalars['String'];
  durata_dal: Scalars['String'];
  durata_al: Scalars['String'];
  durata_gg: Scalars['Int'];
  prezzo_totale: Scalars['Float'];
  prezzo_giornaliero: Scalars['Float'];
  last_update: Scalars['Time'];
  jobId: Scalars['String'];
  luogo: Scalars['String'];
  venditore: Scalars['String'];
  companies: Array<Scalars['String']>;
  search_url?: Maybe<Scalars['String']>;
};

export type CrawlerResultInputForm = {
  provider: Scalars['String'];
  classe: Scalars['String'];
  durata_dal: Scalars['String'];
  durata_al: Scalars['String'];
  durata_gg: Scalars['Int'];
  prezzo_totale: Scalars['Float'];
  prezzo_giornaliero: Scalars['Float'];
  last_update: Scalars['Time'];
  jobId: Scalars['String'];
  luogo: Scalars['String'];
  venditore: Scalars['String'];
  companies: Array<Scalars['String']>;
  search_url?: Maybe<Scalars['String']>;
};

export type CrawlerResultUpdateForm = {
  provider: Scalars['String'];
  classe: Scalars['String'];
  durata_dal: Scalars['String'];
  durata_al: Scalars['String'];
  durata_gg: Scalars['Int'];
  prezzo_totale: Scalars['Float'];
  prezzo_giornaliero: Scalars['Float'];
  last_update: Scalars['Time'];
  luogo: Scalars['String'];
  venditore: Scalars['String'];
  companies: Array<Scalars['String']>;
  search_url?: Maybe<Scalars['String']>;
};

export type CrawlerResultsTable = {
  __typename?: 'CrawlerResultsTable';
  pages: Scalars['Int'];
  count: Scalars['Int'];
  items?: Maybe<Array<Maybe<CrawlerResult>>>;
  options?: Maybe<Array<Maybe<TableSelectOptions>>>;
  op?: Maybe<Array<Maybe<TableOperation>>>;
};

export type Customerconfig = {
  __typename?: 'Customerconfig';
  id: Scalars['String'];
  customer: Scalars['String'];
  provider: Scalars['String'];
  name: Scalars['String'];
};

export type CustomerconfigInputForm = {
  customer: Scalars['String'];
  provider: Scalars['String'];
  name: Scalars['String'];
};

export type CustomerconfigUpdateForm = {
  customer: Scalars['String'];
  provider: Scalars['String'];
  name: Scalars['String'];
};

export type DashboardInfo = {
  __typename?: 'DashboardInfo';
  numCrawlerResults: Scalars['Int'];
  numProviderConfigs: Scalars['Int'];
  numLocations: Scalars['Int'];
  jobsInfo: Jobs;
  risultatiPerProvider: Array<Maybe<RisultatiPerProvider>>;
  risultatiPerCategoria: Array<Maybe<RisultatoCategoria>>;
  risultatiPerProviderPerCategoria: Array<Maybe<RisultatoProviderCategoria>>;
  captchaCredit: Scalars['String'];
};

export type DateFilter = {
  data?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type FilterInput = {
  column: Scalars['String'];
  value?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};

export type GlobalSearchInput = {
  columns: Array<Maybe<Scalars['String']>>;
  value?: Maybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  subject: Scalars['String'];
  role: Scalars['String'];
  domain: Scalars['String'];
};

export type Jobs = {
  __typename?: 'Jobs';
  jobsPianificati: Scalars['Int'];
  jobsDaEseguire: Scalars['Int'];
  jobsInCorso: Scalars['Int'];
  jobsEseguito: Scalars['Int'];
  jobsFalliti: Scalars['Int'];
};


export type Location = {
  __typename?: 'Location';
  id: Scalars['String'];
  provider: Scalars['String'];
  comune: Scalars['String'];
  transCodifica: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  pingMutation: Scalars['String'];
  createAcrissCode: AcrissCode;
  updateAcrissCode: AcrissCode;
  deleteAcrissCode: AcrissCode;
  addAnalytic: Analytic;
  generateAnalytics: Array<Analytic>;
  updateMany: Scalars['Boolean'];
  resetTable: Scalars['Boolean'];
  AddPolicy?: Maybe<Policy>;
  AddGroup?: Maybe<Group>;
  createCompany: Company;
  updateCompany: Company;
  deleteCompany: Company;
  createConfiguration: Configuration;
  updateConfiguration: Configuration;
  deleteConfiguration: Configuration;
  runJob: CrawlerJob;
  runJobs: Scalars['Boolean'];
  createCrawlerJob: CrawlerJob;
  updateCrawlerJob: CrawlerJob;
  deleteCrawlerJob: CrawlerJob;
  generateCrawlerJobs: Array<CrawlerJob>;
  createCrawlerResult: CrawlerResult;
  updateCrawlerResult: CrawlerResult;
  deleteCrawlerResult: CrawlerResult;
  startCrawler: Array<CrawlerResult>;
  downloadResults?: Maybe<Scalars['String']>;
  downloadResultsForIncrement?: Maybe<Scalars['String']>;
  downloadResultsForAcriss?: Maybe<Scalars['String']>;
  createCustomerconfig: Customerconfig;
  updateCustomerconfig: Customerconfig;
  deleteCustomerconfig: Customerconfig;
  createLocation: Location;
  updateLocation: Location;
  deleteLocation: Location;
  calculateJobNumber: Scalars['Int'];
  createProviderConfig: ProviderConfig;
  updateProviderConfig: ProviderConfig;
  deleteProviderConfig: ProviderConfig;
  changeActive: ProviderConfig;
  duplicateConfig: ProviderConfig;
  createUser: TokenDetails;
  updateUser: User;
  updateAvatar: User;
  updateSelfPassword: User;
  updateUserPassword: User;
  recoverPassword: Scalars['Boolean'];
  resetPassword: TokenDetails;
  impersonates: TokenDetails;
  createRole?: Maybe<Role>;
};


export type MutationCreateAcrissCodeArgs = {
  form?: Maybe<AcrissCodeInputForm>;
};


export type MutationUpdateAcrissCodeArgs = {
  id: Scalars['String'];
  form?: Maybe<AcrissCodeUpdateForm>;
};


export type MutationDeleteAcrissCodeArgs = {
  id: Scalars['String'];
};


export type MutationAddAnalyticArgs = {
  date: Scalars['String'];
  source: Scalars['String'];
  acriss: Scalars['String'];
  location: Scalars['String'];
  duration: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  multipliedPrice: Scalars['Float'];
  isSelected: Scalars['Boolean'];
  isFelirent: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};


export type MutationUpdateManyArgs = {
  input: Array<AnalyticInput>;
  acriss: Array<Scalars['String']>;
  source: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
};


export type MutationAddPolicyArgs = {
  subject: Scalars['String'];
  object: Scalars['String'];
  action: Scalars['String'];
};


export type MutationAddGroupArgs = {
  subject: Scalars['String'];
  role: Scalars['String'];
};


export type MutationCreateCompanyArgs = {
  form?: Maybe<CompanyInputForm>;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['String'];
  form?: Maybe<CompanyUpdateForm>;
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['String'];
};


export type MutationCreateConfigurationArgs = {
  form?: Maybe<ConfigurationInputForm>;
};


export type MutationUpdateConfigurationArgs = {
  id: Scalars['String'];
  form?: Maybe<ConfigurationUpdateForm>;
};


export type MutationDeleteConfigurationArgs = {
  id: Scalars['String'];
};


export type MutationRunJobArgs = {
  id: Scalars['String'];
};


export type MutationRunJobsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationCreateCrawlerJobArgs = {
  form?: Maybe<CrawlerJobInputForm>;
};


export type MutationUpdateCrawlerJobArgs = {
  id: Scalars['String'];
  form?: Maybe<CrawlerJobUpdateForm>;
};


export type MutationDeleteCrawlerJobArgs = {
  id: Scalars['String'];
};


export type MutationCreateCrawlerResultArgs = {
  form?: Maybe<CrawlerResultInputForm>;
};


export type MutationUpdateCrawlerResultArgs = {
  id: Scalars['String'];
  form?: Maybe<CrawlerResultUpdateForm>;
};


export type MutationDeleteCrawlerResultArgs = {
  id: Scalars['String'];
};


export type MutationDownloadResultsArgs = {
  start: Scalars['Time'];
  end: Scalars['Time'];
};


export type MutationDownloadResultsForIncrementArgs = {
  start: Scalars['Time'];
  end: Scalars['Time'];
  luogo: Scalars['String'];
};


export type MutationDownloadResultsForAcrissArgs = {
  start: Scalars['Time'];
  end: Scalars['Time'];
  luogo: Scalars['String'];
};


export type MutationCreateCustomerconfigArgs = {
  form?: Maybe<CustomerconfigInputForm>;
};


export type MutationUpdateCustomerconfigArgs = {
  id: Scalars['String'];
  form?: Maybe<CustomerconfigUpdateForm>;
};


export type MutationDeleteCustomerconfigArgs = {
  id: Scalars['String'];
};


export type MutationCreateLocationArgs = {
  form?: Maybe<NewLocation>;
};


export type MutationUpdateLocationArgs = {
  id: Scalars['String'];
  form?: Maybe<UpdateLocation>;
};


export type MutationDeleteLocationArgs = {
  id: Scalars['String'];
};


export type MutationCalculateJobNumberArgs = {
  form?: Maybe<ProviderConfigInputForm>;
};


export type MutationCreateProviderConfigArgs = {
  form?: Maybe<ProviderConfigInputForm>;
};


export type MutationUpdateProviderConfigArgs = {
  id: Scalars['String'];
  form?: Maybe<ProviderConfigUpdateForm>;
};


export type MutationDeleteProviderConfigArgs = {
  id: Scalars['String'];
};


export type MutationChangeActiveArgs = {
  id: Scalars['String'];
  active: Scalars['Boolean'];
};


export type MutationDuplicateConfigArgs = {
  id: Scalars['String'];
};


export type MutationCreateUserArgs = {
  user?: Maybe<UserInputWithPassword>;
};


export type MutationUpdateUserArgs = {
  email: Scalars['String'];
  user?: Maybe<UpdateUserInput>;
};


export type MutationUpdateAvatarArgs = {
  email: Scalars['String'];
  avatar: Scalars['String'];
};


export type MutationUpdateSelfPasswordArgs = {
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationUpdateUserPasswordArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationRecoverPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  code: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationImpersonatesArgs = {
  email: Scalars['String'];
};


export type MutationCreateRoleArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
};

export type NewLocation = {
  provider: Scalars['String'];
  comune: Scalars['String'];
  transCodifica: Scalars['String'];
};

export type Policy = {
  __typename?: 'Policy';
  subject: Scalars['String'];
  domain: Scalars['String'];
  object: Scalars['String'];
  action: Scalars['String'];
};

export enum Provider {
  Doyouspain = 'DOYOUSPAIN',
  Rentit = 'RENTIT',
  Tinoleggio = 'TINOLEGGIO',
  Discovercars = 'DISCOVERCARS'
}

export type ProviderConfig = {
  __typename?: 'ProviderConfig';
  id: Scalars['String'];
  provider: Scalars['String'];
  localita: Array<Scalars['String']>;
  steps: Array<Scalars['Int']>;
  weekDaysStart: Array<Scalars['Int']>;
  monthDaysStart: Array<Scalars['Int']>;
  monthToCheck: Array<Scalars['Int']>;
  monthsDuration: Scalars['Int'];
  categories: Array<Scalars['String']>;
  scheduledAt: Array<Scalars['String']>;
  company: Scalars['String'];
  lastUpdate: Scalars['Int'];
  deleted?: Maybe<Scalars['Boolean']>;
  maxAttempts: Scalars['Int'];
  useTor: Scalars['Boolean'];
  active: Scalars['Boolean'];
  createdOn: Scalars['Int'];
};

export type ProviderConfigInputForm = {
  provider: Scalars['String'];
  localita: Array<Scalars['String']>;
  steps: Array<Scalars['Int']>;
  weekDaysStart: Array<Scalars['Int']>;
  monthDaysStart: Array<Scalars['Int']>;
  monthToCheck: Array<Scalars['Int']>;
  monthsDuration: Scalars['Int'];
  categories: Array<Scalars['String']>;
  scheduledAt: Array<Scalars['String']>;
  company: Scalars['String'];
  maxAttempts: Scalars['Int'];
  useTor: Scalars['Boolean'];
};

export type ProviderConfigUpdateForm = {
  provider: Scalars['String'];
  localita: Array<Scalars['String']>;
  steps: Array<Scalars['Int']>;
  weekDaysStart: Array<Scalars['Int']>;
  monthDaysStart: Array<Scalars['Int']>;
  monthToCheck: Array<Scalars['Int']>;
  monthsDuration: Scalars['Int'];
  categories: Array<Scalars['String']>;
  scheduledAt: Array<Scalars['String']>;
  company: Scalars['String'];
  maxAttempts: Scalars['Int'];
  useTor: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  ping: Scalars['String'];
  acrissCodes?: Maybe<Array<Maybe<AcrissCode>>>;
  acrissCode: AcrissCode;
  analytics: Array<Analytic>;
  analyticsByDate: Array<Analytic>;
  getAnalyticExcel?: Maybe<Scalars['String']>;
  analyticsSourceOptions: Array<Scalars['String']>;
  getPolicies?: Maybe<Array<Maybe<Policy>>>;
  getGroups?: Maybe<Array<Maybe<Group>>>;
  companys?: Maybe<Array<Maybe<Company>>>;
  company: Company;
  configurations?: Maybe<Array<Maybe<Configuration>>>;
  configuration: Configuration;
  crawlerJobs?: Maybe<Array<Maybe<CrawlerJob>>>;
  crawlerJobsToRun?: Maybe<Array<Maybe<CrawlerJob>>>;
  crawlerJob: CrawlerJob;
  crawlerResults?: Maybe<Array<Maybe<CrawlerResult>>>;
  crawlerResult: CrawlerResult;
  getRemoteResults: CrawlerResultsTable;
  customerconfigs?: Maybe<Array<Maybe<Customerconfig>>>;
  customerconfig: Customerconfig;
  getDashboardInfo?: Maybe<DashboardInfo>;
  getLocations?: Maybe<Array<Maybe<Location>>>;
  getLocation: Location;
  getProviderLocations: Array<Maybe<Location>>;
  providerConfigs?: Maybe<Array<Maybe<ProviderConfig>>>;
  providerConfig: ProviderConfig;
  users?: Maybe<Array<Maybe<User>>>;
  user: User;
  loggedUser: User;
  basicLogin: TokenDetails;
  getNewAccessToken: TokenDetails;
  roles?: Maybe<Array<Maybe<Role>>>;
};


export type QueryAcrissCodeArgs = {
  id: Scalars['String'];
};


export type QueryAnalyticsByDateArgs = {
  month: Scalars['Int'];
  year: Scalars['Int'];
  source?: Maybe<Scalars['String']>;
};


export type QueryGetAnalyticExcelArgs = {
  month: Scalars['Int'];
  year: Scalars['Int'];
  mul: Scalars['Float'];
  mul21: Scalars['Float'];
  mul25: Scalars['Float'];
  source?: Maybe<Scalars['String']>;
  selection?: Maybe<Array<Scalars['String']>>;
};


export type QueryCompanyArgs = {
  id: Scalars['String'];
};


export type QueryConfigurationArgs = {
  id: Scalars['String'];
};


export type QueryCrawlerJobsArgs = {
  filter?: Maybe<DateFilter>;
};


export type QueryCrawlerJobArgs = {
  id: Scalars['String'];
};


export type QueryCrawlerResultsArgs = {
  filter?: Maybe<DateFilter>;
};


export type QueryCrawlerResultArgs = {
  id: Scalars['String'];
};


export type QueryGetRemoteResultsArgs = {
  input: TableInput;
};


export type QueryCustomerconfigArgs = {
  id: Scalars['String'];
};


export type QueryGetDashboardInfoArgs = {
  filter?: Maybe<DateFilter>;
};


export type QueryGetLocationArgs = {
  id: Scalars['String'];
};


export type QueryGetProviderLocationsArgs = {
  provider: Scalars['String'];
};


export type QueryProviderConfigArgs = {
  id: Scalars['String'];
};


export type QueryUserArgs = {
  email: Scalars['String'];
};


export type QueryBasicLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type QueryGetNewAccessTokenArgs = {
  refreshToken: Scalars['String'];
};

export enum Resources {
  Users = 'USERS',
  Roles = 'ROLES',
  Groups = 'GROUPS',
  Policies = 'POLICIES'
}

export type ResponseDelete = {
  __typename?: 'ResponseDelete';
  success: Scalars['Boolean'];
  message: Scalars['String'];
  rowAffected: Scalars['Int'];
};

export type RisultatiPerProvider = {
  __typename?: 'RisultatiPerProvider';
  provider: Scalars['String'];
  count: Scalars['Int'];
};

export type RisultatoCategoria = {
  __typename?: 'RisultatoCategoria';
  categoria: Scalars['String'];
  quanti: Scalars['Int'];
};

export type RisultatoProviderCategoria = {
  __typename?: 'RisultatoProviderCategoria';
  provider: Scalars['String'];
  risultatiPerCategoria: Array<Maybe<RisultatoCategoria>>;
};

export type Role = {
  __typename?: 'Role';
  name: Scalars['String'];
  description: Scalars['String'];
};

export type TableInput = {
  records: Scalars['Int'];
  page: Scalars['Int'];
  orderBy: Scalars['String'];
  selectOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Array<Maybe<FilterInput>>>;
  sumColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  avgColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalSearch?: Maybe<GlobalSearchInput>;
};

export type TableOperation = {
  __typename?: 'TableOperation';
  id: Scalars['String'];
  result: Scalars['String'];
  type: Scalars['String'];
};

export type TableSelectOptions = {
  __typename?: 'TableSelectOptions';
  column: Scalars['String'];
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TokenDetails = {
  __typename?: 'TokenDetails';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  accessUuid: Scalars['String'];
  refreshUuid: Scalars['String'];
  atExpires: Scalars['Int'];
  rtExpires: Scalars['Int'];
  user: User;
  permissions: Scalars['String'];
};


export type UpdateLocation = {
  provider: Scalars['String'];
  comune: Scalars['String'];
  transCodifica: Scalars['String'];
};

export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company: Scalars['String'];
};


export type User = {
  __typename?: 'User';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  avatar: Scalars['String'];
  createdAt?: Maybe<Scalars['Time']>;
  company: Scalars['String'];
};

export type UserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  salt: Scalars['String'];
  email: Scalars['String'];
  avatar: Scalars['String'];
  company: Scalars['String'];
};

export type UserInputWithPassword = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  email: Scalars['String'];
  company: Scalars['String'];
};

export type AddGroupMutationVariables = Exact<{
  subject: Scalars['String'];
  role: Scalars['String'];
}>;


export type AddGroupMutation = (
  { __typename?: 'Mutation' }
  & { AddGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'subject' | 'role' | 'domain'>
  )> }
);

export type AddPolicyMutationVariables = Exact<{
  subject: Scalars['String'];
  object: Scalars['String'];
  action: Scalars['String'];
}>;


export type AddPolicyMutation = (
  { __typename?: 'Mutation' }
  & { AddPolicy?: Maybe<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'subject' | 'domain' | 'object' | 'action'>
  )> }
);

export type AddAnalyticMutationVariables = Exact<{
  date: Scalars['String'];
  source: Scalars['String'];
  acriss: Scalars['String'];
  location: Scalars['String'];
  duration: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  multipliedPrice: Scalars['Float'];
  isSelected: Scalars['Boolean'];
  isFelirent: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
}>;


export type AddAnalyticMutation = (
  { __typename?: 'Mutation' }
  & { addAnalytic: (
    { __typename?: 'Analytic' }
    & Pick<Analytic, 'id' | 'date' | 'source' | 'acriss' | 'group' | 'location' | 'duration' | 'name' | 'price' | 'multipliedPrice' | 'isSelected' | 'isFelirent' | 'url'>
  ) }
);

export type CalculateJobNumberMutationVariables = Exact<{
  form?: Maybe<ProviderConfigInputForm>;
}>;


export type CalculateJobNumberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'calculateJobNumber'>
);

export type ChangeActiveMutationVariables = Exact<{
  id: Scalars['String'];
  active: Scalars['Boolean'];
}>;


export type ChangeActiveMutation = (
  { __typename?: 'Mutation' }
  & { changeActive: (
    { __typename?: 'ProviderConfig' }
    & Pick<ProviderConfig, 'id' | 'provider' | 'localita' | 'steps' | 'weekDaysStart' | 'monthDaysStart' | 'monthToCheck' | 'monthsDuration' | 'categories' | 'scheduledAt' | 'company' | 'lastUpdate' | 'deleted' | 'maxAttempts' | 'useTor' | 'active' | 'createdOn'>
  ) }
);

export type CreateAcrissCodeMutationVariables = Exact<{
  form?: Maybe<AcrissCodeInputForm>;
}>;


export type CreateAcrissCodeMutation = (
  { __typename?: 'Mutation' }
  & { createAcrissCode: (
    { __typename?: 'AcrissCode' }
    & Pick<AcrissCode, 'id' | 'code' | 'gruppo' | 'auto' | 'classe' | 'posti' | 'porte' | 'cambio'>
  ) }
);

export type CreateCompanyMutationVariables = Exact<{
  form?: Maybe<CompanyInputForm>;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'description'>
  ) }
);

export type CreateConfigurationMutationVariables = Exact<{
  form?: Maybe<ConfigurationInputForm>;
}>;


export type CreateConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { createConfiguration: (
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'value'>
  ) }
);

export type CreateCrawlerJobMutationVariables = Exact<{
  form?: Maybe<CrawlerJobInputForm>;
}>;


export type CreateCrawlerJobMutation = (
  { __typename?: 'Mutation' }
  & { createCrawlerJob: (
    { __typename?: 'CrawlerJob' }
    & Pick<CrawlerJob, 'id' | 'providerConfigID' | 'provider' | 'localita' | 'codificaLocalita' | 'categoria' | 'status' | 'customer' | 'scheduledAt' | 'from' | 'to' | 'pubsubbed' | 'pubsubbedAt' | 'startedAt' | 'finishedAt' | 'jobDurationSeconds' | 'resultRows' | 'step' | 'companies' | 'attempts' | 'maxAttempts' | 'useTor'>
  ) }
);

export type CreateCrawlerResultMutationVariables = Exact<{
  form?: Maybe<CrawlerResultInputForm>;
}>;


export type CreateCrawlerResultMutation = (
  { __typename?: 'Mutation' }
  & { createCrawlerResult: (
    { __typename?: 'CrawlerResult' }
    & Pick<CrawlerResult, 'id' | 'provider' | 'classe' | 'Posti' | 'Porte' | 'Cambio' | 'Auto' | 'durata_dal' | 'durata_al' | 'durata_gg' | 'prezzo_totale' | 'prezzo_giornaliero' | 'last_update' | 'jobId' | 'luogo' | 'venditore' | 'companies' | 'search_url'>
  ) }
);

export type CreateCustomerconfigMutationVariables = Exact<{
  form?: Maybe<CustomerconfigInputForm>;
}>;


export type CreateCustomerconfigMutation = (
  { __typename?: 'Mutation' }
  & { createCustomerconfig: (
    { __typename?: 'Customerconfig' }
    & Pick<Customerconfig, 'id' | 'customer' | 'provider' | 'name'>
  ) }
);

export type CreateLocationMutationVariables = Exact<{
  form?: Maybe<NewLocation>;
}>;


export type CreateLocationMutation = (
  { __typename?: 'Mutation' }
  & { createLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'provider' | 'comune' | 'transCodifica'>
  ) }
);

export type CreateProviderConfigMutationVariables = Exact<{
  form?: Maybe<ProviderConfigInputForm>;
}>;


export type CreateProviderConfigMutation = (
  { __typename?: 'Mutation' }
  & { createProviderConfig: (
    { __typename?: 'ProviderConfig' }
    & Pick<ProviderConfig, 'id' | 'provider' | 'localita' | 'steps' | 'weekDaysStart' | 'monthDaysStart' | 'monthToCheck' | 'monthsDuration' | 'categories' | 'scheduledAt' | 'company' | 'lastUpdate' | 'deleted' | 'maxAttempts' | 'useTor' | 'active' | 'createdOn'>
  ) }
);

export type CreateRoleMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'name' | 'description'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  user?: Maybe<UserInputWithPassword>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt' | 'company'>
    ) }
  ) }
);

export type DeleteAcrissCodeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAcrissCodeMutation = (
  { __typename?: 'Mutation' }
  & { deleteAcrissCode: (
    { __typename?: 'AcrissCode' }
    & Pick<AcrissCode, 'id' | 'code' | 'gruppo' | 'auto' | 'classe' | 'posti' | 'porte' | 'cambio'>
  ) }
);

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'description'>
  ) }
);

export type DeleteConfigurationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { deleteConfiguration: (
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'value'>
  ) }
);

export type DeleteCrawlerJobMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCrawlerJobMutation = (
  { __typename?: 'Mutation' }
  & { deleteCrawlerJob: (
    { __typename?: 'CrawlerJob' }
    & Pick<CrawlerJob, 'id' | 'providerConfigID' | 'provider' | 'localita' | 'codificaLocalita' | 'categoria' | 'status' | 'customer' | 'scheduledAt' | 'from' | 'to' | 'pubsubbed' | 'pubsubbedAt' | 'startedAt' | 'finishedAt' | 'jobDurationSeconds' | 'resultRows' | 'step' | 'companies' | 'attempts' | 'maxAttempts' | 'useTor'>
  ) }
);

export type DeleteCrawlerResultMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCrawlerResultMutation = (
  { __typename?: 'Mutation' }
  & { deleteCrawlerResult: (
    { __typename?: 'CrawlerResult' }
    & Pick<CrawlerResult, 'id' | 'provider' | 'classe' | 'Posti' | 'Porte' | 'Cambio' | 'Auto' | 'durata_dal' | 'durata_al' | 'durata_gg' | 'prezzo_totale' | 'prezzo_giornaliero' | 'last_update' | 'jobId' | 'luogo' | 'venditore' | 'companies' | 'search_url'>
  ) }
);

export type DeleteCustomerconfigMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCustomerconfigMutation = (
  { __typename?: 'Mutation' }
  & { deleteCustomerconfig: (
    { __typename?: 'Customerconfig' }
    & Pick<Customerconfig, 'id' | 'customer' | 'provider' | 'name'>
  ) }
);

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & { deleteLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'provider' | 'comune' | 'transCodifica'>
  ) }
);

export type DeleteProviderConfigMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteProviderConfigMutation = (
  { __typename?: 'Mutation' }
  & { deleteProviderConfig: (
    { __typename?: 'ProviderConfig' }
    & Pick<ProviderConfig, 'id' | 'provider' | 'localita' | 'steps' | 'weekDaysStart' | 'monthDaysStart' | 'monthToCheck' | 'monthsDuration' | 'categories' | 'scheduledAt' | 'company' | 'lastUpdate' | 'deleted' | 'maxAttempts' | 'useTor' | 'active' | 'createdOn'>
  ) }
);

export type DownloadResultsMutationVariables = Exact<{
  start: Scalars['Time'];
  end: Scalars['Time'];
}>;


export type DownloadResultsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadResults'>
);

export type DownloadResultsForAcrissMutationVariables = Exact<{
  start: Scalars['Time'];
  end: Scalars['Time'];
  luogo: Scalars['String'];
}>;


export type DownloadResultsForAcrissMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadResultsForAcriss'>
);

export type DownloadResultsForIncrementMutationVariables = Exact<{
  start: Scalars['Time'];
  end: Scalars['Time'];
  luogo: Scalars['String'];
}>;


export type DownloadResultsForIncrementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadResultsForIncrement'>
);

export type DuplicateConfigMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DuplicateConfigMutation = (
  { __typename?: 'Mutation' }
  & { duplicateConfig: (
    { __typename?: 'ProviderConfig' }
    & Pick<ProviderConfig, 'id' | 'provider' | 'localita' | 'steps' | 'weekDaysStart' | 'monthDaysStart' | 'monthToCheck' | 'monthsDuration' | 'categories' | 'scheduledAt' | 'company' | 'lastUpdate' | 'deleted' | 'maxAttempts' | 'useTor' | 'active' | 'createdOn'>
  ) }
);

export type GenerateAnalyticsMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateAnalyticsMutation = (
  { __typename?: 'Mutation' }
  & { generateAnalytics: Array<(
    { __typename?: 'Analytic' }
    & Pick<Analytic, 'id' | 'date' | 'source' | 'acriss' | 'group' | 'location' | 'duration' | 'name' | 'price' | 'multipliedPrice' | 'isSelected' | 'isFelirent' | 'url'>
  )> }
);

export type GenerateCrawlerJobsMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateCrawlerJobsMutation = (
  { __typename?: 'Mutation' }
  & { generateCrawlerJobs: Array<(
    { __typename?: 'CrawlerJob' }
    & Pick<CrawlerJob, 'id' | 'providerConfigID' | 'provider' | 'localita' | 'codificaLocalita' | 'categoria' | 'status' | 'customer' | 'scheduledAt' | 'from' | 'to' | 'pubsubbed' | 'pubsubbedAt' | 'startedAt' | 'finishedAt' | 'jobDurationSeconds' | 'resultRows' | 'step' | 'companies' | 'attempts' | 'maxAttempts' | 'useTor'>
  )> }
);

export type ImpersonatesMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ImpersonatesMutation = (
  { __typename?: 'Mutation' }
  & { impersonates: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt' | 'company'>
    ) }
  ) }
);

export type PingMutationMutationVariables = Exact<{ [key: string]: never; }>;


export type PingMutationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pingMutation'>
);

export type RecoverPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RecoverPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'recoverPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt' | 'company'>
    ) }
  ) }
);

export type ResetTableMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetTable'>
);

export type RunJobMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RunJobMutation = (
  { __typename?: 'Mutation' }
  & { runJob: (
    { __typename?: 'CrawlerJob' }
    & Pick<CrawlerJob, 'id' | 'providerConfigID' | 'provider' | 'localita' | 'codificaLocalita' | 'categoria' | 'status' | 'customer' | 'scheduledAt' | 'from' | 'to' | 'pubsubbed' | 'pubsubbedAt' | 'startedAt' | 'finishedAt' | 'jobDurationSeconds' | 'resultRows' | 'step' | 'companies' | 'attempts' | 'maxAttempts' | 'useTor'>
  ) }
);

export type RunJobsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type RunJobsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'runJobs'>
);

export type StartCrawlerMutationVariables = Exact<{ [key: string]: never; }>;


export type StartCrawlerMutation = (
  { __typename?: 'Mutation' }
  & { startCrawler: Array<(
    { __typename?: 'CrawlerResult' }
    & Pick<CrawlerResult, 'id' | 'provider' | 'classe' | 'Posti' | 'Porte' | 'Cambio' | 'Auto' | 'durata_dal' | 'durata_al' | 'durata_gg' | 'prezzo_totale' | 'prezzo_giornaliero' | 'last_update' | 'jobId' | 'luogo' | 'venditore' | 'companies' | 'search_url'>
  )> }
);

export type UpdateAcrissCodeMutationVariables = Exact<{
  id: Scalars['String'];
  form?: Maybe<AcrissCodeUpdateForm>;
}>;


export type UpdateAcrissCodeMutation = (
  { __typename?: 'Mutation' }
  & { updateAcrissCode: (
    { __typename?: 'AcrissCode' }
    & Pick<AcrissCode, 'id' | 'code' | 'gruppo' | 'auto' | 'classe' | 'posti' | 'porte' | 'cambio'>
  ) }
);

export type UpdateAvatarMutationVariables = Exact<{
  email: Scalars['String'];
  avatar: Scalars['String'];
}>;


export type UpdateAvatarMutation = (
  { __typename?: 'Mutation' }
  & { updateAvatar: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt' | 'company'>
  ) }
);

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['String'];
  form?: Maybe<CompanyUpdateForm>;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'description'>
  ) }
);

export type UpdateConfigurationMutationVariables = Exact<{
  id: Scalars['String'];
  form?: Maybe<ConfigurationUpdateForm>;
}>;


export type UpdateConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateConfiguration: (
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'value'>
  ) }
);

export type UpdateCrawlerJobMutationVariables = Exact<{
  id: Scalars['String'];
  form?: Maybe<CrawlerJobUpdateForm>;
}>;


export type UpdateCrawlerJobMutation = (
  { __typename?: 'Mutation' }
  & { updateCrawlerJob: (
    { __typename?: 'CrawlerJob' }
    & Pick<CrawlerJob, 'id' | 'providerConfigID' | 'provider' | 'localita' | 'codificaLocalita' | 'categoria' | 'status' | 'customer' | 'scheduledAt' | 'from' | 'to' | 'pubsubbed' | 'pubsubbedAt' | 'startedAt' | 'finishedAt' | 'jobDurationSeconds' | 'resultRows' | 'step' | 'companies' | 'attempts' | 'maxAttempts' | 'useTor'>
  ) }
);

export type UpdateCrawlerResultMutationVariables = Exact<{
  id: Scalars['String'];
  form?: Maybe<CrawlerResultUpdateForm>;
}>;


export type UpdateCrawlerResultMutation = (
  { __typename?: 'Mutation' }
  & { updateCrawlerResult: (
    { __typename?: 'CrawlerResult' }
    & Pick<CrawlerResult, 'id' | 'provider' | 'classe' | 'Posti' | 'Porte' | 'Cambio' | 'Auto' | 'durata_dal' | 'durata_al' | 'durata_gg' | 'prezzo_totale' | 'prezzo_giornaliero' | 'last_update' | 'jobId' | 'luogo' | 'venditore' | 'companies' | 'search_url'>
  ) }
);

export type UpdateCustomerconfigMutationVariables = Exact<{
  id: Scalars['String'];
  form?: Maybe<CustomerconfigUpdateForm>;
}>;


export type UpdateCustomerconfigMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerconfig: (
    { __typename?: 'Customerconfig' }
    & Pick<Customerconfig, 'id' | 'customer' | 'provider' | 'name'>
  ) }
);

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['String'];
  form?: Maybe<UpdateLocation>;
}>;


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'provider' | 'comune' | 'transCodifica'>
  ) }
);

export type UpdateManyMutationVariables = Exact<{
  input: Array<AnalyticInput> | AnalyticInput;
  acriss: Array<Scalars['String']> | Scalars['String'];
  source: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
}>;


export type UpdateManyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMany'>
);

export type UpdateProviderConfigMutationVariables = Exact<{
  id: Scalars['String'];
  form?: Maybe<ProviderConfigUpdateForm>;
}>;


export type UpdateProviderConfigMutation = (
  { __typename?: 'Mutation' }
  & { updateProviderConfig: (
    { __typename?: 'ProviderConfig' }
    & Pick<ProviderConfig, 'id' | 'provider' | 'localita' | 'steps' | 'weekDaysStart' | 'monthDaysStart' | 'monthToCheck' | 'monthsDuration' | 'categories' | 'scheduledAt' | 'company' | 'lastUpdate' | 'deleted' | 'maxAttempts' | 'useTor' | 'active' | 'createdOn'>
  ) }
);

export type UpdateSelfPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdateSelfPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateSelfPassword: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt' | 'company'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  email: Scalars['String'];
  user?: Maybe<UpdateUserInput>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt' | 'company'>
  ) }
);

export type UpdateUserPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdateUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateUserPassword: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt' | 'company'>
  ) }
);

export type AcrissCodeQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AcrissCodeQuery = (
  { __typename?: 'Query' }
  & { acrissCode: (
    { __typename?: 'AcrissCode' }
    & Pick<AcrissCode, 'id' | 'code' | 'gruppo' | 'auto' | 'classe' | 'posti' | 'porte' | 'cambio'>
  ) }
);

export type AcrissCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type AcrissCodesQuery = (
  { __typename?: 'Query' }
  & { acrissCodes?: Maybe<Array<Maybe<(
    { __typename?: 'AcrissCode' }
    & Pick<AcrissCode, 'id' | 'code' | 'gruppo' | 'auto' | 'classe' | 'posti' | 'porte' | 'cambio'>
  )>>> }
);

export type AnalyticsQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsQuery = (
  { __typename?: 'Query' }
  & { analytics: Array<(
    { __typename?: 'Analytic' }
    & Pick<Analytic, 'id' | 'date' | 'source' | 'acriss' | 'group' | 'location' | 'duration' | 'name' | 'price' | 'multipliedPrice' | 'isSelected' | 'isFelirent' | 'url'>
  )> }
);

export type AnalyticsByDateQueryVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['Int'];
  source?: Maybe<Scalars['String']>;
}>;


export type AnalyticsByDateQuery = (
  { __typename?: 'Query' }
  & { analyticsByDate: Array<(
    { __typename?: 'Analytic' }
    & Pick<Analytic, 'id' | 'date' | 'source' | 'acriss' | 'group' | 'location' | 'duration' | 'name' | 'price' | 'multipliedPrice' | 'isSelected' | 'isFelirent' | 'url'>
  )> }
);

export type AnalyticsSourceOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsSourceOptionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'analyticsSourceOptions'>
);

export type BasicLoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type BasicLoginQuery = (
  { __typename?: 'Query' }
  & { basicLogin: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt' | 'company'>
    ) }
  ) }
);

export type CompanyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CompanyQuery = (
  { __typename?: 'Query' }
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'description'>
  ) }
);

export type CompanysQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanysQuery = (
  { __typename?: 'Query' }
  & { companys?: Maybe<Array<Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'description'>
  )>>> }
);

export type ConfigurationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ConfigurationQuery = (
  { __typename?: 'Query' }
  & { configuration: (
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'value'>
  ) }
);

export type ConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfigurationsQuery = (
  { __typename?: 'Query' }
  & { configurations?: Maybe<Array<Maybe<(
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'value'>
  )>>> }
);

export type CrawlerJobQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CrawlerJobQuery = (
  { __typename?: 'Query' }
  & { crawlerJob: (
    { __typename?: 'CrawlerJob' }
    & Pick<CrawlerJob, 'id' | 'providerConfigID' | 'provider' | 'localita' | 'codificaLocalita' | 'categoria' | 'status' | 'customer' | 'scheduledAt' | 'from' | 'to' | 'pubsubbed' | 'pubsubbedAt' | 'startedAt' | 'finishedAt' | 'jobDurationSeconds' | 'resultRows' | 'step' | 'companies' | 'attempts' | 'maxAttempts' | 'useTor'>
  ) }
);

export type CrawlerJobsQueryVariables = Exact<{
  filter?: Maybe<DateFilter>;
}>;


export type CrawlerJobsQuery = (
  { __typename?: 'Query' }
  & { crawlerJobs?: Maybe<Array<Maybe<(
    { __typename?: 'CrawlerJob' }
    & Pick<CrawlerJob, 'id' | 'providerConfigID' | 'provider' | 'localita' | 'codificaLocalita' | 'categoria' | 'status' | 'customer' | 'scheduledAt' | 'from' | 'to' | 'pubsubbed' | 'pubsubbedAt' | 'startedAt' | 'finishedAt' | 'jobDurationSeconds' | 'resultRows' | 'step' | 'companies' | 'attempts' | 'maxAttempts' | 'useTor'>
  )>>> }
);

export type CrawlerJobsToRunQueryVariables = Exact<{ [key: string]: never; }>;


export type CrawlerJobsToRunQuery = (
  { __typename?: 'Query' }
  & { crawlerJobsToRun?: Maybe<Array<Maybe<(
    { __typename?: 'CrawlerJob' }
    & Pick<CrawlerJob, 'id' | 'providerConfigID' | 'provider' | 'localita' | 'codificaLocalita' | 'categoria' | 'status' | 'customer' | 'scheduledAt' | 'from' | 'to' | 'pubsubbed' | 'pubsubbedAt' | 'startedAt' | 'finishedAt' | 'jobDurationSeconds' | 'resultRows' | 'step' | 'companies' | 'attempts' | 'maxAttempts' | 'useTor'>
  )>>> }
);

export type CrawlerResultQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CrawlerResultQuery = (
  { __typename?: 'Query' }
  & { crawlerResult: (
    { __typename?: 'CrawlerResult' }
    & Pick<CrawlerResult, 'id' | 'provider' | 'classe' | 'Posti' | 'Porte' | 'Cambio' | 'Auto' | 'durata_dal' | 'durata_al' | 'durata_gg' | 'prezzo_totale' | 'prezzo_giornaliero' | 'last_update' | 'jobId' | 'luogo' | 'venditore' | 'companies' | 'search_url'>
  ) }
);

export type CrawlerResultsQueryVariables = Exact<{
  filter?: Maybe<DateFilter>;
}>;


export type CrawlerResultsQuery = (
  { __typename?: 'Query' }
  & { crawlerResults?: Maybe<Array<Maybe<(
    { __typename?: 'CrawlerResult' }
    & Pick<CrawlerResult, 'id' | 'provider' | 'classe' | 'Posti' | 'Porte' | 'Cambio' | 'Auto' | 'durata_dal' | 'durata_al' | 'durata_gg' | 'prezzo_totale' | 'prezzo_giornaliero' | 'last_update' | 'jobId' | 'luogo' | 'venditore' | 'companies' | 'search_url'>
  )>>> }
);

export type CustomerconfigQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CustomerconfigQuery = (
  { __typename?: 'Query' }
  & { customerconfig: (
    { __typename?: 'Customerconfig' }
    & Pick<Customerconfig, 'id' | 'customer' | 'provider' | 'name'>
  ) }
);

export type CustomerconfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerconfigsQuery = (
  { __typename?: 'Query' }
  & { customerconfigs?: Maybe<Array<Maybe<(
    { __typename?: 'Customerconfig' }
    & Pick<Customerconfig, 'id' | 'customer' | 'provider' | 'name'>
  )>>> }
);

export type GetAnalyticExcelQueryVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['Int'];
  mul: Scalars['Float'];
  mul21: Scalars['Float'];
  mul25: Scalars['Float'];
  source?: Maybe<Scalars['String']>;
  selection?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetAnalyticExcelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAnalyticExcel'>
);

export type GetDashboardInfoQueryVariables = Exact<{
  filter?: Maybe<DateFilter>;
}>;


export type GetDashboardInfoQuery = (
  { __typename?: 'Query' }
  & { getDashboardInfo?: Maybe<(
    { __typename?: 'DashboardInfo' }
    & Pick<DashboardInfo, 'numCrawlerResults' | 'numProviderConfigs' | 'numLocations' | 'captchaCredit'>
    & { jobsInfo: (
      { __typename?: 'Jobs' }
      & Pick<Jobs, 'jobsPianificati' | 'jobsDaEseguire' | 'jobsInCorso' | 'jobsEseguito' | 'jobsFalliti'>
    ), risultatiPerProvider: Array<Maybe<(
      { __typename?: 'RisultatiPerProvider' }
      & Pick<RisultatiPerProvider, 'provider' | 'count'>
    )>>, risultatiPerCategoria: Array<Maybe<(
      { __typename?: 'RisultatoCategoria' }
      & Pick<RisultatoCategoria, 'categoria' | 'quanti'>
    )>>, risultatiPerProviderPerCategoria: Array<Maybe<(
      { __typename?: 'RisultatoProviderCategoria' }
      & Pick<RisultatoProviderCategoria, 'provider'>
      & { risultatiPerCategoria: Array<Maybe<(
        { __typename?: 'RisultatoCategoria' }
        & Pick<RisultatoCategoria, 'categoria' | 'quanti'>
      )>> }
    )>> }
  )> }
);

export type GetGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsQuery = (
  { __typename?: 'Query' }
  & { getGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'subject' | 'role' | 'domain'>
  )>>> }
);

export type GetLocationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetLocationQuery = (
  { __typename?: 'Query' }
  & { getLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'provider' | 'comune' | 'transCodifica'>
  ) }
);

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = (
  { __typename?: 'Query' }
  & { getLocations?: Maybe<Array<Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'provider' | 'comune' | 'transCodifica'>
  )>>> }
);

export type GetNewAccessTokenQueryVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type GetNewAccessTokenQuery = (
  { __typename?: 'Query' }
  & { getNewAccessToken: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt' | 'company'>
    ) }
  ) }
);

export type GetPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPoliciesQuery = (
  { __typename?: 'Query' }
  & { getPolicies?: Maybe<Array<Maybe<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'subject' | 'domain' | 'object' | 'action'>
  )>>> }
);

export type GetProviderLocationsQueryVariables = Exact<{
  provider: Scalars['String'];
}>;


export type GetProviderLocationsQuery = (
  { __typename?: 'Query' }
  & { getProviderLocations: Array<Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'provider' | 'comune' | 'transCodifica'>
  )>> }
);

export type GetRemoteResultsQueryVariables = Exact<{
  input: TableInput;
}>;


export type GetRemoteResultsQuery = (
  { __typename?: 'Query' }
  & { getRemoteResults: (
    { __typename?: 'CrawlerResultsTable' }
    & Pick<CrawlerResultsTable, 'pages' | 'count'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'CrawlerResult' }
      & Pick<CrawlerResult, 'id' | 'provider' | 'classe' | 'Posti' | 'Porte' | 'Cambio' | 'Auto' | 'durata_dal' | 'durata_al' | 'durata_gg' | 'prezzo_totale' | 'prezzo_giornaliero' | 'last_update' | 'jobId' | 'luogo' | 'venditore' | 'companies' | 'search_url'>
    )>>>, options?: Maybe<Array<Maybe<(
      { __typename?: 'TableSelectOptions' }
      & Pick<TableSelectOptions, 'column' | 'options'>
    )>>>, op?: Maybe<Array<Maybe<(
      { __typename?: 'TableOperation' }
      & Pick<TableOperation, 'id' | 'result' | 'type'>
    )>>> }
  ) }
);

export type LoggedUserQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedUserQuery = (
  { __typename?: 'Query' }
  & { loggedUser: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt' | 'company'>
  ) }
);

export type PingQueryVariables = Exact<{ [key: string]: never; }>;


export type PingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ping'>
);

export type ProviderConfigQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProviderConfigQuery = (
  { __typename?: 'Query' }
  & { providerConfig: (
    { __typename?: 'ProviderConfig' }
    & Pick<ProviderConfig, 'id' | 'provider' | 'localita' | 'steps' | 'weekDaysStart' | 'monthDaysStart' | 'monthToCheck' | 'monthsDuration' | 'categories' | 'scheduledAt' | 'company' | 'lastUpdate' | 'deleted' | 'maxAttempts' | 'useTor' | 'active' | 'createdOn'>
  ) }
);

export type ProviderConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProviderConfigsQuery = (
  { __typename?: 'Query' }
  & { providerConfigs?: Maybe<Array<Maybe<(
    { __typename?: 'ProviderConfig' }
    & Pick<ProviderConfig, 'id' | 'provider' | 'localita' | 'steps' | 'weekDaysStart' | 'monthDaysStart' | 'monthToCheck' | 'monthsDuration' | 'categories' | 'scheduledAt' | 'company' | 'lastUpdate' | 'deleted' | 'maxAttempts' | 'useTor' | 'active' | 'createdOn'>
  )>>> }
);

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles?: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'name' | 'description'>
  )>>> }
);

export type UserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt' | 'company'>
  ) }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt' | 'company'>
  )>>> }
);


export const AddGroupDocument = gql`
    mutation AddGroup($subject: String!, $role: String!) {
  AddGroup(subject: $subject, role: $role) {
    subject
    role
    domain
  }
}
    `;
export const AddPolicyDocument = gql`
    mutation AddPolicy($subject: String!, $object: String!, $action: String!) {
  AddPolicy(subject: $subject, object: $object, action: $action) {
    subject
    domain
    object
    action
  }
}
    `;
export const AddAnalyticDocument = gql`
    mutation addAnalytic($date: String!, $source: String!, $acriss: String!, $location: String!, $duration: Int!, $name: String!, $price: Float!, $multipliedPrice: Float!, $isSelected: Boolean!, $isFelirent: Boolean!, $url: String) {
  addAnalytic(
    date: $date
    source: $source
    acriss: $acriss
    location: $location
    duration: $duration
    name: $name
    price: $price
    multipliedPrice: $multipliedPrice
    isSelected: $isSelected
    isFelirent: $isFelirent
    url: $url
  ) {
    id
    date
    source
    acriss
    group
    location
    duration
    name
    price
    multipliedPrice
    isSelected
    isFelirent
    url
  }
}
    `;
export const CalculateJobNumberDocument = gql`
    mutation calculateJobNumber($form: ProviderConfigInputForm) {
  calculateJobNumber(form: $form)
}
    `;
export const ChangeActiveDocument = gql`
    mutation changeActive($id: String!, $active: Boolean!) {
  changeActive(id: $id, active: $active) {
    id
    provider
    localita
    steps
    weekDaysStart
    monthDaysStart
    monthToCheck
    monthsDuration
    categories
    scheduledAt
    company
    lastUpdate
    deleted
    maxAttempts
    useTor
    active
    createdOn
  }
}
    `;
export const CreateAcrissCodeDocument = gql`
    mutation createAcrissCode($form: AcrissCodeInputForm) {
  createAcrissCode(form: $form) {
    id
    code
    gruppo
    auto
    classe
    posti
    porte
    cambio
  }
}
    `;
export const CreateCompanyDocument = gql`
    mutation createCompany($form: CompanyInputForm) {
  createCompany(form: $form) {
    id
    description
  }
}
    `;
export const CreateConfigurationDocument = gql`
    mutation createConfiguration($form: ConfigurationInputForm) {
  createConfiguration(form: $form) {
    id
    value
  }
}
    `;
export const CreateCrawlerJobDocument = gql`
    mutation createCrawlerJob($form: CrawlerJobInputForm) {
  createCrawlerJob(form: $form) {
    id
    providerConfigID
    provider
    localita
    codificaLocalita
    categoria
    status
    customer
    scheduledAt
    from
    to
    pubsubbed
    pubsubbedAt
    startedAt
    finishedAt
    jobDurationSeconds
    resultRows
    step
    companies
    attempts
    maxAttempts
    useTor
  }
}
    `;
export const CreateCrawlerResultDocument = gql`
    mutation createCrawlerResult($form: CrawlerResultInputForm) {
  createCrawlerResult(form: $form) {
    id
    provider
    classe
    Posti
    Porte
    Cambio
    Auto
    durata_dal
    durata_al
    durata_gg
    prezzo_totale
    prezzo_giornaliero
    last_update
    jobId
    luogo
    venditore
    companies
    search_url
  }
}
    `;
export const CreateCustomerconfigDocument = gql`
    mutation createCustomerconfig($form: CustomerconfigInputForm) {
  createCustomerconfig(form: $form) {
    id
    customer
    provider
    name
  }
}
    `;
export const CreateLocationDocument = gql`
    mutation createLocation($form: NewLocation) {
  createLocation(form: $form) {
    id
    provider
    comune
    transCodifica
  }
}
    `;
export const CreateProviderConfigDocument = gql`
    mutation createProviderConfig($form: ProviderConfigInputForm) {
  createProviderConfig(form: $form) {
    id
    provider
    localita
    steps
    weekDaysStart
    monthDaysStart
    monthToCheck
    monthsDuration
    categories
    scheduledAt
    company
    lastUpdate
    deleted
    maxAttempts
    useTor
    active
    createdOn
  }
}
    `;
export const CreateRoleDocument = gql`
    mutation createRole($name: String!, $description: String!) {
  createRole(name: $name, description: $description) {
    name
    description
  }
}
    `;
export const CreateUserDocument = gql`
    mutation createUser($user: UserInputWithPassword) {
  createUser(user: $user) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
      company
    }
    permissions
  }
}
    `;
export const DeleteAcrissCodeDocument = gql`
    mutation deleteAcrissCode($id: String!) {
  deleteAcrissCode(id: $id) {
    id
    code
    gruppo
    auto
    classe
    posti
    porte
    cambio
  }
}
    `;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: String!) {
  deleteCompany(id: $id) {
    id
    description
  }
}
    `;
export const DeleteConfigurationDocument = gql`
    mutation deleteConfiguration($id: String!) {
  deleteConfiguration(id: $id) {
    id
    value
  }
}
    `;
export const DeleteCrawlerJobDocument = gql`
    mutation deleteCrawlerJob($id: String!) {
  deleteCrawlerJob(id: $id) {
    id
    providerConfigID
    provider
    localita
    codificaLocalita
    categoria
    status
    customer
    scheduledAt
    from
    to
    pubsubbed
    pubsubbedAt
    startedAt
    finishedAt
    jobDurationSeconds
    resultRows
    step
    companies
    attempts
    maxAttempts
    useTor
  }
}
    `;
export const DeleteCrawlerResultDocument = gql`
    mutation deleteCrawlerResult($id: String!) {
  deleteCrawlerResult(id: $id) {
    id
    provider
    classe
    Posti
    Porte
    Cambio
    Auto
    durata_dal
    durata_al
    durata_gg
    prezzo_totale
    prezzo_giornaliero
    last_update
    jobId
    luogo
    venditore
    companies
    search_url
  }
}
    `;
export const DeleteCustomerconfigDocument = gql`
    mutation deleteCustomerconfig($id: String!) {
  deleteCustomerconfig(id: $id) {
    id
    customer
    provider
    name
  }
}
    `;
export const DeleteLocationDocument = gql`
    mutation deleteLocation($id: String!) {
  deleteLocation(id: $id) {
    id
    provider
    comune
    transCodifica
  }
}
    `;
export const DeleteProviderConfigDocument = gql`
    mutation deleteProviderConfig($id: String!) {
  deleteProviderConfig(id: $id) {
    id
    provider
    localita
    steps
    weekDaysStart
    monthDaysStart
    monthToCheck
    monthsDuration
    categories
    scheduledAt
    company
    lastUpdate
    deleted
    maxAttempts
    useTor
    active
    createdOn
  }
}
    `;
export const DownloadResultsDocument = gql`
    mutation downloadResults($start: Time!, $end: Time!) {
  downloadResults(start: $start, end: $end)
}
    `;
export const DownloadResultsForAcrissDocument = gql`
    mutation downloadResultsForAcriss($start: Time!, $end: Time!, $luogo: String!) {
  downloadResultsForAcriss(start: $start, end: $end, luogo: $luogo)
}
    `;
export const DownloadResultsForIncrementDocument = gql`
    mutation downloadResultsForIncrement($start: Time!, $end: Time!, $luogo: String!) {
  downloadResultsForIncrement(start: $start, end: $end, luogo: $luogo)
}
    `;
export const DuplicateConfigDocument = gql`
    mutation duplicateConfig($id: String!) {
  duplicateConfig(id: $id) {
    id
    provider
    localita
    steps
    weekDaysStart
    monthDaysStart
    monthToCheck
    monthsDuration
    categories
    scheduledAt
    company
    lastUpdate
    deleted
    maxAttempts
    useTor
    active
    createdOn
  }
}
    `;
export const GenerateAnalyticsDocument = gql`
    mutation generateAnalytics {
  generateAnalytics {
    id
    date
    source
    acriss
    group
    location
    duration
    name
    price
    multipliedPrice
    isSelected
    isFelirent
    url
  }
}
    `;
export const GenerateCrawlerJobsDocument = gql`
    mutation generateCrawlerJobs {
  generateCrawlerJobs {
    id
    providerConfigID
    provider
    localita
    codificaLocalita
    categoria
    status
    customer
    scheduledAt
    from
    to
    pubsubbed
    pubsubbedAt
    startedAt
    finishedAt
    jobDurationSeconds
    resultRows
    step
    companies
    attempts
    maxAttempts
    useTor
  }
}
    `;
export const ImpersonatesDocument = gql`
    mutation impersonates($email: String!) {
  impersonates(email: $email) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
      company
    }
    permissions
  }
}
    `;
export const PingMutationDocument = gql`
    mutation pingMutation {
  pingMutation
}
    `;
export const RecoverPasswordDocument = gql`
    mutation recoverPassword($email: String!) {
  recoverPassword(email: $email)
}
    `;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!, $code: String!, $newPassword: String!) {
  resetPassword(email: $email, code: $code, newPassword: $newPassword) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
      company
    }
    permissions
  }
}
    `;
export const ResetTableDocument = gql`
    mutation resetTable {
  resetTable
}
    `;
export const RunJobDocument = gql`
    mutation runJob($id: String!) {
  runJob(id: $id) {
    id
    providerConfigID
    provider
    localita
    codificaLocalita
    categoria
    status
    customer
    scheduledAt
    from
    to
    pubsubbed
    pubsubbedAt
    startedAt
    finishedAt
    jobDurationSeconds
    resultRows
    step
    companies
    attempts
    maxAttempts
    useTor
  }
}
    `;
export const RunJobsDocument = gql`
    mutation runJobs($ids: [String!]!) {
  runJobs(ids: $ids)
}
    `;
export const StartCrawlerDocument = gql`
    mutation startCrawler {
  startCrawler {
    id
    provider
    classe
    Posti
    Porte
    Cambio
    Auto
    durata_dal
    durata_al
    durata_gg
    prezzo_totale
    prezzo_giornaliero
    last_update
    jobId
    luogo
    venditore
    companies
    search_url
  }
}
    `;
export const UpdateAcrissCodeDocument = gql`
    mutation updateAcrissCode($id: String!, $form: AcrissCodeUpdateForm) {
  updateAcrissCode(id: $id, form: $form) {
    id
    code
    gruppo
    auto
    classe
    posti
    porte
    cambio
  }
}
    `;
export const UpdateAvatarDocument = gql`
    mutation updateAvatar($email: String!, $avatar: String!) {
  updateAvatar(email: $email, avatar: $avatar) {
    firstName
    lastName
    email
    avatar
    createdAt
    company
  }
}
    `;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($id: String!, $form: CompanyUpdateForm) {
  updateCompany(id: $id, form: $form) {
    id
    description
  }
}
    `;
export const UpdateConfigurationDocument = gql`
    mutation updateConfiguration($id: String!, $form: ConfigurationUpdateForm) {
  updateConfiguration(id: $id, form: $form) {
    id
    value
  }
}
    `;
export const UpdateCrawlerJobDocument = gql`
    mutation updateCrawlerJob($id: String!, $form: CrawlerJobUpdateForm) {
  updateCrawlerJob(id: $id, form: $form) {
    id
    providerConfigID
    provider
    localita
    codificaLocalita
    categoria
    status
    customer
    scheduledAt
    from
    to
    pubsubbed
    pubsubbedAt
    startedAt
    finishedAt
    jobDurationSeconds
    resultRows
    step
    companies
    attempts
    maxAttempts
    useTor
  }
}
    `;
export const UpdateCrawlerResultDocument = gql`
    mutation updateCrawlerResult($id: String!, $form: CrawlerResultUpdateForm) {
  updateCrawlerResult(id: $id, form: $form) {
    id
    provider
    classe
    Posti
    Porte
    Cambio
    Auto
    durata_dal
    durata_al
    durata_gg
    prezzo_totale
    prezzo_giornaliero
    last_update
    jobId
    luogo
    venditore
    companies
    search_url
  }
}
    `;
export const UpdateCustomerconfigDocument = gql`
    mutation updateCustomerconfig($id: String!, $form: CustomerconfigUpdateForm) {
  updateCustomerconfig(id: $id, form: $form) {
    id
    customer
    provider
    name
  }
}
    `;
export const UpdateLocationDocument = gql`
    mutation updateLocation($id: String!, $form: UpdateLocation) {
  updateLocation(id: $id, form: $form) {
    id
    provider
    comune
    transCodifica
  }
}
    `;
export const UpdateManyDocument = gql`
    mutation updateMany($input: [AnalyticInput!]!, $acriss: [String!]!, $source: String!, $duration: Int, $date: String) {
  updateMany(
    input: $input
    acriss: $acriss
    source: $source
    duration: $duration
    date: $date
  )
}
    `;
export const UpdateProviderConfigDocument = gql`
    mutation updateProviderConfig($id: String!, $form: ProviderConfigUpdateForm) {
  updateProviderConfig(id: $id, form: $form) {
    id
    provider
    localita
    steps
    weekDaysStart
    monthDaysStart
    monthToCheck
    monthsDuration
    categories
    scheduledAt
    company
    lastUpdate
    deleted
    maxAttempts
    useTor
    active
    createdOn
  }
}
    `;
export const UpdateSelfPasswordDocument = gql`
    mutation updateSelfPassword($email: String!, $oldPassword: String!, $newPassword: String!) {
  updateSelfPassword(
    email: $email
    oldPassword: $oldPassword
    newPassword: $newPassword
  ) {
    firstName
    lastName
    email
    avatar
    createdAt
    company
  }
}
    `;
export const UpdateUserDocument = gql`
    mutation updateUser($email: String!, $user: UpdateUserInput) {
  updateUser(email: $email, user: $user) {
    firstName
    lastName
    email
    avatar
    createdAt
    company
  }
}
    `;
export const UpdateUserPasswordDocument = gql`
    mutation updateUserPassword($email: String!, $newPassword: String!) {
  updateUserPassword(email: $email, newPassword: $newPassword) {
    firstName
    lastName
    email
    avatar
    createdAt
    company
  }
}
    `;
export const AcrissCodeDocument = gql`
    query acrissCode($id: String!) {
  acrissCode(id: $id) {
    id
    code
    gruppo
    auto
    classe
    posti
    porte
    cambio
  }
}
    `;
export const AcrissCodesDocument = gql`
    query acrissCodes {
  acrissCodes {
    id
    code
    gruppo
    auto
    classe
    posti
    porte
    cambio
  }
}
    `;
export const AnalyticsDocument = gql`
    query analytics {
  analytics {
    id
    date
    source
    acriss
    group
    location
    duration
    name
    price
    multipliedPrice
    isSelected
    isFelirent
    url
  }
}
    `;
export const AnalyticsByDateDocument = gql`
    query analyticsByDate($month: Int!, $year: Int!, $source: String) {
  analyticsByDate(month: $month, year: $year, source: $source) {
    id
    date
    source
    acriss
    group
    location
    duration
    name
    price
    multipliedPrice
    isSelected
    isFelirent
    url
  }
}
    `;
export const AnalyticsSourceOptionsDocument = gql`
    query analyticsSourceOptions {
  analyticsSourceOptions
}
    `;
export const BasicLoginDocument = gql`
    query basicLogin($username: String!, $password: String!) {
  basicLogin(username: $username, password: $password) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
      company
    }
    permissions
  }
}
    `;
export const CompanyDocument = gql`
    query company($id: String!) {
  company(id: $id) {
    id
    description
  }
}
    `;
export const CompanysDocument = gql`
    query companys {
  companys {
    id
    description
  }
}
    `;
export const ConfigurationDocument = gql`
    query configuration($id: String!) {
  configuration(id: $id) {
    id
    value
  }
}
    `;
export const ConfigurationsDocument = gql`
    query configurations {
  configurations {
    id
    value
  }
}
    `;
export const CrawlerJobDocument = gql`
    query crawlerJob($id: String!) {
  crawlerJob(id: $id) {
    id
    providerConfigID
    provider
    localita
    codificaLocalita
    categoria
    status
    customer
    scheduledAt
    from
    to
    pubsubbed
    pubsubbedAt
    startedAt
    finishedAt
    jobDurationSeconds
    resultRows
    step
    companies
    attempts
    maxAttempts
    useTor
  }
}
    `;
export const CrawlerJobsDocument = gql`
    query crawlerJobs($filter: DateFilter) {
  crawlerJobs(filter: $filter) {
    id
    providerConfigID
    provider
    localita
    codificaLocalita
    categoria
    status
    customer
    scheduledAt
    from
    to
    pubsubbed
    pubsubbedAt
    startedAt
    finishedAt
    jobDurationSeconds
    resultRows
    step
    companies
    attempts
    maxAttempts
    useTor
  }
}
    `;
export const CrawlerJobsToRunDocument = gql`
    query crawlerJobsToRun {
  crawlerJobsToRun {
    id
    providerConfigID
    provider
    localita
    codificaLocalita
    categoria
    status
    customer
    scheduledAt
    from
    to
    pubsubbed
    pubsubbedAt
    startedAt
    finishedAt
    jobDurationSeconds
    resultRows
    step
    companies
    attempts
    maxAttempts
    useTor
  }
}
    `;
export const CrawlerResultDocument = gql`
    query crawlerResult($id: String!) {
  crawlerResult(id: $id) {
    id
    provider
    classe
    Posti
    Porte
    Cambio
    Auto
    durata_dal
    durata_al
    durata_gg
    prezzo_totale
    prezzo_giornaliero
    last_update
    jobId
    luogo
    venditore
    companies
    search_url
  }
}
    `;
export const CrawlerResultsDocument = gql`
    query crawlerResults($filter: DateFilter) {
  crawlerResults(filter: $filter) {
    id
    provider
    classe
    Posti
    Porte
    Cambio
    Auto
    durata_dal
    durata_al
    durata_gg
    prezzo_totale
    prezzo_giornaliero
    last_update
    jobId
    luogo
    venditore
    companies
    search_url
  }
}
    `;
export const CustomerconfigDocument = gql`
    query customerconfig($id: String!) {
  customerconfig(id: $id) {
    id
    customer
    provider
    name
  }
}
    `;
export const CustomerconfigsDocument = gql`
    query customerconfigs {
  customerconfigs {
    id
    customer
    provider
    name
  }
}
    `;
export const GetAnalyticExcelDocument = gql`
    query getAnalyticExcel($month: Int!, $year: Int!, $mul: Float!, $mul21: Float!, $mul25: Float!, $source: String, $selection: [String!]) {
  getAnalyticExcel(
    month: $month
    year: $year
    mul: $mul
    mul21: $mul21
    mul25: $mul25
    source: $source
    selection: $selection
  )
}
    `;
export const GetDashboardInfoDocument = gql`
    query getDashboardInfo($filter: DateFilter) {
  getDashboardInfo(filter: $filter) {
    numCrawlerResults
    numProviderConfigs
    numLocations
    jobsInfo {
      jobsPianificati
      jobsDaEseguire
      jobsInCorso
      jobsEseguito
      jobsFalliti
    }
    risultatiPerProvider {
      provider
      count
    }
    risultatiPerCategoria {
      categoria
      quanti
    }
    risultatiPerProviderPerCategoria {
      provider
      risultatiPerCategoria {
        categoria
        quanti
      }
    }
    captchaCredit
  }
}
    `;
export const GetGroupsDocument = gql`
    query getGroups {
  getGroups {
    subject
    role
    domain
  }
}
    `;
export const GetLocationDocument = gql`
    query getLocation($id: String!) {
  getLocation(id: $id) {
    id
    provider
    comune
    transCodifica
  }
}
    `;
export const GetLocationsDocument = gql`
    query getLocations {
  getLocations {
    id
    provider
    comune
    transCodifica
  }
}
    `;
export const GetNewAccessTokenDocument = gql`
    query getNewAccessToken($refreshToken: String!) {
  getNewAccessToken(refreshToken: $refreshToken) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
      company
    }
    permissions
  }
}
    `;
export const GetPoliciesDocument = gql`
    query getPolicies {
  getPolicies {
    subject
    domain
    object
    action
  }
}
    `;
export const GetProviderLocationsDocument = gql`
    query getProviderLocations($provider: String!) {
  getProviderLocations(provider: $provider) {
    id
    provider
    comune
    transCodifica
  }
}
    `;
export const GetRemoteResultsDocument = gql`
    query getRemoteResults($input: TableInput!) {
  getRemoteResults(input: $input) {
    pages
    count
    items {
      id
      provider
      classe
      Posti
      Porte
      Cambio
      Auto
      durata_dal
      durata_al
      durata_gg
      prezzo_totale
      prezzo_giornaliero
      last_update
      jobId
      luogo
      venditore
      companies
      search_url
    }
    options {
      column
      options
    }
    op {
      id
      result
      type
    }
  }
}
    `;
export const LoggedUserDocument = gql`
    query loggedUser {
  loggedUser {
    firstName
    lastName
    email
    avatar
    createdAt
    company
  }
}
    `;
export const PingDocument = gql`
    query ping {
  ping
}
    `;
export const ProviderConfigDocument = gql`
    query providerConfig($id: String!) {
  providerConfig(id: $id) {
    id
    provider
    localita
    steps
    weekDaysStart
    monthDaysStart
    monthToCheck
    monthsDuration
    categories
    scheduledAt
    company
    lastUpdate
    deleted
    maxAttempts
    useTor
    active
    createdOn
  }
}
    `;
export const ProviderConfigsDocument = gql`
    query providerConfigs {
  providerConfigs {
    id
    provider
    localita
    steps
    weekDaysStart
    monthDaysStart
    monthToCheck
    monthsDuration
    categories
    scheduledAt
    company
    lastUpdate
    deleted
    maxAttempts
    useTor
    active
    createdOn
  }
}
    `;
export const RolesDocument = gql`
    query roles {
  roles {
    name
    description
  }
}
    `;
export const UserDocument = gql`
    query user($email: String!) {
  user(email: $email) {
    firstName
    lastName
    email
    avatar
    createdAt
    company
  }
}
    `;
export const UsersDocument = gql`
    query users {
  users {
    firstName
    lastName
    email
    avatar
    createdAt
    company
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    AddGroup(variables: AddGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddGroupMutation> {
      return withWrapper(() => client.request<AddGroupMutation>(AddGroupDocument, variables, requestHeaders));
    },
    AddPolicy(variables: AddPolicyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddPolicyMutation> {
      return withWrapper(() => client.request<AddPolicyMutation>(AddPolicyDocument, variables, requestHeaders));
    },
    addAnalytic(variables: AddAnalyticMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddAnalyticMutation> {
      return withWrapper(() => client.request<AddAnalyticMutation>(AddAnalyticDocument, variables, requestHeaders));
    },
    calculateJobNumber(variables?: CalculateJobNumberMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CalculateJobNumberMutation> {
      return withWrapper(() => client.request<CalculateJobNumberMutation>(CalculateJobNumberDocument, variables, requestHeaders));
    },
    changeActive(variables: ChangeActiveMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ChangeActiveMutation> {
      return withWrapper(() => client.request<ChangeActiveMutation>(ChangeActiveDocument, variables, requestHeaders));
    },
    createAcrissCode(variables?: CreateAcrissCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateAcrissCodeMutation> {
      return withWrapper(() => client.request<CreateAcrissCodeMutation>(CreateAcrissCodeDocument, variables, requestHeaders));
    },
    createCompany(variables?: CreateCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCompanyMutation> {
      return withWrapper(() => client.request<CreateCompanyMutation>(CreateCompanyDocument, variables, requestHeaders));
    },
    createConfiguration(variables?: CreateConfigurationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateConfigurationMutation> {
      return withWrapper(() => client.request<CreateConfigurationMutation>(CreateConfigurationDocument, variables, requestHeaders));
    },
    createCrawlerJob(variables?: CreateCrawlerJobMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCrawlerJobMutation> {
      return withWrapper(() => client.request<CreateCrawlerJobMutation>(CreateCrawlerJobDocument, variables, requestHeaders));
    },
    createCrawlerResult(variables?: CreateCrawlerResultMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCrawlerResultMutation> {
      return withWrapper(() => client.request<CreateCrawlerResultMutation>(CreateCrawlerResultDocument, variables, requestHeaders));
    },
    createCustomerconfig(variables?: CreateCustomerconfigMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCustomerconfigMutation> {
      return withWrapper(() => client.request<CreateCustomerconfigMutation>(CreateCustomerconfigDocument, variables, requestHeaders));
    },
    createLocation(variables?: CreateLocationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateLocationMutation> {
      return withWrapper(() => client.request<CreateLocationMutation>(CreateLocationDocument, variables, requestHeaders));
    },
    createProviderConfig(variables?: CreateProviderConfigMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateProviderConfigMutation> {
      return withWrapper(() => client.request<CreateProviderConfigMutation>(CreateProviderConfigDocument, variables, requestHeaders));
    },
    createRole(variables: CreateRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateRoleMutation> {
      return withWrapper(() => client.request<CreateRoleMutation>(CreateRoleDocument, variables, requestHeaders));
    },
    createUser(variables?: CreateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateUserMutation> {
      return withWrapper(() => client.request<CreateUserMutation>(CreateUserDocument, variables, requestHeaders));
    },
    deleteAcrissCode(variables: DeleteAcrissCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteAcrissCodeMutation> {
      return withWrapper(() => client.request<DeleteAcrissCodeMutation>(DeleteAcrissCodeDocument, variables, requestHeaders));
    },
    deleteCompany(variables: DeleteCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCompanyMutation> {
      return withWrapper(() => client.request<DeleteCompanyMutation>(DeleteCompanyDocument, variables, requestHeaders));
    },
    deleteConfiguration(variables: DeleteConfigurationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteConfigurationMutation> {
      return withWrapper(() => client.request<DeleteConfigurationMutation>(DeleteConfigurationDocument, variables, requestHeaders));
    },
    deleteCrawlerJob(variables: DeleteCrawlerJobMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCrawlerJobMutation> {
      return withWrapper(() => client.request<DeleteCrawlerJobMutation>(DeleteCrawlerJobDocument, variables, requestHeaders));
    },
    deleteCrawlerResult(variables: DeleteCrawlerResultMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCrawlerResultMutation> {
      return withWrapper(() => client.request<DeleteCrawlerResultMutation>(DeleteCrawlerResultDocument, variables, requestHeaders));
    },
    deleteCustomerconfig(variables: DeleteCustomerconfigMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCustomerconfigMutation> {
      return withWrapper(() => client.request<DeleteCustomerconfigMutation>(DeleteCustomerconfigDocument, variables, requestHeaders));
    },
    deleteLocation(variables: DeleteLocationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteLocationMutation> {
      return withWrapper(() => client.request<DeleteLocationMutation>(DeleteLocationDocument, variables, requestHeaders));
    },
    deleteProviderConfig(variables: DeleteProviderConfigMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteProviderConfigMutation> {
      return withWrapper(() => client.request<DeleteProviderConfigMutation>(DeleteProviderConfigDocument, variables, requestHeaders));
    },
    downloadResults(variables: DownloadResultsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadResultsMutation> {
      return withWrapper(() => client.request<DownloadResultsMutation>(DownloadResultsDocument, variables, requestHeaders));
    },
    downloadResultsForAcriss(variables: DownloadResultsForAcrissMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadResultsForAcrissMutation> {
      return withWrapper(() => client.request<DownloadResultsForAcrissMutation>(DownloadResultsForAcrissDocument, variables, requestHeaders));
    },
    downloadResultsForIncrement(variables: DownloadResultsForIncrementMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadResultsForIncrementMutation> {
      return withWrapper(() => client.request<DownloadResultsForIncrementMutation>(DownloadResultsForIncrementDocument, variables, requestHeaders));
    },
    duplicateConfig(variables: DuplicateConfigMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DuplicateConfigMutation> {
      return withWrapper(() => client.request<DuplicateConfigMutation>(DuplicateConfigDocument, variables, requestHeaders));
    },
    generateAnalytics(variables?: GenerateAnalyticsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GenerateAnalyticsMutation> {
      return withWrapper(() => client.request<GenerateAnalyticsMutation>(GenerateAnalyticsDocument, variables, requestHeaders));
    },
    generateCrawlerJobs(variables?: GenerateCrawlerJobsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GenerateCrawlerJobsMutation> {
      return withWrapper(() => client.request<GenerateCrawlerJobsMutation>(GenerateCrawlerJobsDocument, variables, requestHeaders));
    },
    impersonates(variables: ImpersonatesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ImpersonatesMutation> {
      return withWrapper(() => client.request<ImpersonatesMutation>(ImpersonatesDocument, variables, requestHeaders));
    },
    pingMutation(variables?: PingMutationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PingMutationMutation> {
      return withWrapper(() => client.request<PingMutationMutation>(PingMutationDocument, variables, requestHeaders));
    },
    recoverPassword(variables: RecoverPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RecoverPasswordMutation> {
      return withWrapper(() => client.request<RecoverPasswordMutation>(RecoverPasswordDocument, variables, requestHeaders));
    },
    resetPassword(variables: ResetPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetPasswordMutation> {
      return withWrapper(() => client.request<ResetPasswordMutation>(ResetPasswordDocument, variables, requestHeaders));
    },
    resetTable(variables?: ResetTableMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetTableMutation> {
      return withWrapper(() => client.request<ResetTableMutation>(ResetTableDocument, variables, requestHeaders));
    },
    runJob(variables: RunJobMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunJobMutation> {
      return withWrapper(() => client.request<RunJobMutation>(RunJobDocument, variables, requestHeaders));
    },
    runJobs(variables: RunJobsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunJobsMutation> {
      return withWrapper(() => client.request<RunJobsMutation>(RunJobsDocument, variables, requestHeaders));
    },
    startCrawler(variables?: StartCrawlerMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StartCrawlerMutation> {
      return withWrapper(() => client.request<StartCrawlerMutation>(StartCrawlerDocument, variables, requestHeaders));
    },
    updateAcrissCode(variables: UpdateAcrissCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateAcrissCodeMutation> {
      return withWrapper(() => client.request<UpdateAcrissCodeMutation>(UpdateAcrissCodeDocument, variables, requestHeaders));
    },
    updateAvatar(variables: UpdateAvatarMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateAvatarMutation> {
      return withWrapper(() => client.request<UpdateAvatarMutation>(UpdateAvatarDocument, variables, requestHeaders));
    },
    updateCompany(variables: UpdateCompanyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCompanyMutation> {
      return withWrapper(() => client.request<UpdateCompanyMutation>(UpdateCompanyDocument, variables, requestHeaders));
    },
    updateConfiguration(variables: UpdateConfigurationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateConfigurationMutation> {
      return withWrapper(() => client.request<UpdateConfigurationMutation>(UpdateConfigurationDocument, variables, requestHeaders));
    },
    updateCrawlerJob(variables: UpdateCrawlerJobMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCrawlerJobMutation> {
      return withWrapper(() => client.request<UpdateCrawlerJobMutation>(UpdateCrawlerJobDocument, variables, requestHeaders));
    },
    updateCrawlerResult(variables: UpdateCrawlerResultMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCrawlerResultMutation> {
      return withWrapper(() => client.request<UpdateCrawlerResultMutation>(UpdateCrawlerResultDocument, variables, requestHeaders));
    },
    updateCustomerconfig(variables: UpdateCustomerconfigMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCustomerconfigMutation> {
      return withWrapper(() => client.request<UpdateCustomerconfigMutation>(UpdateCustomerconfigDocument, variables, requestHeaders));
    },
    updateLocation(variables: UpdateLocationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateLocationMutation> {
      return withWrapper(() => client.request<UpdateLocationMutation>(UpdateLocationDocument, variables, requestHeaders));
    },
    updateMany(variables: UpdateManyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateManyMutation> {
      return withWrapper(() => client.request<UpdateManyMutation>(UpdateManyDocument, variables, requestHeaders));
    },
    updateProviderConfig(variables: UpdateProviderConfigMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateProviderConfigMutation> {
      return withWrapper(() => client.request<UpdateProviderConfigMutation>(UpdateProviderConfigDocument, variables, requestHeaders));
    },
    updateSelfPassword(variables: UpdateSelfPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateSelfPasswordMutation> {
      return withWrapper(() => client.request<UpdateSelfPasswordMutation>(UpdateSelfPasswordDocument, variables, requestHeaders));
    },
    updateUser(variables: UpdateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateUserMutation> {
      return withWrapper(() => client.request<UpdateUserMutation>(UpdateUserDocument, variables, requestHeaders));
    },
    updateUserPassword(variables: UpdateUserPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateUserPasswordMutation> {
      return withWrapper(() => client.request<UpdateUserPasswordMutation>(UpdateUserPasswordDocument, variables, requestHeaders));
    },
    acrissCode(variables: AcrissCodeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AcrissCodeQuery> {
      return withWrapper(() => client.request<AcrissCodeQuery>(AcrissCodeDocument, variables, requestHeaders));
    },
    acrissCodes(variables?: AcrissCodesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AcrissCodesQuery> {
      return withWrapper(() => client.request<AcrissCodesQuery>(AcrissCodesDocument, variables, requestHeaders));
    },
    analytics(variables?: AnalyticsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AnalyticsQuery> {
      return withWrapper(() => client.request<AnalyticsQuery>(AnalyticsDocument, variables, requestHeaders));
    },
    analyticsByDate(variables: AnalyticsByDateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AnalyticsByDateQuery> {
      return withWrapper(() => client.request<AnalyticsByDateQuery>(AnalyticsByDateDocument, variables, requestHeaders));
    },
    analyticsSourceOptions(variables?: AnalyticsSourceOptionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AnalyticsSourceOptionsQuery> {
      return withWrapper(() => client.request<AnalyticsSourceOptionsQuery>(AnalyticsSourceOptionsDocument, variables, requestHeaders));
    },
    basicLogin(variables: BasicLoginQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BasicLoginQuery> {
      return withWrapper(() => client.request<BasicLoginQuery>(BasicLoginDocument, variables, requestHeaders));
    },
    company(variables: CompanyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CompanyQuery> {
      return withWrapper(() => client.request<CompanyQuery>(CompanyDocument, variables, requestHeaders));
    },
    companys(variables?: CompanysQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CompanysQuery> {
      return withWrapper(() => client.request<CompanysQuery>(CompanysDocument, variables, requestHeaders));
    },
    configuration(variables: ConfigurationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ConfigurationQuery> {
      return withWrapper(() => client.request<ConfigurationQuery>(ConfigurationDocument, variables, requestHeaders));
    },
    configurations(variables?: ConfigurationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ConfigurationsQuery> {
      return withWrapper(() => client.request<ConfigurationsQuery>(ConfigurationsDocument, variables, requestHeaders));
    },
    crawlerJob(variables: CrawlerJobQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CrawlerJobQuery> {
      return withWrapper(() => client.request<CrawlerJobQuery>(CrawlerJobDocument, variables, requestHeaders));
    },
    crawlerJobs(variables?: CrawlerJobsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CrawlerJobsQuery> {
      return withWrapper(() => client.request<CrawlerJobsQuery>(CrawlerJobsDocument, variables, requestHeaders));
    },
    crawlerJobsToRun(variables?: CrawlerJobsToRunQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CrawlerJobsToRunQuery> {
      return withWrapper(() => client.request<CrawlerJobsToRunQuery>(CrawlerJobsToRunDocument, variables, requestHeaders));
    },
    crawlerResult(variables: CrawlerResultQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CrawlerResultQuery> {
      return withWrapper(() => client.request<CrawlerResultQuery>(CrawlerResultDocument, variables, requestHeaders));
    },
    crawlerResults(variables?: CrawlerResultsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CrawlerResultsQuery> {
      return withWrapper(() => client.request<CrawlerResultsQuery>(CrawlerResultsDocument, variables, requestHeaders));
    },
    customerconfig(variables: CustomerconfigQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CustomerconfigQuery> {
      return withWrapper(() => client.request<CustomerconfigQuery>(CustomerconfigDocument, variables, requestHeaders));
    },
    customerconfigs(variables?: CustomerconfigsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CustomerconfigsQuery> {
      return withWrapper(() => client.request<CustomerconfigsQuery>(CustomerconfigsDocument, variables, requestHeaders));
    },
    getAnalyticExcel(variables: GetAnalyticExcelQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAnalyticExcelQuery> {
      return withWrapper(() => client.request<GetAnalyticExcelQuery>(GetAnalyticExcelDocument, variables, requestHeaders));
    },
    getDashboardInfo(variables?: GetDashboardInfoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDashboardInfoQuery> {
      return withWrapper(() => client.request<GetDashboardInfoQuery>(GetDashboardInfoDocument, variables, requestHeaders));
    },
    getGroups(variables?: GetGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetGroupsQuery> {
      return withWrapper(() => client.request<GetGroupsQuery>(GetGroupsDocument, variables, requestHeaders));
    },
    getLocation(variables: GetLocationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLocationQuery> {
      return withWrapper(() => client.request<GetLocationQuery>(GetLocationDocument, variables, requestHeaders));
    },
    getLocations(variables?: GetLocationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLocationsQuery> {
      return withWrapper(() => client.request<GetLocationsQuery>(GetLocationsDocument, variables, requestHeaders));
    },
    getNewAccessToken(variables: GetNewAccessTokenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetNewAccessTokenQuery> {
      return withWrapper(() => client.request<GetNewAccessTokenQuery>(GetNewAccessTokenDocument, variables, requestHeaders));
    },
    getPolicies(variables?: GetPoliciesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPoliciesQuery> {
      return withWrapper(() => client.request<GetPoliciesQuery>(GetPoliciesDocument, variables, requestHeaders));
    },
    getProviderLocations(variables: GetProviderLocationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetProviderLocationsQuery> {
      return withWrapper(() => client.request<GetProviderLocationsQuery>(GetProviderLocationsDocument, variables, requestHeaders));
    },
    getRemoteResults(variables: GetRemoteResultsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetRemoteResultsQuery> {
      return withWrapper(() => client.request<GetRemoteResultsQuery>(GetRemoteResultsDocument, variables, requestHeaders));
    },
    loggedUser(variables?: LoggedUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoggedUserQuery> {
      return withWrapper(() => client.request<LoggedUserQuery>(LoggedUserDocument, variables, requestHeaders));
    },
    ping(variables?: PingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PingQuery> {
      return withWrapper(() => client.request<PingQuery>(PingDocument, variables, requestHeaders));
    },
    providerConfig(variables: ProviderConfigQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProviderConfigQuery> {
      return withWrapper(() => client.request<ProviderConfigQuery>(ProviderConfigDocument, variables, requestHeaders));
    },
    providerConfigs(variables?: ProviderConfigsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProviderConfigsQuery> {
      return withWrapper(() => client.request<ProviderConfigsQuery>(ProviderConfigsDocument, variables, requestHeaders));
    },
    roles(variables?: RolesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RolesQuery> {
      return withWrapper(() => client.request<RolesQuery>(RolesDocument, variables, requestHeaders));
    },
    user(variables: UserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserQuery> {
      return withWrapper(() => client.request<UserQuery>(UserDocument, variables, requestHeaders));
    },
    users(variables?: UsersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UsersQuery> {
      return withWrapper(() => client.request<UsersQuery>(UsersDocument, variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;