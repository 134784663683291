import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Breadcrumbs,
	Button,
	Card,
	CircularProgress,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Grid,
	InputAdornment,
	Link,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import MaterialReactTable, {
	MRT_ColumnDef,
	MRT_Row,
	MRT_TableInstance,
} from "material-react-table";
import { MRT_Localization_IT } from "material-react-table/locales/it";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { Helmet } from "react-helmet-async";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { Link as RouterLink } from "react-router-dom";
import GraphqlClient from "src/client/graphql.client";
import useSettings from "src/hooks/useSettings";
import { Analytic, AnalyticInput } from "src/types/generated";
import { v4 as uuidv4 } from "uuid";

interface AnalyticTableData {
	id: string;
	acrissCode: string;
	group: string;
	location: string;
	gg: number;
	felirent: { position: number; data: Competitor } | null;
	[date: number]: Competitor[];
}

interface Competitor {
	id: string;
	name: string;
	price: number;
	multipliedPrice: number;
	isSelected: boolean;
	isFelirent: boolean;
	source: string;
	url?: string;
}

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

function generateAnalyticTableData(analytics: Analytic[]): AnalyticTableData[] {
	const tableData: AnalyticTableData[] = [];

	// Group Analytics by acrissCode and location and duration
	const groups = analytics.reduce((acc, analytic) => {
		const key = `${analytic.group}-${analytic.location}-${analytic.duration}`;
		acc[key] = acc[key] || [];
		acc[key].push(analytic);
		return acc;
	}, {});

	// Generate AnalyticTableData for each group
	Object.keys(groups).forEach((key) => {
		const groupAnalytics = groups[key];

		const competitorsByDate: { [date: number]: Competitor[] } = {};
		const competitorFelirent: { position: number; data: Competitor }[] = [];

		groupAnalytics.forEach((analytic: Analytic, index) => {
			const dateIndex = moment(analytic.date, "DD/MM/YYYY")
				.startOf("day")
				.unix();

			// Swap special characters in competitor's name with space
			const formattedName = analytic.name.replace(/[^a-zA-Z0-9 ]/g, " ");

			const competitor: Competitor = {
				id: analytic.id,
				name: formattedName,
				price: analytic.price,
				multipliedPrice: analytic.multipliedPrice || analytic.price,
				isSelected: analytic.isSelected,
				isFelirent: analytic.isFelirent,
				source: analytic.source,
				url: analytic.url,
			};

			if (!competitorsByDate[dateIndex]) {
				competitorsByDate[dateIndex] = [];
			}

			// if already exists a competitor with the same id skip
			if (
				competitorsByDate[dateIndex].find((c) => c.name === competitor.name) !==
				undefined
			) {
				return;
			}

			competitorsByDate[dateIndex].push(competitor);
		});

		// sort Felirent objects by price asc
		competitorFelirent.sort((a, b) => a.data.price - b.data.price);

		const tableRow: AnalyticTableData = {
			id: key,
			acrissCode: groupAnalytics[0].acriss,
			location: groupAnalytics[0].location,
			group: groupAnalytics[0].group,
			gg: groupAnalytics[0].duration,
			felirent: competitorFelirent.length > 0 ? competitorFelirent[0] : null,
			...competitorsByDate,
		};

		tableData.push(tableRow);
	});

	return tableData;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
	function NumericFormatCustom(props, ref) {
		const { onChange, ...other } = props;

		return (
			<NumericFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				thousandSeparator="."
				decimalSeparator=","
				valueIsNumericString
				prefix="€"
			/>
		);
	}
);

function FelirentRow({ competitors }: { competitors: Competitor[] }) {
	const felirent = competitors.find(
		(c) => c.name === "felirent" && !c.isFelirent
	);
	const position = competitors.indexOf(felirent) + 1;

	if (!felirent) {
		return (
			<Grid item xs={12}>
				<Typography variant="caption" color="error">
					Felirent assente
				</Typography>
			</Grid>
		);
	}

	return (
		<Typography variant="caption" color="textSecondary">
			{position}° {felirent.name} - {felirent.price}€
		</Typography>
	);
}

export default function PageAnalytics() {
	const { settings } = useSettings();
	const { enqueueSnackbar } = useSnackbar();
	const tableInstanceRef = useRef<MRT_TableInstance<AnalyticTableData>>(null);

	const [openResetWarning, setOpenResetWarning] = useState<boolean>(false);
	const [priceDetractor, setPriceDetractor] = useState<number>();
	const [selectedSource, setSelectedSource] =
		useState<string>("DoYouSpain.com");
	const [sourceOptions, setSourceOptions] = useState<string[]>([]);
	const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
	const [year, setYear] = useState<number>(new Date().getFullYear());
	const [tableData, setTableData] = useState<AnalyticTableData[]>([]);

	const { isLoading: isLoadingSourceOptions, refetch: refetchSourceOptions } =
		useQuery(
			["analyticsSourceOptions"],
			() => GraphqlClient.analyticsSourceOptions(),
			{
				refetchOnWindowFocus: false,
				onError: (error) => {
					console.error(error);
				},
				onSuccess: (data) => {
					setSelectedSource(data.analyticsSourceOptions[0]);
					setSourceOptions(data.analyticsSourceOptions);
				},
			}
		);

	const { isLoading: isLoadingPriceDetractor } = useQuery(
		["analyticspriceDetractor"],
		() =>
			GraphqlClient.configuration({
				id: "priceDetractor",
			}),
		{
			refetchOnWindowFocus: false,
			onError: (error) => {
				console.error(error);
				setPriceDetractor(2);
			},
			onSuccess: (data) => {
				setPriceDetractor(Number.parseFloat(data.configuration.value) || 2);
			},
		}
	);

	const {
		isLoading: isLoadingUpdatePriceDetractor,
		mutate: updatePriceDetractor,
	} = useMutation(
		["analyticsUpdatepriceDetractor"],
		() =>
			GraphqlClient.updateConfiguration({
				id: "priceDetractor",
				form: {
					value: priceDetractor.toString(),
				},
			}),
		{
			onError: (error) => {
				console.error(error);
				enqueueSnackbar("Errore durante l'aggiornamento della detrazione", {
					variant: "error",
				});
			},
			onSuccess: (data) => {
				enqueueSnackbar("Detrazione aggiornata con successo", {
					variant: "success",
				});
			},
		}
	);

	const { isLoading, isFetching, refetch } = useQuery(
		["analytics", month, year, selectedSource],
		() =>
			GraphqlClient.analyticsByDate({
				month: month,
				year: year,
				source: selectedSource,
			}),
		{
			refetchOnWindowFocus: false,
			onError: (error) => {
				console.error(error);
			},
			onSuccess: (data) => {
				const tableData = generateAnalyticTableData(data.analyticsByDate);
				// hide columns (dates) with no competitors
				const daysInMonth = moment()
					.month(month + 1)
					.daysInMonth();
				const columnsVisibility: Record<string, boolean> = {};

				for (let i = 1; i <= daysInMonth; i++) {
					const date = moment()
						.year(year)
						.month(month - 1)
						.date(i)
						.startOf("day")
						.unix();

					const competitors = tableData.reduce((acc, row) => {
						if (row[date]) {
							acc += row[date].length;
						}
						return acc;
					}, 0);

					columnsVisibility[(i - 1).toString()] = competitors > 0;
				}

				tableInstanceRef.current?.setState((old) => ({
					...old,
					columnVisibility: columnsVisibility,
				}));

				setTableData(tableData);
			},
		}
	);

	const { mutate: updateTable, mutateAsync: updateTableAsync, isLoading: isSaving } = useMutation(
		(input: {
			data: AnalyticInput[];
			acriss: string[];
			source: string;
			duration?: number;
			date?: string;
		}) => GraphqlClient.updateMany({ ...input, input: input.data }),
		{
			onSuccess: () => {
				enqueueSnackbar("Aggiornamento completato", { variant: "success" });
				refetch();
			},
			onError: (error) => {
				console.error(error);
				enqueueSnackbar("Errore durante l'aggiornamento", {
					variant: "error",
				});
			},
		}
	);

	const { mutate: resetTable, isLoading: isResetting } = useMutation(
		() => GraphqlClient.resetTable(),
		{
			onSuccess: () => {
				enqueueSnackbar("Reset completato", { variant: "success" });
				refetch();
				refetchSourceOptions();
				setOpenResetWarning(false);
			},
			onError: (error) => {
				console.error(error);
				enqueueSnackbar(
					"Errore durante il reset, i dati non sono stati modificati",
					{
						variant: "error",
					}
				);
			},
		}
	);

	const [priceInput, setPriceInput] = useState<string>("");
	const [open, setOpen] = useState<{ rowId: string; dateIndex: number } | null>(
		null
	);

	const [multiplier, setMultiplier] = useState<number>(1.5);
	const [mult21, setMult21] = useState<number>(1.6);
	const [mult25, setMult25] = useState<number>(1.6);
	const [openDownloadDialog, setOpenDownloadDialog] = useState<boolean>(false);
	const [enableDownload, setEnableDownload] = useState<boolean>(false);

	const { isLoading: isExcelLoading, refetch: triggerDownloadExcel } = useQuery(
		["analytics", month, year, multiplier, mult21, mult25],
		() => {
			const selection = [];
			if (tableInstanceRef.current) {
				const rowSelection = tableInstanceRef.current.getState().rowSelection;
				Object.keys(rowSelection).forEach((key) => {
					if (rowSelection[key]) selection.push(key);
				});
			}

			return GraphqlClient.getAnalyticExcel({
				month: month,
				year: year,
				mul: multiplier,
				mul21: mult21,
				mul25: mult25,
				source: selectedSource,
				selection: selection,
			});
		},
		{
			refetchOnWindowFocus: false,
			enabled: false,
			onSuccess: (data) => {
				// download excel from b64
				const b64 = data.getAnalyticExcel;
				const link = document.createElement("a");
				link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${b64}`;
				link.download = `${selectedSource}-${month}-${year}-${moment().format(
					"DD-MM-YYYY"
				)}.xlsx`;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
			onError: (error) => {
				console.error(error);
			},
		}
	);

	const handleDownloadExcel = () => {
		const selection = tableInstanceRef.current.getState().rowSelection;
		triggerDownloadExcel();
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPriceInput(event.target.value);
	};

	const handleMultiplierApply = () => {
		const dataCopy = [...tableData];

		// aggiornare i prezzi
		dataCopy.forEach((d) => {
			Object.keys(d).forEach((key) => {
				if (Array.isArray(d[key])) {
					(d[key] as Competitor[]).forEach((c) => {
						if (c.isFelirent)
							c.multipliedPrice = Math.round(c.price * multiplier);
					});
				}
			});
		});

		// update table
		setTableData([...dataCopy]);
	};

	const handleMultiplierRestore = () => {
		const dataCopy = [...tableData];
		setMultiplier(1);

		// aggiornare i prezzi
		dataCopy.forEach((d) => {
			Object.keys(d).forEach((key) => {
				if (Array.isArray(d[key])) {
					(d[key] as Competitor[]).forEach((c) => {
						if (c.isFelirent) c.multipliedPrice = null;
					});
				}
			});
		});

		// update table
		setTableData([...dataCopy]);
	};

	const [openCheckboxSelectionDialog, setOpenCheckboxSelectionDialog] =
		useState<boolean>(false);

	const [positionSelected, setPositionSelected] = useState<number>(1);

	const handleCheckboxSelection = () => {
		const selection = tableInstanceRef.current.getState().rowSelection;

		const groupsSelected = Object.keys(selection)
			.filter((key) => selection[key] && key.includes("group"))
			.map((key) => key.split(":"))
			.map((key) => key[key.length - 1]);

		const dataCopy = [...tableData];
		const competitorIndex = positionSelected - 1;

		// aggiornare i prezzi
		dataCopy.forEach((d) => {
			Object.keys(d).forEach((key) => {
				if (Object.keys(selection).includes(d.id) && Array.isArray(d[key])) {
					// delete existing Felirent
					const felirentIndex = (d[key] as Competitor[]).findIndex(
						(c) => c.isFelirent
					);
					if (felirentIndex !== -1) {
						(d[key] as Competitor[]).splice(felirentIndex, 1);
					}

					// deselect all competitors
					(d[key] as Competitor[]).forEach((competitor) => {
						competitor.isSelected = false;
					});

					// toggle isSelected
					(d[key] as Competitor[]).forEach((competitor, index) => {
						if (index === competitorIndex) {
							competitor.isSelected = !competitor.isSelected;
						}
					});

					let competitorToSurpass = (d[key] as Competitor[])[competitorIndex];

					if (!competitorToSurpass) return;

					if (competitorToSurpass.name.toLowerCase() === "felirent") {
						const nextValidCompetitor = (d[key] as Competitor[])[competitorIndex + 1];
						if (nextValidCompetitor) {
							competitorToSurpass = nextValidCompetitor;
						}
					}

					const felirentPrice = competitorToSurpass.price - priceDetractor;

					// add Felirent
					(d[key] as Competitor[]).push({
						id: uuidv4(),
						name: "Felirent",
						price: felirentPrice,
						isSelected: false,
						multipliedPrice: null,
						isFelirent: true,
						source: selectedSource,
					});

					// sort competitors
					(d[key] as Competitor[]).sort((a, b) => {
						return a.price - b.price;
					});
				}
			});
		});

		// update table
		setTableData([...dataCopy]);

		// close dialog
		setOpenCheckboxSelectionDialog(false);
		console.log("Aggiornato fronted");
		// update backend
		const input: AnalyticInput[] = [];
		dataCopy.forEach((r) => {
			Object.keys(r).forEach((key) => {
				if (Array.isArray(r[key])) {
					(r[key] as Competitor[]).forEach((c) => {
						input.push({
							id: c.id,
							name: c.name,
							price: c.price,
							isSelected: c.isSelected,
							isFelirent: c.isFelirent,
							multipliedPrice: c.multipliedPrice,
							date: moment(key, "X").format("DD/MM/YYYY"),
							url: c.url,
							source: c.source,
							acriss: r.acrissCode,
							location: r.location,
							duration: r.gg,
						});
					});
				}
			});
		});

		const acrissCodes = [];
		groupsSelected.forEach((group) => {
			// Get acriss for group
			// One group has multiple acriss
			dataCopy.forEach((row) => {
				if (row.group !== group) return;
				acrissCodes.push(row.acrissCode);
			});
		});


		updateTableWitPromise(input, updateTableAsync);

	};

	const handleAddPrice = ({
		rowId,
		dateIndex,
	}: {
		rowId: string;
		dateIndex: number;
	}) => {
		const input = Number(priceInput);
		if (!input || input === 0) return;

		// aggiornare i prezzi
		const dataCopy = [...tableData];

		// find the row
		const row = dataCopy.find((d) => d.id === rowId);
		if (!row) return;

		// add Felirent as competitor with input price
		const competitors = row[dateIndex] as Competitor[];

		// remove existing Felirent
		const felirentIndex = competitors?.findIndex((c) => c.isFelirent);
		if (felirentIndex !== -1) competitors.splice(felirentIndex, 1);

		// deselect other competitors
		competitors.forEach((c) => (c.isSelected = false));

		competitors.push({
			id: uuidv4(),
			name: "Felirent",
			price: input,
			isSelected: false,
			multipliedPrice: null,
			isFelirent: true,
			source: selectedSource,
		});

		// sort competitors by price
		competitors.sort((a, b) => a.price - b.price);

		// close dialog
		setOpen(null);

		// update backend
		const updateInput: AnalyticInput[] = [];
		competitors.forEach((c) => {
			updateInput.push({
				id: c.id,
				name: c.name,
				price: c.price,
				isSelected: c.isSelected,
				multipliedPrice: c.multipliedPrice,
				isFelirent: c.isFelirent,
				url: c.url,
				source: c.source,
				acriss: row.acrissCode,
				date: moment(dateIndex, "X").format("DD/MM/YYYY"),
				location: row.location,
				duration: row.gg,
			});
		});

		updateTable({
			data: updateInput,
			acriss: [row.acrissCode],
			source: selectedSource,
			date: moment(dateIndex, "X").format("DD/MM/YYYY"),
			duration: row.gg,
		});
	};

	const handleAddSingleEntry = ({
		rowId,
		dateIndex,
		competitor,
	}: {
		rowId: string;
		dateIndex: number;
		competitor: Competitor;
	}) => {
		const dataCopy = [...tableData];

		// find the row
		const row = dataCopy.find((d) => d.id === rowId);
		if (!row) return;

		// find the competitor
		const competitors = row[dateIndex] as Competitor[];
		if (!competitors) return;

		const competitorIndex = competitors.findIndex(
			(c) => c.id === competitor.id
		);

		if (competitorIndex === -1) return;

		competitors.forEach((c) => (c.isSelected = false));

		// remove existing Felirent
		const felirentIndex = competitors.findIndex((c) => c.isFelirent);
		if (felirentIndex !== -1) {
			competitors.splice(felirentIndex, 1);
		} else {
			competitors[competitorIndex].isSelected =
				!competitors[competitorIndex].isSelected;

			// get new index where to add Felirent
			const newFelirentIndex =
				felirentIndex === -1
					? competitorIndex
					: competitorIndex > felirentIndex
					? competitorIndex - 1
					: competitorIndex;

			let choosedCompetitor = competitors[newFelirentIndex];
			if (competitors[newFelirentIndex].name.toLowerCase() === "felirent") {
				const nextValidCompetitor = competitors[newFelirentIndex + 1];
				if (nextValidCompetitor) {
					choosedCompetitor = nextValidCompetitor;
				}
			}

			// if is isSelected, add Felirent as competitor with price 1 lower than the isSelected competitor
			if (competitors[newFelirentIndex].isSelected) {
				competitors.splice(newFelirentIndex, 0, {
					id: uuidv4(),
					name: "Felirent",
					price: choosedCompetitor.price - priceDetractor,
					isSelected: false,
					multipliedPrice: null,
					isFelirent: true,
					source: selectedSource,
				});
			}
		}

		const input: AnalyticInput[] = [];
		competitors.forEach((c) => {
			input.push({
				id: c.id,
				name: c.name,
				price: c.price,
				isSelected: c.isSelected,
				isFelirent: c.isFelirent,
				multipliedPrice: c.multipliedPrice,
				url: c.url,
				source: c.source,
				date: moment(dateIndex, "X").format("DD/MM/YYYY"),
				acriss: row.acrissCode,
				location: row.location,
				duration: row.gg,
			});
		});

		updateTable({
			data: input,
			acriss: [row.acrissCode],
			source: selectedSource,
			date: moment(dateIndex, "X").format("DD/MM/YYYY"),
			duration: row.gg,
		});
	};

	const handleAddBothAxis = ({
		row,
		position,
		deselect,
	}: {
		row: MRT_Row<AnalyticTableData>;
		position: number;
		deselect: boolean;
	}) => {
		const dataCopy = [...tableData];

		// find all the rows with same acrissCode, location
		const rows = dataCopy.filter(
			(d) =>
				d.group === row.original.group && d.location === row.original.location
		);

		if (deselect) {
			// Rimuovo felirent se esiste gia'
			rows.forEach((c) => {
				Object.keys(c).forEach((key) => {
					if (Array.isArray(c[key])) {
						(c[key] as Competitor[]).forEach((competitor) => {
							competitor.isSelected = false;
						});

						// remove existing Felirent
						const felirentIndex = (c[key] as Competitor[]).findIndex(
							(c) => c.isFelirent
						);

						if (felirentIndex !== -1)
							(c[key] as Competitor[]).splice(felirentIndex, 1);
					}
				});
			});
		} else {
			// select all the competitors at the same position, deselects the rest
			rows.forEach((c) => {
				Object.keys(c).forEach((key) => {
					if (Array.isArray(c[key])) {
						(c[key] as Competitor[]).forEach((competitor, index) => {
							competitor.isSelected = index === position;
						});

						// remove existing Felirent
						const felirentIndex = (c[key] as Competitor[]).findIndex(
							(c) => c.isFelirent
						);

						if (felirentIndex !== -1)
							(c[key] as Competitor[]).splice(felirentIndex, 1);

						// get new index where to add Felirent
						const newFelirentIndex =
							felirentIndex === -1
								? position
								: position > felirentIndex
								? position - 1
								: position;

						// if is isSelected, add Felirent as competitor with price 1 lower than the isSelected competitor
						if ((c[key] as Competitor[])[newFelirentIndex].isSelected) {
							const competitors = c[key] as Competitor[];
							let competitor = competitors[newFelirentIndex];
							if (competitor.name.toLowerCase() === "felirent") {
								const nextValidCompetitor = competitors[newFelirentIndex + 1];	
								if (nextValidCompetitor) {
									competitor = nextValidCompetitor;
								}
							}

							competitors.splice(newFelirentIndex, 0, {
								id: uuidv4(),
								name: "Felirent",
								price: competitor.price - priceDetractor,
								isSelected: false,
								multipliedPrice: null,
								isFelirent: true,
								source: selectedSource,
							});
						}
					}
				});
			});
		}

		// update backend
		const input: AnalyticInput[] = [];
		rows.forEach((r) => {
			Object.keys(r).forEach((key) => {
				if (Array.isArray(r[key])) {
					(r[key] as Competitor[]).forEach((c) => {
						input.push({
							id: c.id,
							name: c.name,
							price: c.price,
							isSelected: c.isSelected,
							isFelirent: c.isFelirent,
							multipliedPrice: c.multipliedPrice,
							url: c.url,
							source: c.source,
							date: moment(key, "X").format("DD/MM/YYYY"),
							acriss: r.acrissCode,
							location: r.location,
							duration: r.gg,
						});
					});
				}
			});
		});

		updateTable({
			data: input,
			acriss: [row.original.acrissCode],
			source: selectedSource,
		});
	};

	const handleAddHorizontal = ({
		row,
		dateIndex,
		competitor,
	}: {
		row: MRT_Row<AnalyticTableData>;
		dateIndex: number;
		competitor: Competitor;
	}) => {
		const dataCopy = [...tableData];
		const momentDate = moment.unix(dateIndex);

		const currentRow = dataCopy.find(
			(d) =>
				d.acrissCode === row.original.acrissCode &&
				d.location === row.original.location &&
				d.gg === row.original.gg
		);

		if (!currentRow) return;

		if (competitor.isSelected) {
			// Se riclicco sul competitor su cui ho cliccato per impostare il prezzo mi elimina i prezzi
			Object.keys(currentRow).forEach((key) => {
				if (
					moment(key, "X").isValid() &&
					moment(key, "X").isSameOrAfter(momentDate)
				) {
					const competitors = currentRow[key] as Competitor[];

					// deselect all the competitors in the same date at the same position, after the clicked competitor
					competitors.forEach((c) => {
						c.isSelected = false;
					});

					// remove Felirent if already exists
					const felirentIndex = competitors.findIndex((c) => c.isFelirent);

					if (felirentIndex !== -1) competitors.splice(felirentIndex, 1);
				}
			});
		} else {
			// Altrimenti inserisco felirent sopra il competitor
			const clickedCompetitorIndex = (
				currentRow[dateIndex] as Competitor[]
			)?.findIndex((c) => c.id === competitor.id);

			if (clickedCompetitorIndex === -1) return;

			Object.keys(currentRow).forEach((key) => {
				if (
					moment(key, "X").isValid() &&
					moment(key, "X").isSameOrAfter(momentDate)
				) {
					const competitors = currentRow[key] as Competitor[];

					// select all the competitors in the same date at the same position, after the clicked competitor gg
					competitors.forEach((c, index) => {
						if (index === clickedCompetitorIndex) {
							c.isSelected = !c.isSelected;
						}

						if (index !== clickedCompetitorIndex) {
							c.isSelected = false;
						}
					});

					// remove Felirent if already exists
					const felirentIndex = competitors.findIndex((c) => c.isFelirent);

					if (felirentIndex !== -1) competitors.splice(felirentIndex, 1);

					const newCompetitorIndex =
						felirentIndex === -1
							? clickedCompetitorIndex
							: clickedCompetitorIndex > felirentIndex
							? clickedCompetitorIndex - 1
							: clickedCompetitorIndex;

					let newPrice = competitors[newCompetitorIndex].price - priceDetractor;
					if (
						competitors[newCompetitorIndex].name.toLowerCase() === "felirent"
					) {
						// Se ferelitn e' gia' primo uso il prezzo del secondo competitor meno la detrazione
							const nextValidCompetitor = competitors[newCompetitorIndex + 1];	
							if (nextValidCompetitor) {
								newPrice = nextValidCompetitor.price - priceDetractor;
							}
						
					}

					// if is isSelected, add Felirent as competitor with price 1 lower than the competitor at clickedCompetitorIndex
					if (competitors[newCompetitorIndex].isSelected) {
						competitors.splice(newCompetitorIndex, 0, {
							id: uuidv4(),
							name: "Felirent",
							price: newPrice,
							isSelected: false,
							multipliedPrice: null,
							isFelirent: true,
							source: selectedSource,
						});
					}
				}
			});
		}

		const input: AnalyticInput[] = [];

		Object.keys(currentRow).forEach((key) => {
			if (moment(key, "X").isValid()) {
				const competitors = currentRow[key] as Competitor[];

				competitors.forEach((c) => {
					input.push({
						acriss: currentRow.acrissCode,
						location: currentRow.location,
						duration: currentRow.gg,
						date: moment(key, "X").format("DD/MM/YYYY"),
						id: c.id,
						name: c.name,
						price: c.price,
						multipliedPrice: c.multipliedPrice,
						isSelected: c.isSelected,
						isFelirent: c.isFelirent,
						url: c.url,
						source: c.source,
					});
				});
			}
		});

		updateTable({
			data: input,
			acriss: [row.original.acrissCode],
			source: selectedSource,
			duration: row.original.gg,
		});
	};

	const columns: MRT_ColumnDef<AnalyticTableData>[] = [
		{
			accessorKey: "group",
			header: "Gruppo",
			enablePinning: true,
		},
		{
			accessorKey: "location",
			header: "Localita",
			enablePinning: true,
		},
		{
			accessorKey: "gg",
			header: "Durata",
			enablePinning: true,
		},
		...Array.from({ length: moment().month(month).daysInMonth() }, (_, i) => {
			const date = moment()
				.date(i + 1)
				.month(month - 1)
				.year(year)
				.startOf("day");
			const dateIndex = date.unix();

			return {
				accessorFn: (row) => row[dateIndex],
				id: i.toString(),
				header: date.format("ddd DD/MM/YYYY"),
				size: 250,
				enableSorting: false,
				enableGlobalFilter: false,
				enableGrouping: false,
				enablePinning: false,
				Cell: ({ row }) => {
					const competitors = row.original[dateIndex] as Competitor[];
					if (!competitors || competitors.length === 0) {
						return <div />;
					}
					const competitorsLimit = 5;
					return (
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="space-between"
							height="100%"
						>
							{competitors
								.sort((a, b) => a.price - b.price)
								.slice(0, competitorsLimit)
								.map((competitor) => (
									<Grid container spacing={1} key={competitor.id}>
										<Grid
											item
											xs={6}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "left",
											}}
										>
											<Button
												disabled={competitor.isFelirent}
												size="small"
												variant="text"
												color={competitor.isSelected ? "success" : "primary"}
												sx={{
													padding: 0,
												}}
												onClick={() => {
													handleAddHorizontal({
														row,
														dateIndex,
														competitor,
													});
												}}
												onContextMenu={(e) => {
													e.preventDefault();

													handleAddBothAxis({
														row,
														position: competitors.findIndex(
															(c) => c.id === competitor.id
														),
														deselect: competitor.isSelected,
													});
													return;
												}}
												onAuxClick={(e) => {
													e.preventDefault();

													if (e.button === 2) {
														return;
													}

													handleAddSingleEntry({
														rowId: row.original.id,
														dateIndex,
														competitor,
													});
												}}
											>
												{competitor.name}
											</Button>
										</Grid>
										<Grid
											item
											xs={6}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											{competitor.url ? (
												<Link
													href={competitor.url}
													target="_blank"
													rel="noopener noreferrer"
													underline="hover"
													color="primary"
												>
													<CurrencyFormat
														value={
															competitor.multipliedPrice ?? competitor.price
														}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"€"}
														decimalScale={2}
													/>
												</Link>
											) : (
												<CurrencyFormat
													value={competitor.multipliedPrice ?? competitor.price}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"€"}
													decimalScale={2}
												/>
											)}
										</Grid>
									</Grid>
								))}

							<FelirentRow competitors={competitors} />

							<Divider />
							<Button
								sx={{ p: 0 }}
								variant="text"
								size="small"
								color="warning"
								onClick={() => setOpen({ rowId: row.original.id, dateIndex })}
							>
								Inserisci
							</Button>
						</Box>
					);
				},
			} as MRT_ColumnDef<AnalyticTableData>;
		}),
	];

	return (
		<>
			<Helmet>
				<title>Analytics</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: "background.default",
					py: 8,
				}}
			>
				<Container maxWidth={settings.compact ? "xl" : false}>
					<Dialog open={Boolean(open)} onClose={() => setOpen(null)}>
						<DialogContent
							sx={{ p: 2, display: "flex", flexDirection: "column" }}
						>
							<TextField
								label="Prezzo"
								value={priceInput}
								onChange={handleChange}
								name="numberformat"
								id="formatted-numberformat-input"
								InputProps={{
									inputComponent: NumericFormatCustom as any,
								}}
								variant="outlined"
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setOpen(null)}>Annulla</Button>

							<Button
								variant="contained"
								onClick={() => handleAddPrice(open)}
								sx={{ ml: 2 }}
							>
								Inserisci
							</Button>
						</DialogActions>
					</Dialog>

					<Dialog
						open={openCheckboxSelectionDialog}
						onClose={() => setOpenCheckboxSelectionDialog(false)}
					>
						<DialogContent
							sx={{ p: 2, display: "flex", flexDirection: "column" }}
						>
							<Select
								labelId="position-select"
								id="position-select"
								value={positionSelected}
								label="Posizione"
								onChange={(e) => setPositionSelected(e.target.value as number)}
							>
								<MenuItem value={1}>1</MenuItem>
								<MenuItem value={2}>2</MenuItem>
								<MenuItem value={3}>3</MenuItem>
								<MenuItem value={4}>4</MenuItem>
								<MenuItem value={5}>5</MenuItem>
							</Select>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setOpenCheckboxSelectionDialog(false)}>
								Annulla
							</Button>

							<Button
								variant="contained"
								onClick={() => handleCheckboxSelection()}
								sx={{ ml: 2 }}
							>
								Inserisci
							</Button>
						</DialogActions>
					</Dialog>

					<Dialog
						open={openDownloadDialog}
						onClose={() => setOpenDownloadDialog(false)}
					>
						<DialogContent
							sx={{ p: 2, display: "flex", flexDirection: "column" }}
						>
							<TextField
								label="Moltiplicatore 21"
								size="small"
								value={mult21}
								sx={{ m: 1 }}
								onChange={(e) => setMult21(Number(e.target.value))}
								inputProps={{ min: 0, max: 100, step: 0.1 }}
								type="number"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">x</InputAdornment>
									),
								}}
							/>
							<TextField
								label="Moltiplicatore 25"
								size="small"
								value={mult25}
								sx={{ m: 1 }}
								onChange={(e) => setMult25(Number(e.target.value))}
								inputProps={{ min: 0, max: 100, step: 0.1 }}
								type="number"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">x</InputAdornment>
									),
								}}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setOpenDownloadDialog(false)}>
								Annulla
							</Button>

							<Button
								variant="contained"
								onClick={() => handleDownloadExcel()}
								sx={{ ml: 2 }}
							>
								Download
							</Button>
						</DialogActions>
					</Dialog>

					<Dialog
						open={openResetWarning}
						onClose={() => setOpenResetWarning(false)}
					>
						<DialogContent
							sx={{ p: 2, display: "flex", flexDirection: "column" }}
						>
							<Typography variant="h6" sx={{ mb: 2 }}>
								Sei sicuro di voler resettare i dati?
							</Typography>
							<Typography variant="body2" sx={{ mb: 2 }}>
								Questa azione è irreversibile, colpirà solo i dati relativi al
								mese visualizzato.
							</Typography>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setOpenResetWarning(false)}>
								Annulla
							</Button>

							<LoadingButton
								loading={isResetting}
								color="error"
								variant="contained"
								onClick={() => resetTable()}
								sx={{ ml: 2 }}
							>
								Reset
							</LoadingButton>
						</DialogActions>
					</Dialog>

					<Grid container justifyContent="space-between" spacing={3}>
						<Grid item>
							<Typography color="textPrimary" variant="h5">
								Analytics
							</Typography>
							<Breadcrumbs
								aria-label="breadcrumb"
								separator={<ChevronRightIcon fontSize="small" />}
								sx={{ mt: 1 }}
							>
								<Link
									color="textPrimary"
									component={RouterLink}
									to="/dashboard"
									variant="subtitle2"
								>
									Dashboard
								</Link>
								<Typography color="textSecondary" variant="subtitle2">
									Analytics
								</Typography>
							</Breadcrumbs>
						</Grid>
						<Grid item sx={{ display: "flex", alignItems: "center" }}>
							{isSaving && <CircularProgress size={24} sx={{ mr: 1 }} />}

							<Button
								variant="contained"
								onClick={() => setOpenResetWarning(true)}
								sx={{ mr: 1 }}
							>
								Reset
							</Button>

							<Button
								variant="contained"
								onClick={() => setOpenDownloadDialog(true)}
								sx={{ mr: 1 }}
							>
								Download Excel
							</Button>

							<TextField
								label="Detrazione"
								size="small"
								value={priceDetractor}
								disabled={
									isLoadingPriceDetractor || isLoadingUpdatePriceDetractor
								}
								sx={{ mr: 1 }}
								onChange={(e) => setPriceDetractor(Number(e.target.value))}
								onBlur={() => {
									updatePriceDetractor();
								}}
								inputProps={{ min: 0, max: 100, step: 0.1 }}
								type="number"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">€</InputAdornment>
									),
								}}
							/>

							<Select
								disabled={isLoadingSourceOptions}
								size="small"
								value={selectedSource}
								sx={{ mr: 2 }}
								onChange={(e) => setSelectedSource(e.target.value as string)}
							>
								{sourceOptions.map((option) => (
									<MenuItem value={option} key={option}>
										{option}
									</MenuItem>
								))}
							</Select>

							<Select
								size="small"
								value={month}
								sx={{ mr: 2 }}
								onChange={(e) => setMonth(e.target.value as number)}
							>
								{Array.from({ length: 12 }, (_, i) => (
									<MenuItem value={i + 1} key={i}>
										{moment().month(i).format("MMMM")}
									</MenuItem>
								))}
							</Select>

							<Select
								size="small"
								value={year}
								onChange={(e) => setYear(e.target.value as number)}
							>
								{Array.from({ length: 5 }, (_, i) => (
									<MenuItem value={moment().year() + i} key={i}>
										{moment().year() + i}
									</MenuItem>
								))}
							</Select>
						</Grid>
					</Grid>
					<Box sx={{ mt: 3 }}>
						<Card variant="outlined">
							<MaterialReactTable
								tableInstanceRef={tableInstanceRef}
								localization={MRT_Localization_IT}
								columns={columns}
								data={tableData || []}
								enableGrouping
								enableGlobalFilter={false}
								enableColumnOrdering
								enablePinning
								enableStickyHeader
								enableStickyFooter
								enableMultiSort
								enableRowSelection
								getRowId={(originalRow) => originalRow.id}
								enableRowVirtualization
								rowVirtualizerProps={{ overscan: 10 }}
								enablePagination={false}
								renderTopToolbarCustomActions={() => (
									<LoadingButton 
									loading={isSaving}
									onClick={() => setOpenCheckboxSelectionDialog(true)}>
										{"Posizione righe selezionate"}
									</LoadingButton>
								)}
								initialState={{
									grouping: ["location", "group"],
									density: "compact",
									sorting: [
										{ id: "location", desc: false },
										{ id: "group", desc: false },
										{ id: "gg", desc: false },
									],
								}}
								state={{
									isLoading: isLoading,
								}}
							/>
						</Card>
					</Box>
				</Container>
			</Box>
		</>
	);
}


function splitToNChunks(array, n) {     
	let result = [];     
	for (let i = n; i > 0; i--) {         
		result.push(array.splice(0, Math.ceil(array.length / i)));     
	}     
	return result; 
}


async function updateTableWitPromise( input: AnalyticInput[], updateTableAsync: any, ){

	const size = Buffer.byteLength(JSON.stringify(input), "utf8");

	const chunkSize = 10*1024*1024; // 10MB
	const chunks = splitToNChunks(input, size/chunkSize);

	const promisesChunks = chunks.map( async (chunk) => {
		return new Promise( (resolve, reject) => {
			updateTableAsync({
				data: chunk,
				acriss: chunk.map( c => c.acriss ),
				source: chunk[0].source,
			}).then((data) => {
				console.log(data);
				resolve("ok");
			}).catch( (err) => {
				reject(err);
			});
		} );
	} );

	return await Promise.all(promisesChunks)
}