import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { Add, Edit } from "@mui/icons-material";
import GraphqlClient from "src/client/graphql.client";
import {
  SelectColumnFilter,
  SelectColumnFilterMethod,
  Table
} from "src/components/Table/Table";
import PermissionGuard from "../../components/PermissionGuard/PermissionGuard";
import useSettings from "../../hooks/useSettings";
import { Can } from "../../pages/login/authorizer";
import { useSelector } from "../../store";
import {
  deleteProviderConfig,
  duplicateProviderConfig,
  readAll
} from "./commands.providerConfig";

const PageProviderConfig: FC = (props) => {
  const data = useSelector((state) => state.providerConfig.data);
  const user = useSelector((state) => (state as any).auth);
  const { t } = useTranslation();

  const { settings } = useSettings();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [hiddenColumns, setHiddenColumns] = useState(["company"]);

  const { ...other } = props;

  useEffect(() => {
    readAll();
  }, []);

  useEffect(() => {
    const checkRole = async () => {
      let can = await Can("allow", "users");
      if (can) {
        setHiddenColumns([]);
      }
    };

    checkRole();
  }, [user]);

  const deleteEntry = async (id: string) => {
    try {
      let res = await deleteProviderConfig(id);
      readAll();
      enqueueSnackbar("Eliminato con successo", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
        variant: "success",
      });
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar("Impossibile eliminare", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  const handleDuplicate = async (id: string) => {
    const res = await duplicateProviderConfig(id);
    if (res.error) {
      enqueueSnackbar(res.message, {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
      return;
    }

    enqueueSnackbar("Duplicato con successo", {
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
      variant: "success",
    });
    readAll();
  };

  const setActive = async (id: string, active) => {
    try {
      let res = await GraphqlClient.changeActive({ id, active });
      readAll();
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar("Impossibile aggiornare stato", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  let mapWeekDay = new Map([
    [1, "Lun"],
    [2, "Mar"],
    [3, "Mer"],
    [4, "Gio"],
    [5, "Ven"],
    [6, "Sab"],
    [7, "Dom"],
  ]);

  let mapMonthDay = new Map([
    [1, "Gen"],
    [2, "Feb"],
    [3, "Mar"],
    [4, "Apr"],
    [5, "Mag"],
    [6, "Giu"],
    [7, "Lug"],
    [8, "Ago"],
    [9, "Set"],
    [10, "Ott"],
    [11, "Nov"],
    [12, "Dic"],
  ]);

  // dialog locations
  const [list, setList] = useState([]);
  const [label, setLabel] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (list, type) => {
    type === "location" ? setLabel("Località") : setLabel("Categorie");
    setList(list);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //-----------------

  // @ts-ignore
  return (
    <>
      <Helmet>
        <title>Configurazioni Provider </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Configurazioni Provider
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Configurazioni Provider
                </Typography>
              </Breadcrumbs>
            </Grid>
            <PermissionGuard action={"allow"} resource={"createProviderConfig"}>
              <Grid item>
                <Box sx={{ m: -1 }}>
                  <RouterLink
                    to={{
                      pathname: "/providerConfigs/new",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      color="primary"
                      startIcon={<Add fontSize="small" />}
                      sx={{ m: 1 }}
                      variant="contained"
                    >
                      Nuova Configurazione
                    </Button>
                  </RouterLink>
                </Box>
              </Grid>
            </PermissionGuard>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card {...other}>
              <Table
                idDefaultColumnSort="provider"
                Data={data}
                defaultColumnsToHide={hiddenColumns}
                Columns={[
                  {
                    Header: "Id",
                    accessor: "id",
                    id: "id",
                    Filter: SelectColumnFilter,
                    filter: SelectColumnFilterMethod,
                    width: 350,
                  },
                  {
                    Header: "Stato",
                    accessor: (cell) => (cell.active ? "ATTIVO" : "PAUSA"),
                    width: 150,
                    id: "active",
                    Filter: SelectColumnFilter,
                    filter: SelectColumnFilterMethod,
                    Cell: (cell) => {
                      if (cell.row.original.active) {
                        return (
                          <Chip
                            label={<div className={"chipLabel"}>ATTIVO</div>}
                            style={{ backgroundColor: "green" }}
                          />
                        );
                      }

                      return (
                        <Chip
                          label={<div className={"chipLabel"}>PAUSA</div>}
                          style={{ backgroundColor: "orange" }}
                        />
                      );
                    },
                  },
                  {
                    Header: "Provider",
                    accessor: "provider",
                    id: "provider",
                    Filter: SelectColumnFilter,
                    filter: SelectColumnFilterMethod,
                  },
                  {
                    Header: "Orari esecuzione",
                    accessor: "scheduledAt",
                    Cell: (row) => {
                      return (
                        <div>
                          {row.row.original.scheduledAt?.map((elem) => {
                            return (
                              <Chip label={elem} style={{ margin: "1px" }} />
                            );
                          })}
                        </div>
                      );
                    },
                    disableFilters: true,
                  },
                  {
                    Header: "Localita",
                    accessor: "localita",
                    Cell: (row) => {
                      return (
                        <Box sx={{ m: -1 }}>
                          {row.value?.map((elem) => {
                            return (
                              <Chip label={elem} style={{ margin: "1px" }} />
                            );
                          })}
                        </Box>
                      );
                    },
                    disableFilters: true,
                  },
                  {
                    Header: "Categorie esaminate",
                    accessor: "categories",
                    disableFilters: true,
                    /*  id:'categories',
                                                     Filter: SelectColumnFilter,
                                                     filter: SelectColumnFilterMethod, */
                    Cell: (row) => {
                      return (
                        <Chip
                          label={row.row.original.categories.length}
                          style={{ margin: "1px", fontWeight: "bold" }}
                          clickable={true}
                          color="primary"
                          variant="outlined"
                          onClick={() =>
                            handleClickOpen(
                              row.row.original.categories,
                              "categories"
                            )
                          }
                        />
                      );
                    },
                  },
                  {
                    Header: "Periodi giornalieri",
                    accessor: "steps",
                    disableFilters: true,
                    width: 300,
                    Cell: (row) => {
                      return (
                        <div>
                          {row.row.original.steps?.map((elem) => {
                            return (
                              <Chip label={elem} style={{ margin: "1px" }} />
                            );
                          })}
                        </div>
                      );
                    },
                  },
                  {
                    Header: "Mesi analizzati",
                    accessor: "monthToCheck",
                    disableFilters: true,
                    width: 200,
                    Cell: (row) => {
                      return (
                        <div>
                          {row.row.original.monthToCheck?.map((elem) => {
                            return (
                              <Chip
                                label={mapMonthDay.get(elem)}
                                style={{ margin: "1px" }}
                              />
                            );
                          })}
                        </div>
                      );
                    },
                  },
                  {
                    Header: "Inizio settimana",
                    accessor: "weekDaysStart",
                    disableFilters: true,
                    width: 200,
                    Cell: (row) => {
                      return (
                        <div>
                          {row.row.original.weekDaysStart?.map((elem) => {
                            return (
                              <Chip
                                label={mapWeekDay.get(elem)}
                                style={{ margin: "1px" }}
                              />
                            );
                          })}
                        </div>
                      );
                    },
                  },

                  {
                    Header: "Inizio mese",
                    accessor: "monthDaysStart",
                    disableFilters: true,
                    width: 300,
                    Cell: (row) => {
                      return (
                        <div>
                          {row.row.original.monthDaysStart?.map((elem) => {
                            return (
                              <Chip label={elem} style={{ margin: "1px" }} />
                            );
                          })}
                        </div>
                      );
                    },
                  },
                  {
                    Header: "Durata mensile",
                    accessor: "monthsDuration",
                    disableFilters: true,
                    Cell: (row) => (
                      <Chip
                        label={row.row.original.monthsDuration}
                        style={{ margin: "1px" }}
                      />
                    ),
                  },
                  {
                    Header: "Max tentativi",
                    accessor: "maxAttempts",
                  },
                  {
                    Header: "Usa Tor",
                    accessor: "useTor",
                    Cell: (row) => {
                      if (row.value) return "Si";
                      return "No";
                    },
                  },
                  // {
                  //     "Header": "Cliente",
                  //     "accessor": "company",
                  //     id: 'company',
                  //     Filter: SelectColumnFilter,
                  //     filter: SelectColumnFilterMethod,
                  //     width: 350,
                  // },
                  {
                    Header: "Azioni",
                    Cell: (row) => (
                      <>
                        {!row.row.original.active ? (
                          <Tooltip title={"Attiva provider"}>
                            <IconButton
                              aria-label="Attiva provider"
                              color="primary"
                              onClick={() =>
                                setActive(row.row.original.id, true)
                              }
                            >
                              <PlayCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title={"Metti in pausa provider"}>
                            <IconButton
                              aria-label="Metti in pausa provider"
                              color="secondary"
                              onClick={() =>
                                setActive(row.row.original.id, false)
                              }
                            >
                              <PauseCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title={"Duplica configurazione"}>
                          <IconButton
                            aria-label="Duplica configurazione"
                            color="primary"
                            onClick={() => handleDuplicate(row.row.original.id)}
                          >
                            <FileCopyIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <PermissionGuard
                          action={"allow"}
                          resource={"updateProviderConfig"}
                        >
                          <IconButton
                            component={RouterLink}
                            to={`/providerConfigs/update/${row.row.original.id}`}
                          >
                            <Edit fontSize="small" />
                          </IconButton>
                        </PermissionGuard>
                        <PermissionGuard
                          action={"allow"}
                          resource={"deleteProviderConfig"}
                        >
                          <Tooltip title={"Elimina"}>
                            <IconButton
                              aria-label="Elimina"
                              color="secondary"
                              onClick={() => deleteEntry(row.row.original.id)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </PermissionGuard>
                      </>
                    ),
                  },
                ]}
              />
            </Card>
          </Box>
        </Container>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="">{label} Selezionate</DialogTitle>
        <DialogContent>
          <Divider style={{ marginBottom: "5px" }} />
          {list.length === 0 ? (
            <Typography variant="body2" component="p">
              Nessuna Selezionata
            </Typography>
          ) : (
            list?.map((item) => <Chip label={item} style={{ margin: "3px" }} />)
          )}
          <Divider style={{ marginTop: "5px" }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {" "}
            Chiudi{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PageProviderConfig;
