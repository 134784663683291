import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Link,
    TextField,
    Typography
} from "@mui/material";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import type { FC } from "react";
import { Helmet } from "react-helmet-async";
import {
    Link as RouterLink,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import * as Yup from "yup";
import GraphqlClient from "../../client/graphql.client";
import useSettings from "../../hooks/useSettings";

const NewRole: FC = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();

  console.log("location", location);
  console.log("params", params);

  return (
    <>
      <Helmet>
        <title>Dashboard: Nuovo ruolo </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Nuovo ruolo
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/roles"
                  variant="subtitle2"
                >
                  roles
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Nuovo ruolo
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<ArrowLeftIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                  to="/users"
                  variant="outlined"
                >
                  Torna a ruoli
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Formik
              initialValues={{
                nome: "",
                descrizione: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                nome: Yup.string().max(20).required("Campo obbligatorio"),
                descrizione: Yup.string().max(40),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ): Promise<void> => {
                setSubmitting(true);
                let { nome, descrizione } = values;
                try {
                  const res = await GraphqlClient.createRole({
                    name: nome,
                    description: descrizione,
                  });
                  setStatus({ success: true });
                  setSubmitting(false);
                  enqueueSnackbar("Ruolo creato", {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    variant: "success",
                  });
                  navigate("/roles");
                } catch (e) {
                  console.error(e);
                  setStatus({ success: false });
                  setSubmitting(false);
                  enqueueSnackbar("Errore durante creazione ruolo", {
                    anchorOrigin: {
                      horizontal: "right",
                      vertical: "top",
                    },
                    variant: "error",
                  });
                  return;
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }): JSX.Element => (
                <form onSubmit={handleSubmit} {...props}>
                  <Box sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <Card>
                          <CardContent>
                            <CardHeader title="Informazioni ruolo" />
                            <Box sx={{ mt: 2 }}>
                              <TextField
                                error={Boolean(touched.nome && errors.nome)}
                                fullWidth
                                helperText={touched.nome && errors.nome}
                                label="Nome"
                                id="nome"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.nome}
                                variant="outlined"
                              />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <TextField
                                error={Boolean(
                                  touched.descrizione && errors.descrizione
                                )}
                                fullWidth
                                helperText={
                                  touched.descrizione && errors.descrizione
                                }
                                label="Descrizione"
                                id="descrizione"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.descrizione}
                                variant="outlined"
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 3,
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Salva
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NewRole;
